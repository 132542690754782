import React, { Component } from 'react';

import { Ajax } from '../../ajax/Ajax.js';
import { Release } from '../releases/Release.js';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from '../common/Loading.js';

class EditRelease extends Component {
	
	constructor(props) {
		super(props);
		this.state = { release: false, loading: true };
	}
	
	componentWillMount() {
		var releaseId = this.props.match.params.releaseId;
		var obj = this;		
		Ajax.getReleaseById(releaseId).then((release) => {
			if (release.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});				
			} else {
				obj.setState({release: release, loading: false}); 
			}
		});
	}
	
	render() {
		if (this.state.release)
			return (
				<DashboardComponent>
					<Release release={this.state.release} />
					{ this.state.loading ? (<Loading />) : null }
				</DashboardComponent>			
			);		
		else
			return null;
	}
}

export { EditRelease };