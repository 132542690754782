import React, { Component } from 'react';
import { TimeSeries } from "pondjs";
import {
    Charts,
    ChartContainer,
    ChartRow,
    YAxis,
    LineChart,
    Resizable,
    Legend
} from "react-timeseries-charts";
import _ from "underscore";

class TimeChart extends React.Component {

    constructor(props) {
        super(props);
        const data = props.data;
        const timeSeries = new TimeSeries({
            name: "visits",
            columns: ["time", "visits"],
            points: data
        });
        this.state = {
            timeSeries: timeSeries,
            timerange: timeSeries.range(),
            max: timeSeries.max(),
            height: props.height,
            width: props.width
        };
        this.rescale = this.rescale.bind(this);
        this.handleRescale = _.debounce(this.rescale, 300);
        this.handleTimeRangeChange = this.handleTimeRangeChange.bind(this);
        this.renderChart = this.renderChart.bind(this);
    }

    componentDidUpdate(prevProps) {
        var props = this.props;
        if (props.releaseId === prevProps.releaseId) {
            return
        }
        const data = props.data;
        const timeSeries = new TimeSeries({
            name: "visits",
            columns: ["time", "visits"],
            points: data
        });
        this.setState({
            timeSeries: timeSeries,
            timerange: timeSeries.range(),
            max: timeSeries.max(),
            height: props.height,
            width: props.width
        });
    }

    rescale(timerange) {
        let max = 100;
        const maxVisits = this.state.timeSeries.crop(this.state.timerange).max("visits");
        if (maxVisits > max) max = maxVisits;        
        this.setState({ max });
    }

    handleTimeRangeChange(timerange) {
        this.setState({ timerange });
        this.handleRescale(timerange);
    };

    renderChart() {
        let charts = [];
        
        var max = this.state.timeSeries.crop(this.state.timerange).max("visits");
        charts.push(
            <LineChart
                key="visits"
                axis="axis1"
                series={this.state.timeSeries}
                columns={["visits"]}
                interpolation="curveBasis"
            />
        );

        return (
            <ChartContainer
                enableDragZoom
                minDuration={7*24*60*60*1000}
                onTimeRangeChanged={this.handleTimeRangeChange}
                timeRange={this.state.timerange}
                maxTime={this.state.timeSeries.range().end()}
                minTime={this.state.timeSeries.range().begin()}
            >
                <ChartRow height={this.state.height} width={this.state.width}>
                    <YAxis
                        id="axis1"
                        showGrid
                        hideAxisLine
                        transition={300}
                        min={0}
                        max={max}
                        format=",.0f"
                        type="linear"
                        width={15}
                    />
                    <Charts>{charts}</Charts>
                </ChartRow>
            </ChartContainer>
        );
    };

    render() {

        return (
            <div>
                <Resizable>{this.renderChart()}</Resizable>
            </div>
        );
    }
}

export { TimeChart };