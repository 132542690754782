import { cookies } from '../cookies/Cookies.js';
import { config } from '../config/config.js';
const axios = require('axios');
const serverUrl = config.localMode ? config.local.backendPrivate : config.deployed.backendPrivate;
const publicServerUrl = config.localMode ? config.local.backendPublic : config.deployed.backendPublic;
const fileServerUrl = config.localMode ? config.local.backendFiles : config.deployed.backendFiles;

var webPsupport = false;
const webP = new Image();
webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
webP.onload = webP.onerror = () => {
    webPsupport = webP.height === 2;
};        

class AjaxCalls {
	
	getRequest(url) {
		var init = {
		  method: "GET"
		}; 
		var request = new Request(url, init);
		return request;
	}
	
	postRequest(url, jsondata) {		
		var init = {
			method: "POST",
			body: JSON.stringify(jsondata),
			headers: { "Content-Type": "application/json" }
		}; 
		var request = new Request(url, init);
		return request;
	}
	
	getText(url) {
		return fetch(url, {credentials: "include"})
		.then(function(response) {
			return response.text();
		})
		.catch(function(error) {
			console.log(error);
		});
	}

	getJsonNoCredentials(url) {
		return fetch(url)
		.then(function(response) {
			return response.json();
		})
		.catch(function(error) {
			console.log(error);			
		});
	}
	
	getJson(url) {		
		return fetch(url, {credentials: "include"})
		.then(function(response) {			
			return response.json();
		})
		.catch(function(error) {
			console.log(error);
		});
	}

	getOdesliLinks(link) {
		var request = this.getRequest("https://earlygrooves.com/odesli.php?link=" + encodeURIComponent(link));
		return this.getJsonNoCredentials(request).then((response) => {
			if (response === undefined) {
				return false;
			}
			return response;
		});
	}

	getSubscription(subscriptionId) {
		var request = this.getRequest(serverUrl + "billing/subscription?subscriptionId=" + subscriptionId);
		return this.getJson(request);
	}

	cancelSubscription(subscriptionId) {
		var request = this.postRequest(serverUrl + "billing/cancel", {subscriptionId: subscriptionId});
		return this.getJson(request);
	}

	bugReport(description, context) {
		var request = this.postRequest(serverUrl + "bug", {description: description, context: context});
		return this.getJson(request);
	}

	beta(email, name, link) {
		var request = this.postRequest(serverUrl + "beta", {email: email, name: name, link: link});
		return this.getJson(request);
	}

	getFeed() {
		var request = this.getRequest(serverUrl + "feed/all");
		return this.getJson(request);
	}

	filterFeed(genres) {
		var request = this.postRequest(serverUrl + "feed/genres", {genres: genres});
		return this.getJson(request);
	}

	updateNotification(notification) {
		var request = this.postRequest(serverUrl + "profile/notifications", {notification: notification});
		return this.getJson(request);
	}

	getNotifications() {
		var request = this.getRequest(serverUrl + "profile/notifications");
		return this.getJson(request);
	}

	uploadTrack(releaseId, file, progressCallback) {
		var data = new FormData();
		data.append('file', file);
		data.append('releaseId', releaseId);
		return axios.request({
			method: "post", 
			url: serverUrl + "files/uploadTrack", 
			data: data, 
			onUploadProgress: progressCallback,
			withCredentials: true
		}).then(function(response) {
			return response.data;
		});
	}

	uploadCover(releaseId, file, progressCallback) {
		var data = new FormData();
		data.append('file', file);
		data.append('releaseId', releaseId);
		return axios.request({
			method: "post", 
			url: serverUrl + "files/uploadCover", 
			data: data, 
			onUploadProgress: progressCallback,
			withCredentials: true
		}).then(function(response) {
			return response.data;
		});
	}
	
	upload(file, progressCallback) {
		var data = new FormData();
		data.append('file', file);
		
		return axios.request({
			method: "post", 
			url: serverUrl + "files/upload", 
			data: data, 
			onUploadProgress: progressCallback,
			withCredentials: true
		}).then(function(response) {
			return response.data;
		});
	}	

	finalize(token, name, password) {
		var request = this.postRequest(serverUrl + "auth/finalize", {token: token, name: name, password: password});
		return this.getJsonNoCredentials(request);
	}
	
	register(values) {
		var request = this.postRequest(serverUrl + "auth/register", values);
		return this.getJson(request);
	}

	upgrade(subscriptionId, orderId) {
		var request = this.postRequest(serverUrl + "billing/upgrade", {subscriptionId: subscriptionId, orderId: orderId});
		return this.getJson(request);
	}

	verify(token) {
		var request = this.postRequest(serverUrl + "auth/verify", {token: token});
		return this.getJson(request);
	}
	
	login(email, password) {
		var request = this.postRequest(serverUrl + "auth/signin", {email: email, password: password});
		return this.getJson(request);
	}

	forgotPassword(email) {
		var request = this.postRequest(serverUrl + "forgotpassword", {email: email});
		return this.getJsonNoCredentials(request);
	}

	resetPassword(token, password) {
		var request = this.postRequest(serverUrl + "resetpassword", {token: token, password: password});
		return this.getJsonNoCredentials(request);
	}

	changePassword(oldPassword, newPassword) {
		var request = this.postRequest(serverUrl + "changepassword", {oldPassword: oldPassword, newPassword: newPassword});
		return this.getJson(request);
	}

	isAuthenticated() {
		var request = this.getRequest(serverUrl + "isauth");
		return this.getJson(request);
	}
	
	logout() {
		var request = this.postRequest(serverUrl + "unauth");
		return this.getJson(request).then((result) => {
			cookies.removeSessionCookie();			
			return result;
		});
	}

	addProfileGenre(genre) {
		var request = this.postRequest(serverUrl + "addprofilegenre", {genre: genre});
		return this.getJson(request);
	}

	verifyEmail(email) {
		var request = this.getRequest(serverUrl + "verifyEmail?email=" + email);
		return this.getJson(request);
	}
	
	getProfile() {
		var request = this.getRequest(serverUrl + "profile");
		return this.getJson(request);
	}

	getPublicProfile(userId) {
		var request = this.getRequest(serverUrl + "profile?userId=" + userId);
		return this.getJsonNoCredentials(request);
	}

	getPublicProfileBySlug(slug) {
		var request = this.getRequest(serverUrl + "profile?slug=" + slug);
		return this.getJsonNoCredentials(request);
	}

	getPublicProfileByFinalizeToken(token) {
		var request = this.getRequest(serverUrl + "profile?finalizeToken=" + token);
		return this.getJsonNoCredentials(request);
	}

	getProfileSlugs() {
		var request = this.getRequest(serverUrl + "profile/slugs");
		return this.getJsonNoCredentials(request);
	}

	getUserId() {
		var request = this.getRequest(serverUrl + "userId");
		return this.getJson(request);
	}

	updateProfile(profile) {
		var request = this.postRequest(serverUrl + "profile", {profile: profile});
		return this.getJson(request);
	}
	
	getFile(fileId) {		
		if (webPsupport) {
			return fileServerUrl + fileId;
		} else {
			return fileServerUrl + fileId.replace('.webp', '').replace('.jpg_small', '_small.jpg');
		}		
	}

	getReleaseAsZip(releaseId, subscriberId) {
		return publicServerUrl + "releases/download?releaseId=" + releaseId + "&subscriberId=" + subscriberId;
	}

	getCsv(list) {
		return serverUrl + "subscribers/export?list=" + list;
	}
	
	saveRelease(release) {
		var request = this.postRequest(serverUrl + "releases", {release: release});
		return this.getJson(request);
	}
	
	getReleases() {
		var request = this.getRequest(serverUrl + "releases/all");
		return this.getJson(request);
	}
	
	getReleaseById(releaseId) {
		var request = this.getRequest(serverUrl + "releases?id=" + releaseId);
		return this.getJson(request);
	}

	getPublicReleaseById(releaseId) {
		var request = this.getRequest(serverUrl + "releases?id=" + releaseId);
		return this.getJsonNoCredentials(request);
	}

	getPublicReleaseBySlug(slug) {
		var request = this.getRequest(serverUrl + "releases?slug=" + slug);
		return this.getJsonNoCredentials(request);
	}

	getPublicReleases(userId) {
		var request = this.getRequest(serverUrl + "releases?userId=" + userId);
		return this.getJsonNoCredentials(request);
	}

	removeRelease(release) {
		var request = this.postRequest(serverUrl + "releases/remove", {release: release});
		return this.getJson(request);
	}
	
	importSubscribers(file, list, progressCallback) {
		var data = new FormData();
		data.append('file', file);
		data.append('list', list);
		
		return axios.request({
			method: "post", 
			url: serverUrl + "subscribers/import", 
			data: data, 
			onUploadProgress: progressCallback,
			withCredentials: true
		}).then(function(response) {
			return response.data;
		});
	}	
	
	getSubscribers() {
		var request = this.getRequest(serverUrl + "subscribers");
		return this.getJson(request);
	}

	removeAllPresskitSubscribers(profile) {
		var request = this.postRequest(serverUrl + "subscribers/removeAll", {profile: profile, list: "presskit"});
		return this.getJson(request);
	}

	removeAllNewsletterSubscribers(profile) {
		var request = this.postRequest(serverUrl + "subscribers/removeAll", {profile: profile, list: "newsletter"});
		return this.getJson(request);
	}

	getNewsletterSubscribers(profile) {
		var request = this.getRequest(serverUrl + "subscribers/newsletter");
		if (profile) {
			return this.getJson(request).then((result) => {
				return {profile: profile, subscribers: result};
			});
		} else {			
			return this.getJson(request);
		}		
	}

	getPresskitSubscribers(profile) {
		var request = this.getRequest(serverUrl + "subscribers/presskit");
		if (profile) {
			return this.getJson(request).then((result) => {
				return {profile: profile, subscribers: result.subscribers};
			});
		} else {			
			return this.getJson(request);
		}		
	}
	
	getSubscriberCsv(list) {
		return serverUrl + "subscribers/export?list=" + list;		
	}
	
	removeSubscriber(list, subscriber) {
		var request = this.postRequest(serverUrl + "subscribers/remove", {list: list, subscriber: subscriber});
		return this.getJson(request);
	}
	
	addSubscriber(list, subscriber) {
		var request = this.postRequest(serverUrl + "subscribers/add", {list: list, subscriber: subscriber});
		return this.getJson(request);
	}

	subscribe(profileId, subscriber) {
		var request = this.postRequest(publicServerUrl + "subscribe", {profileId: profileId, subscriber: subscriber, list: "newsletter"});
		return this.getJsonNoCredentials(request);
	}

	unsubscribe(list, profileId, subscriberId) {
		var request = this.getJsonNoCredentials(publicServerUrl + "unsubscribe?list=" + list + "&profileId=" + profileId + "&subscriberId=" + subscriberId);
		return this.getJsonNoCredentials(request);
	}

	getNewsletterPreview(release) {
		var request = this.postRequest(serverUrl + "releases/newsletter/preview", {release: release});
		return this.getJson(request);
	}

	getPresskitPreview(release) {
		var request = this.postRequest(serverUrl + "releases/presskit/preview", {release: release});
		return this.getJson(request);
	}

	sendTestNewsLetter(release, email) {
		var request = this.postRequest(serverUrl + "releases/newsletter/test", {release: release, email: email});
		return this.getJson(request);
	}

	sendTestPresskit(release, email) {
		var request = this.postRequest(serverUrl + "releases/presskit/test", {release: release, email: email});
		return this.getJson(request);
	}

	sendNewsletter(release) {
		var obj = this;
		return this.getProfile()
		.then((profile) => {
			var request = obj.postRequest(serverUrl + "releases/newsletter/send", {release: release, profile: profile});
			return obj.getJson(request);
		});	
	}
	
	sendPresskit(release) {
		var obj = this;
		return this.getProfile()
		.then((profile) => {
			var request = obj.postRequest(serverUrl + "releases/presskit/send", {release: release, profile: profile});
			return obj.getJson(request);
		});	
	}

	statsMinipageOpen(releaseId, from, subscriberId) {		
		var removeShit = from.replace("http://", "").replace("https://", "");
		var request = this.getRequest(publicServerUrl + "stats/minipageOpen?releaseId=" + releaseId + "&from=" + removeShit);
		return this.getJsonNoCredentials(request);
	}

	statsMinipagePlatformClick(releaseId, platform) {
		var request = this.getRequest(publicServerUrl + "stats/minipagePlatformClick?releaseId=" + releaseId + "&platform=" + platform);
		return this.getJsonNoCredentials(request);
	}

	statsNewsletterClick(releaseId, subscriberId) {
		var request = this.getRequest(publicServerUrl + "stats/newsletterClick?releaseId=" + releaseId + "&subscriberId=" + subscriberId);
		return this.getJsonNoCredentials(request);
	}

	statsPresskitClick(releaseId, subscriberId) {
		var request = this.getRequest(publicServerUrl + "stats/presskitClick?releaseId=" + releaseId + "&subscriberId=" + subscriberId);
		return this.getJsonNoCredentials(request);
	}

	statsNewsletterPlatformClick(releaseId, subscriberId, platform) {
		var request = this.getRequest(publicServerUrl + "stats/newsletterPlatformClick?releaseId=" + releaseId + "&subscriberId=" + subscriberId + "&platform=" + platform);
		return this.getJsonNoCredentials(request);
	}

	statsPresskitPlatformClick(releaseId, subscriberId, platform) {
		var request = this.getRequest(publicServerUrl + "stats/presskitPlatformClick?releaseId=" + releaseId + "&subscriberId=" + subscriberId + "&platform=" + platform);
		return this.getJsonNoCredentials(request);
	}

	statsMinipageListen(releaseId, trackNr) {
		var request = this.getRequest(publicServerUrl + "stats/minipageListen?releaseId=" + releaseId + "&trackNr=" + trackNr);
		return this.getJsonNoCredentials(request);
	}

	statsMinipageDownload(releaseId, trackNr) {
		var request = this.getRequest(publicServerUrl + "stats/minipageListen?releaseId=" + releaseId + "&trackNr=" + trackNr);
		return this.getJsonNoCredentials(request);
	}

	getStats(releaseId) {
		var request = this.getRequest(serverUrl + "stats?releaseId=" + releaseId);
		return this.getJson(request);
	}

	sendTicket(message) {
		var request = this.postRequest(serverUrl + "ticket", {message: message});
		return this.getJson(request);
	}
}

var Ajax = new AjaxCalls();

export { Ajax };