import React, { Component } from 'react';

import { Ajax } from '../../ajax/Ajax.js';
import { LinkPage } from '../linkpage/LinkPage.js';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from '../common/Loading.js';
import { config } from '../../config/config.js'
import FontAwesome from 'react-fontawesome';
import { SketchPicker } from 'react-color';

import './EditLinkPage.css';

class EditLinkPage extends Component {
	
	constructor(props) {
		super(props);
	
        this.selectAll = this.selectAll.bind(this);
        this.save = this.save.bind(this);
        this.selectBackgroundCover = this.selectBackgroundCover.bind(this);
        this.selectBackgroundColor = this.selectBackgroundColor.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
		this.toggleBlur = this.toggleBlur.bind(this);       
		this.toggleProfileLinks = this.toggleProfileLinks.bind(this);
		this.addLink = this.addLink.bind(this);
		this.removeLink = this.removeLink.bind(this);
		this.uploadImage = this.uploadImage.bind(this);
		this.handleChangeNewLinkName = this.handleChangeNewLinkName.bind(this);
		this.handleChangeNewLinkUrl = this.handleChangeNewLinkUrl.bind(this);
		this.handleChangeLinkName = this.handleChangeLinkName.bind(this);
		this.handleChangeLinkUrl = this.handleChangeLinkUrl.bind(this);
		this.state = { profile: false, loading: true, saved: true, url: false,
			toggles: {
				showBackdrop: true,
				showLinks: true
			}, change: false, uploadedImage: false,
			newLinkName: "", newLinkUrl: ""
		};
		
	}
	
	componentWillMount() {
		var obj = this;		
		Ajax.getProfile().then((profile) => {
			if (profile.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});				
			} else {
				if (profile.linkpage === undefined || ! profile.linkpage) {
					profile.linkpage = {};
					profile.linkpage.backgroundCover = false;
					profile.linkpage.backgroundSrc = (profile.logo !== undefined && profile.logo) ? profile.logo : false;
					profile.linkpage.backgroundColor = "#ffffff";
				}		
				obj.setState({profile: profile, loading: false, url: config.localMode ? config.local.frontend + "links/" + profile.slug : config.deployed.frontend + "links/" + profile.slug}); 
			}
		});
    }
    
    selectAll(event) {
        event.target.setSelectionRange(0, event.target.value.length);
	}
	
    save() {
		var profile = this.state.profile;
		Ajax.updateProfile(profile).then((profile) => {
			console.log(profile);
		});
    }
    
    selectBackgroundCover() {
        var profile = this.state.profile;
        if (profile.linkpage === undefined) {
            profile.linkpage = {};
        }
		profile.linkpage.backgroundCover = true;
		if (this.state.uploadedImage) {
			profile.linkpage.backgroundSrc = this.state.uploadedImage;
		} else {
			if (profile.linkpage.backgroundSrc === undefined) {
				profile.linkpage.backgroundSrc = profile.logo;
			}
		}
        this.setState({profile: profile, change: ! this.state.change}, this.save);
    }

    selectBackgroundColor(color) {
        var profile = this.state.profile;
        if (profile.linkpage === undefined) {
            profile.linkpage = {};
        }
        profile.linkpage.backgroundCover = false;
        profile.linkpage.backgroundColor = color.rgb;
        this.setState({profile: profile, change: ! this.state.change}, this.save);
    }

    handleToggle(field) {
		var obj = this;
		return function(event) {
            var toggles = obj.state.toggles;
            var toggle = toggles[field];
            var newToggle = ! toggle;
            toggles[field] = newToggle;
			obj.setState({toggles: toggles});
		}
	}
	
	toggleBlur() {
        var obj = this;
        return (event) => {
            var profile = obj.state.profile;
            if (profile.linkpage === undefined) {
                profile.linkpage = {};
            }
            if (profile.linkpage.backgroundBlur === undefined) {
                profile.linkpage.backgroundBlur = false;
            }
            profile.linkpage.backgroundBlur = ! profile.linkpage.backgroundBlur;
            obj.setState({profile: profile, change: ! obj.state.change}, obj.save);
        }        
	}

	toggleProfileLinks() {
        var obj = this;
        return (event) => {
            var profile = obj.state.profile;
            if (profile.linkpage === undefined) {
                profile.linkpage = {};
            }
            if (profile.linkpage.includeProfileLinks === undefined) {
                profile.linkpage.includeProfileLinks = true;
            }
            profile.linkpage.includeProfileLinks = ! profile.linkpage.includeProfileLinks;
            obj.setState({profile: profile, change: ! obj.state.change}, obj.save);
        }        
	}	
	
	uploadImage(event) {
		var obj = this;		
		var file = event.target.files[0];
		Ajax.upload(file, () => {}).then((data) => {
			var profile = obj.state.profile;
			var imageId = data.file;
			profile.linkpage.backgroundSrc = imageId;
			profile.linkpage.backgroundCover = true;
			obj.setState({profile: profile, uploadedImage: imageId, change: ! obj.state.change}, obj.save);
		});
	}

	handleChangeLinkName(url) {
		var obj = this;
		return (event) => {
			var profile = obj.state.profile;
			var links = profile.linkpage.links;
			for (var i = 0; i < links.length; i++) {
				var link = links[i];
				if (link.url === url) {
					link.name = event.target.value;
					break;
				}			
			}
			profile.linkpage.links = links;
			obj.setState({profile: profile, change: ! obj.state.change}, obj.save);
		}
	}

	handleChangeLinkUrl(url) {
		var obj = this;
		return (event) => {
			var profile = obj.state.profile;
			var links = profile.linkpage.links;
			for (var i = 0; i < links.length; i++) {
				var link = links[i];
				if (link.url === url) {
					link.url = event.target.value;
					break;
				}			
			}
			profile.linkpage.links = links;
			obj.setState({profile: profile, change: ! obj.state.change}, obj.save);
		}
	}

	handleChangeNewLinkName() {
		var obj = this;
		return (event) => {
			var name = event.target.value;
			obj.setState({newLinkName: name});
		}
	}
	handleChangeNewLinkUrl() {
		var obj = this;
		return (event) => {
			var url = event.target.value;
			obj.setState({newLinkUrl: url});
		}
	}

	addLink() {
		var obj = this;
		return () => {
			var link = {name: obj.state.newLinkName, url: obj.state.newLinkUrl};
			var profile = obj.state.profile;
	
			if (profile.linkpage.links === undefined) {
				profile.linkpage.links = [];
			}
			profile.linkpage.links.push(link);
			console.log(profile);
			obj.setState({profile: profile, newLinkName: "", newLinkUrl: "", change: ! obj.state.change}, obj.save);
		}		
	}

	removeLink(url) {
		var obj = this;
		return () => {
			var profile = obj.state.profile;
			var links = profile.linkpage.links;
			var newLinks = [];
			for (var i = 0; i < links.length; i++) {
				var link = links[i];
				if (link.url !== url) {
					newLinks.push(link);
				}
			}
			profile.linkpage.links = newLinks;
			obj.setState({profile: profile, change: ! this.state.change}, obj.save);
		}
	}

	render() {
		var obj = this;
		var profile = this.state.profile;
		
		if (profile) {
			var img = profile.linkpage.backgroundSrc;
			if (img === undefined) {
				img = (profile.logo !== undefined && profile.logo) ? profile.logo : false;
			}
			var links = profile.linkpage.links;
			if (links === undefined) {
				links = [];
			}
			return (
				<DashboardComponent>
                    <div className="EditCatalog">
						<div className="DashboardComponentHeader">
							<div className="DashboardComponentTitle"><FontAwesome name="share-alt" /> Link Page</div>
							<div className="DashboardComponentMenu">
                                <div className={ obj.state.saved ? "DashboardComponentMenuItem":"DashboardComponentMenuItem Unsaved" } onClick={this.save} ><FontAwesome name="save" /> Save</div>
							</div>
						</div>
						<div className="DashboardComponentSection">
                            The Link Page uses information that you can edit on your profile. Click below to edit your profile.<br/><br/>
							<div className="Button" onClick={() => {window.location = "/artists/profile";}}>Edit Profile</div>
                        </div>
                        <div className="DashboardComponentSection">
                            <div className="PublicUrl">
                                <div className="PublicUrlTitle">Public URL</div>
                                <div className="PublicUrlInput"><input onClick={this.selectAll} type="text" name="" value={this.state.url} readOnly /></div>
                                <div className="PublicUrlButton"><a href={this.state.url} target="_blank"><div className="Button">Visit</div></a></div>
                        
                            </div>
                        </div>
						<div className="DashboardComponentSection">
                            <div className="DashboardComponentSectionTitle">Preview</div>
                            <div className="LinkPagePreview">
                                <LinkPage profile={this.state.profile} change={this.state.change}/>								
							</div>
                        </div>
						<div className="DashboardComponentSection">
                            <div className="DashboardComponentSectionTitle">Edit</div>
                            <div className="LinkPageEdit">
								<div className="PreviewRightBlock">
									<div className="PreviewRightTestTitle">Link Page Backdrop
									{ this.state.toggles.showBackdrop ? 
										(<div className="ShowDetails" onClick={obj.handleToggle("showBackdrop")}><i className="fas fa-chevron-circle-up"></i></div>) : 
										(<div className="ShowDetails" onClick={obj.handleToggle("showBackdrop")}><i className="fas fa-chevron-circle-down"></i></div>)
									}
									</div>
									{ this.state.toggles.showBackdrop ? (
										<div>
											<div className="PreviewOption">
											<div className="PreviewOptionLabel">Use Image as Background</div>
												<div onClick={ this.selectBackgroundCover } className={ profile.linkpage.backgroundCover ? "PreviewOptionInput PreviewOptionCover PreviewOptionCoverSelected" : "PreviewOptionInput PreviewOptionCover"}>
													{img ? (<img src={ Ajax.getFile(img) } alt="backgroundImage" />) : (<FontAwesome name="image" />)}
												</div>
												<div className="LinkPageUploadImage">
													<input ref={(ref) => this.logoUploader = ref} type="file" name="cover" id="cover" accept="image/jpeg" onChange={this.uploadImage}/>
													<div className="LinkPageImage" onClick={() => { obj.logoUploader.click() } }>
														<div className="LinkPageImageChange"><FontAwesome name="upload"/> Upload Background</div>
													</div>											
												</div>

												<div className="CoverArtOption">Blur Image <input type="checkbox" onChange={obj.toggleBlur()} checked={profile.linkpage.backgroundBlur}/></div>
											</div>
											<div className="PreviewOption">
												<div className="PreviewOptionLabel">Use Color as Background</div>
												<div className="PreviewOptionInput PreviewOptionColor">
													<SketchPicker
														color={ profile.linkpage.backgroundColor }
														onChangeComplete={ this.selectBackgroundColor }
													/>
												</div>
											</div>
										</div>
									) : null }                                                                                         
								</div>
								<div className="PreviewRightBlock">
									<div className="PreviewRightTestTitle">Links
										{ this.state.toggles.showLinks ? 
											(<div className="ShowDetails" onClick={obj.handleToggle("showLinks")}><i className="fas fa-chevron-circle-up"></i></div>) : 
											(<div className="ShowDetails" onClick={obj.handleToggle("showLinks")}><i className="fas fa-chevron-circle-down"></i></div>)
										}
										</div>
										{ this.state.toggles.showLinks ? (
											<div>
												<div className="LinkPageProfileLinks">
													<div className="CoverArtOption">Include Profile Links <input type="checkbox" onChange={obj.toggleProfileLinks()} checked={profile.linkpage.includeProfileLinks}/></div>
												</div>
												<div className="LinkPageLinks">
													{links.map((link, idx) => {
														return (
															<div className="LinkPageLink" key={link.url}>
																<input type="text" value={link.name} onChange={this.handleChangeLinkName(link.url)} />
																<input type="text" value={link.url} onChange={this.handleChangeLinkUrl(link.url)} />
																<div className="LinkPageLinkDelete" onClick={this.removeLink(link.url)}><FontAwesome name="minus-circle"/></div>
															</div>
														)
													})
													}
												</div>
												<div className="LinkPageAddLink">
													<div className="LinkPageAddLinkLabel">Name</div>
													<input type="text" value={obj.state.newLinkName} placeholder="Name" onChange={this.handleChangeNewLinkName()} />
													<div className="LinkPageAddLinkLabel">URL</div>
													<input type="text" value={obj.state.newLinkUrl} placeholder="URL" onChange={this.handleChangeNewLinkUrl()} />
													<div className="Button" onClick={this.addLink()}>Add Link</div>
												</div>
											</div>
										) : null }                                                                                         
									</div>
								</div>
							</div>
                        </div>
					{ this.state.loading ? (<Loading />) : null }                   
				</DashboardComponent>		
			);		
		}
		else
			return null;
	}
}

export { EditLinkPage };