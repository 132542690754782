import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Ajax } from '../../ajax/Ajax';
import InfiniteScroll from 'react-infinite-scroll-component';
import './Feed.css';
import { FeedRelease } from './FeedRelease.js';
import { WithContext as ReactTags } from 'react-tag-input';
import { isUndefined } from 'util';

const pageSize = 10;

class Feed extends Component {
	
	constructor(props) {
		super(props);
        this.state = {releases: false, shownReleases: [], showMenu: false, filter: [], suggestions: []};
        this.href = this.href.bind(this);
        this.addMoreReleases = this.addMoreReleases.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.handleAddFilter = this.handleAddFilter.bind(this);
        this.handleDeleteFilter = this.handleDeleteFilter.bind(this);        
        this.filterReleases = this.filterReleases.bind(this);
    }
    
    componentWillMount() {
        var obj = this;
        Ajax.getFeed().then((releases) => {
            var uniqueSuggestions = new Set();
            for (var i = 0; i < releases.length; i++) {
                var release = releases[i];
                var title = release.title;
                var artists = release.artists;
                var genres = release.genres;
                uniqueSuggestions.add(title);
                for (var j = 0; j < artists.length; j++) {
                    var artist = artists[j];
                    uniqueSuggestions.add(artist.id);
                }
                for (var k = 0; k < genres.length; k++) {
                    var genre = genres[k];
                    uniqueSuggestions.add(genre.id);
                }
            }
            var suggestions = [];
            uniqueSuggestions.forEach((val1, val2, set) => {
                suggestions.push({id: val1, text: val1});
            });
            obj.setState({releases: releases, shownReleases: releases, suggestions: suggestions});
        });
    }

	href(path) {
		return function() {
			window.location.href = path;
		};
    }

    toggleMenu() {
        this.setState({showMenu: !this.state.showMenu});
    }
    
    addMoreReleases() {
        /*var releases = this.state.releases;
        var shown = releases.slice(0, this.state.shownReleases.length - 1 + pageSize);
        this.setState({shownReleases: shown});*/
    }

    filterReleases() {
        var obj = this;
        var releases = obj.state.releases;
        var filter = obj.state.filter;
        if (filter.length === 0) {
            obj.setState({shownReleases: releases});
        } else {
            var tags = [];
            for (var i = 0; i < filter.length; i++) {
                tags.push(filter[i].id.toLowerCase());
            }
            var shownReleases = releases.filter((release) => {
                if (tags.includes(release.title.toLowerCase())) {
                    return true;
                }
                for (var j = 0; j < release.artists.length; j++) {
                    var artist = release.artists[j];
                    if (tags.includes(artist.id.toLowerCase())) {
                        return true;
                    }
                }
                for (var k = 0; k < release.genres.length; k++) {
                    var genre = release.genres[k];
                    if (tags.includes(genre.id.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            })
            obj.setState({shownReleases: shownReleases});
        }                
    }

    handleDeleteFilter(i) {
        var filter = this.state.filter;
        filter = filter.filter((tag, index) => index !== i);
        this.setState({filter: filter}, this.filterReleases);
       
    }
 
    handleAddFilter(tag) {
        var value = tag.id;
        var trimmedTag = {};
        if (! isUndefined(value)) {
            value = value.trim();
            trimmedTag = {id: value, text: value};
        }
        if (! isUndefined(value) && value !== "") {
            var filter = this.state.filter;
            filter = [...filter, trimmedTag];            
            this.setState({filter: filter}, this.filterReleases);
        }        
    }
	
	render() {
        var obj = this;
        var releases = obj.state.releases;
        var shown = obj.state.shownReleases;
        var menu = obj.state.showMenu;
        var filter = obj.state.filter;
        var suggestions = obj.state.suggestions;
		return (		
			<div className="Feed">
                <div className="FeedHeader">
                    <div className="FeedHeaderMenuButton" onClick={obj.toggleMenu}><FontAwesome name="bars"/></div>                    
                </div>
                <div className="FeedReleases">
                    <InfiniteScroll
                        dataLength={shown.length}
                        next={this.addMoreReleases}
                        hasMore={releases.length > shown.length}
                        loader={<h4>Loading...</h4>}
                        >
                        {this.state.shownReleases.map((release, index) => (
                            <FeedRelease release={release} key={release._id}/>
                        ))}
                    </InfiniteScroll>
                </div>
                {menu ? (
                <div className="FeedHeaderMenu">
                    <div className="FeedHeaderMenuItem NoClick">
                        Filter<br/>
                        <div className="FilterInput">
							<ReactTags tags={filter}									
								handleDelete={this.handleDeleteFilter}
								handleAddition={this.handleAddFilter}
								handleInputBlur={(value) => { obj.handleAddFilter({id: value, text: value}); }}
								allowDragDrop={false}
								placeholder="Title/Artist/Genre"
								suggestions={suggestions}
								inline
							/>
						</div>
                    </div>                    
                    <div className="FeedHeaderMenuItem Special" onClick={obj.href("/artists")}>
                        Early Grooves for Artists
                    </div>
                </div>) : null }
			</div>
		
		);
	}
}

export { Feed };
