import React, { Component } from 'react';
import makeCarousel from 'react-reveal/makeCarousel';
import Slide from 'react-reveal/Slide';
import './Carousel.css';
import FontAwesome from 'react-fontawesome';

const carouselWrap = ({ position, total, handleClick, children }) => (
<div className="CarouselInner">
	<div className="CarouselElements">
		{children}		
	</div>
	<div className="CarouselLeftArrow" onClick={handleClick} data-position={position - 1}><FontAwesome name="chevron-circle-left" /></div>
	<div className="CarouselRightArrow" onClick={handleClick} data-position={position + 1}><FontAwesome name="chevron-circle-right" /></div>
</div>
);

const InnerCarousel = makeCarousel(carouselWrap);

class Carousel extends Component {

	constructor(props) {
		super(props);
		this.state = { elements: props.elements, delay: 0 };
	}
	
	render() {
		return (
			<InnerCarousel defaultWait={this.state.delay}>
				{this.state.elements.map((element, idx) => {
					return (
						<Slide key={element.title} right>
							<div className="CarouselElement">
								<div className="CarouselElementImage">
									<video poster={element.poster} loop autoPlay muted>
										<source src={element.media.webm} type="video/webm" />
										<source src={element.media.mp4} type="video/mp4" />
									</video>
								</div>
								<div className="CarouselElementTitle">{element.title}</div>
							</div>
						</Slide>
					);
				})}
			</InnerCarousel>
		);
	}
}

export { Carousel };