import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import './Reset.css';

class ResetPassword extends Component {
	
	constructor(props) {
		super(props);
		this.reset = this.reset.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.validate = this.validate.bind(this);
		this.detailsOkay = this.detailsOkay.bind(this);
		var token = this.props.match.params.token;
		this.state = {
			token: token,
			values: {password: false, confirmPassword: false},
			errors: {password: false, confirmPassword: false},
			message: false,
			done: false
		}
	}

	handleUpdate(event) {
		var values = this.state.values;
		var name = event.target.id;
		var value = event.target.value;
		values[name] = value;
		this.setState({values: values});
		this.validate(name, value);
	}

	validate(name, value) {
		if (name === "password") {
			var lowerCaseLetters = /[a-z]/g;
			var upperCaseLetters = /[A-Z]/g;
			var numbers = /[0-9]/g;			
			if (value.match(lowerCaseLetters) && value.match(upperCaseLetters) && value.match(numbers) && value.length >= 8) {
				var errors = this.state.errors;
				errors.password = false;				
				this.setState({errors: errors});
			} else {
				var errors = this.state.errors;
				errors.password = "Password invalid";				
				this.setState({errors: errors});
			}
		} else if (name === "confirmPassword") {
			if (value === this.state.values.password) {
				var errors = this.state.errors;
				errors.confirmPassword = false;				
				this.setState({errors: errors});
			} else {
				var errors = this.state.errors;
				errors.confirmPassword = "Needs to match password";				
				this.setState({errors: errors});
			}
		}
	}

	detailsOkay() {
		var errors = this.state.errors;
		var content = this.state.values.password;
		return content && (! (errors.password || errors.confirmPassword));
	}
	
	reset() {
		var obj = this; 
		return () => {
			var password = obj.state.values.password;
			Ajax.resetPassword(obj.state.token, password).then(function (res) {
				if (res.error) {
					obj.setState({message: res.error});
				}
				else {
					obj.setState({message: "Password reset successfully.", done: true});	
				}			
			});
		};		
	}
	
	render() {
		var done = this.state.done;
		return (
		<div className="Reset">
			<div className="ResetLogo" />
			{done ? (
			<div className="ResetSuccessMessage">
				<p>Password successfully reset! Please log in <a href="https://earlygrooves.com">here</a>.</p>
			</div>) : (
			<div className="Form">
				<p>You have requested to change your password. Enter your new password below.</p>
				<div className="FormField">
					<div className="FormFieldLabel">Password</div>
					<input type="password" id="password" placeholder="Password" value={this.state.values.password ? this.state.values.password : ""} onChange={this.handleUpdate} /><br/>
					<p>Password must contain at least one uppercase letter, one lowercase letter, one number, and must be at least 8 characters long</p>
					{ this.state.errors.password ? (<div className="Error">{this.state.errors.password}</div>) : null }
				</div>
				<div className="FormField">
					<div className="FormFieldLabel">Confirm Password</div>
					<input type="password" id="confirmPassword" placeholder="Confirm password" value={this.state.values.confirmPassword ? this.state.values.confirmPassword : ""} onChange={this.handleUpdate} />
					{ this.state.errors.confirmPassword ? (<div className="Error">{this.state.errors.confirmPassword}</div>) : null }
				</div>
				<div className="ResetButton">					
					{ this.detailsOkay() ? (<div className="Button" onClick={this.reset()}>Reset Password</div>) : (<div className="Button Inactive">Reset Password</div>) }
				</div>
				<div className="Message">{this.state.message}</div>
			</div>
			) 
			}
		</div>
		);
	}
}

export { ResetPassword };