import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from '../common/Loading.js';

class FAQ extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			loading: true
		}
	}

	componentWillMount() {
		var obj = this;
		obj.setState({loading: false});
	}
	
	render() {
		return (
			<DashboardComponent>
				<div className="FAQ">
					<div className="DashboardComponentHeader">
						<div className="DashboardComponentTitle"><FontAwesome name="question-circle" /> FAQ</div>
						<div className="DashboardComponentMenu">
						</div>
					</div>

					<div className="DashboardComponentSection">
					</div>
					
				</div>
				{ this.state.loading ? (<Loading />) : null }
			</DashboardComponent>
		);
	}
}

export { FAQ };