const AUTOSAVE = 0;
const DRAFT = 1;
const ACTIVE = 2;

const defaultNewsletter = [
    {type: "cover", active: true, variables: {cover: false, minipageLink: false}},
    {type: "titleArtist", active: true, variables: {title: "", artist: ""}},
    {type: "checkItOut", active: true, variables: {minipageLink: false}},
    {type: "platformLinks", active: true, variables: {links: []}},
    {type: "description", active: true, variables: {description: ""}},
    {type: "releaseDate", active: true, variables: {releaseDate: new Date()}},
    {type: "tags", active: true, variables: {genres: ""}},
    {type: "catalogNr", active: true, variables: {catalogNumber: ""}}
];

const defaultPresskit = [
    {type: "cover", active: true, variables: {cover: false, minipageLink: false}},
    {type: "titleArtist", active: true, variables: {title: "", artist: ""}},
    {type: "checkItOut", active: true, variables: {minipageLink: false}},
    {type: "download", active: true, variables: {minipageLink: false}},
    {type: "platformLinks", active: true, variables: {links: []}},
    {type: "description", active: true, variables: {description: ""}},
    {type: "releaseDate", active: true, variables: {releaseDate: new Date()}},
    {type: "tags", active: true, variables: {genres: ""}},
    {type: "catalogNr", active: true, variables: {catalogNumber: ""}},
    {type: "catalogLink", active: true, variables: {catalogLink: false}},
    {type: "contact", active: true, variables: {contactEmail: false}}
];

const emptyRelease = {
    _id: false,
    status: AUTOSAVE,
    newsletterSent: false,
    presskitSent: false,
    upc: "", 
    ean: "",
    title: "", 
    artists: [], 
    createdOn: new Date(),
    releaseDate: new Date(), 
    prereleaseDate: new Date(), 
    tracks: [],
    cover: {large: false, small: false}, 
    description: "", 
    catalogNumber: "", 
    links: [],
    genres: [],
    slug: false,
    minipage: false,
    demo: false,
    newsletter: [...defaultNewsletter],
    presskit: [...defaultPresskit]
};	

function makeEmptyRelease() {
    return {...emptyRelease};
}

function makeDefaultNewsletter() {
    return [...defaultNewsletter];
}

function makeDefaultPresskit() {
    return [...defaultPresskit];
}

export { makeEmptyRelease, makeDefaultNewsletter, makeDefaultPresskit };