const genres = [
{id: "2 tone", text: "2 tone"},
{id: "2-step garage", text: "2-step garage"},
{id: "4-beat", text: "4-beat"},
{id: "4x4 garage", text: "4x4 garage"},
{id: "8-bit", text: "8-bit"},
{id: "acapella", text: "acapella"},
{id: "acid", text: "acid"},
{id: "acid breaks", text: "acid breaks"},
{id: "acid house", text: "acid house"},
{id: "acid jazz", text: "acid jazz"},
{id: "acid rock", text: "acid rock"},
{id: "acoustic music", text: "acoustic music"},
{id: "acousticana", text: "acousticana"},
{id: "adult contemporary music", text: "adult contemporary music"},
{id: "african popular music", text: "african popular music"},
{id: "african rumba", text: "african rumba"},
{id: "afrobeat", text: "afrobeat"},
{id: "aleatoric music", text: "aleatoric music"},
{id: "alternative country", text: "alternative country"},
{id: "alternative dance", text: "alternative dance"},
{id: "alternative hip hop", text: "alternative hip hop"},
{id: "alternative metal", text: "alternative metal"},
{id: "alternative rock", text: "alternative rock"},
{id: "ambient", text: "ambient"},
{id: "ambient house", text: "ambient house"},
{id: "ambient music", text: "ambient music"},
{id: "americana", text: "americana"},
{id: "anarcho punk", text: "anarcho punk"},
{id: "anime music", text: "anime music"},
{id: "anti-folk", text: "anti-folk"},
{id: "apala", text: "apala"},
{id: "ape haters", text: "ape haters"},
{id: "arab pop", text: "arab pop"},
{id: "arabesque", text: "arabesque"},
{id: "arabic pop", text: "arabic pop"},
{id: "argentine rock", text: "argentine rock"},
{id: "ars antiqua", text: "ars antiqua"},
{id: "ars nova", text: "ars nova"},
{id: "art punk", text: "art punk"},
{id: "art rock", text: "art rock"},
{id: "ashiq", text: "ashiq"},
{id: "asian american jazz", text: "asian american jazz"},
{id: "australian country music", text: "australian country music"},
{id: "australian hip hop", text: "australian hip hop"},
{id: "australian pub rock", text: "australian pub rock"},
{id: "austropop", text: "austropop"},
{id: "avant-garde", text: "avant-garde"},
{id: "avant-garde jazz", text: "avant-garde jazz"},
{id: "avant-garde metal", text: "avant-garde metal"},
{id: "avant-garde music", text: "avant-garde music"},
{id: "axé", text: "axé"},
{id: "bac-bal", text: "bac-bal"},
{id: "bachata", text: "bachata"},
{id: "background music", text: "background music"},
{id: "baggy", text: "baggy"},
{id: "baila", text: "baila"},
{id: "baile funk", text: "baile funk"},
{id: "baisha xiyue", text: "baisha xiyue"},
{id: "baithak gana", text: "baithak gana"},
{id: "baião", text: "baião"},
{id: "bajourou", text: "bajourou"},
{id: "bakersfield sound", text: "bakersfield sound"},
{id: "bakou", text: "bakou"},
{id: "bakshy", text: "bakshy"},
{id: "bal-musette", text: "bal-musette"},
{id: "balakadri", text: "balakadri"},
{id: "balinese gamelan", text: "balinese gamelan"},
{id: "balkan pop", text: "balkan pop"},
{id: "ballad", text: "ballad"},
{id: "ballata", text: "ballata"},
{id: "ballet", text: "ballet"},
{id: "bamboo band", text: "bamboo band"},
{id: "bambuco", text: "bambuco"},
{id: "banda", text: "banda"},
{id: "bangsawan", text: "bangsawan"},
{id: "bantowbol", text: "bantowbol"},
{id: "barbershop music", text: "barbershop music"},
{id: "barndance", text: "barndance"},
{id: "baroque music", text: "baroque music"},
{id: "baroque pop", text: "baroque pop"},
{id: "bass music", text: "bass music"},
{id: "batcave", text: "batcave"},
{id: "batucada", text: "batucada"},
{id: "batuco", text: "batuco"},
{id: "batá-rumba", text: "batá-rumba"},
{id: "beach music", text: "beach music"},
{id: "beat", text: "beat"},
{id: "beatboxing", text: "beatboxing"},
{id: "beautiful music", text: "beautiful music"},
{id: "bebop", text: "bebop"},
{id: "beiguan", text: "beiguan"},
{id: "bel canto", text: "bel canto"},
{id: "bend-skin", text: "bend-skin"},
{id: "benga", text: "benga"},
{id: "berlin school of electronic music", text: "berlin school of electronic music"},
{id: "bhajan", text: "bhajan"},
{id: "bhangra", text: "bhangra"},
{id: "bhangra-wine", text: "bhangra-wine"},
{id: "bhangragga", text: "bhangragga"},
{id: "bhangramuffin", text: "bhangramuffin"},
{id: "big band", text: "big band"},
{id: "big band music", text: "big band music"},
{id: "big beat", text: "big beat"},
{id: "biguine", text: "biguine"},
{id: "bihu", text: "bihu"},
{id: "bikutsi", text: "bikutsi"},
{id: "biomusic", text: "biomusic"},
{id: "bitcore", text: "bitcore"},
{id: "bitpop", text: "bitpop"},
{id: "black metal", text: "black metal"},
{id: "blackened death metal", text: "blackened death metal"},
{id: "blue-eyed soul", text: "blue-eyed soul"},
{id: "bluegrass", text: "bluegrass"},
{id: "blues", text: "blues"},
{id: "blues ballad", text: "blues ballad"},
{id: "blues-rock", text: "blues-rock"},
{id: "boogie", text: "boogie"},
{id: "boogie woogie", text: "boogie woogie"},
{id: "boogie-woogie", text: "boogie-woogie"},
{id: "bossa nova", text: "bossa nova"},
{id: "brass band", text: "brass band"},
{id: "brazilian funk", text: "brazilian funk"},
{id: "brazilian jazz", text: "brazilian jazz"},
{id: "breakbeat", text: "breakbeat"},
{id: "breakbeat hardcore", text: "breakbeat hardcore"},
{id: "breakcore", text: "breakcore"},
{id: "breton music", text: "breton music"},
{id: "brill building pop", text: "brill building pop"},
{id: "britfunk", text: "britfunk"},
{id: "british blues", text: "british blues"},
{id: "british invasion", text: "british invasion"},
{id: "britpop", text: "britpop"},
{id: "broken beat", text: "broken beat"},
{id: "brown-eyed soul", text: "brown-eyed soul"},
{id: "brukdown", text: "brukdown"},
{id: "brutal death metal", text: "brutal death metal"},
{id: "bubblegum dance", text: "bubblegum dance"},
{id: "bubblegum pop", text: "bubblegum pop"},
{id: "bulerias", text: "bulerias"},
{id: "bumba-meu-boi", text: "bumba-meu-boi"},
{id: "bunraku", text: "bunraku"},
{id: "burger-highlife", text: "burger-highlife"},
{id: "burgundian school", text: "burgundian school"},
{id: "byzantine chant", text: "byzantine chant"},
{id: "ca din tulnic", text: "ca din tulnic"},
{id: "ca pe lunca", text: "ca pe lunca"},
{id: "ca trù", text: "ca trù"},
{id: "cabaret", text: "cabaret"},
{id: "cadence", text: "cadence"},
{id: "cadence rampa", text: "cadence rampa"},
{id: "cadence-lypso", text: "cadence-lypso"},
{id: "café-aman", text: "café-aman"},
{id: "cai luong", text: "cai luong"},
{id: "cajun music", text: "cajun music"},
{id: "cakewalk", text: "cakewalk"},
{id: "calenda", text: "calenda"},
{id: "calentanos", text: "calentanos"},
{id: "calgia", text: "calgia"},
{id: "calypso", text: "calypso"},
{id: "calypso jazz", text: "calypso jazz"},
{id: "calypso-style baila", text: "calypso-style baila"},
{id: "campursari", text: "campursari"},
{id: "canatronic", text: "canatronic"},
{id: "candombe", text: "candombe"},
{id: "canon", text: "canon"},
{id: "canrock", text: "canrock"},
{id: "cantata", text: "cantata"},
{id: "cante chico", text: "cante chico"},
{id: "cante jondo", text: "cante jondo"},
{id: "canterbury scene", text: "canterbury scene"},
{id: "cantiga", text: "cantiga"},
{id: "cantique", text: "cantique"},
{id: "cantiñas", text: "cantiñas"},
{id: "canto livre", text: "canto livre"},
{id: "canto nuevo", text: "canto nuevo"},
{id: "canto popular", text: "canto popular"},
{id: "cantopop", text: "cantopop"},
{id: "canzone napoletana", text: "canzone napoletana"},
{id: "cape jazz", text: "cape jazz"},
{id: "capoeira music", text: "capoeira music"},
{id: "caracoles", text: "caracoles"},
{id: "carceleras", text: "carceleras"},
{id: "cardas", text: "cardas"},
{id: "cardiowave", text: "cardiowave"},
{id: "carimbó", text: "carimbó"},
{id: "cariso", text: "cariso"},
{id: "carnatic music", text: "carnatic music"},
{id: "carol", text: "carol"},
{id: "cartageneras", text: "cartageneras"},
{id: "cassette culture", text: "cassette culture"},
{id: "casséy-co", text: "casséy-co"},
{id: "cavacha", text: "cavacha"},
{id: "caveman", text: "caveman"},
{id: "caña", text: "caña"},
{id: "celempungan", text: "celempungan"},
{id: "cello rock", text: "cello rock"},
{id: "celtic", text: "celtic"},
{id: "celtic fusion", text: "celtic fusion"},
{id: "celtic metal", text: "celtic metal"},
{id: "celtic punk", text: "celtic punk"},
{id: "celtic reggae", text: "celtic reggae"},
{id: "celtic rock", text: "celtic rock"},
{id: "cha-cha-cha", text: "cha-cha-cha"},
{id: "chakacha", text: "chakacha"},
{id: "chalga", text: "chalga"},
{id: "chamamé", text: "chamamé"},
{id: "chamber jazz", text: "chamber jazz"},
{id: "chamber music", text: "chamber music"},
{id: "chamber pop", text: "chamber pop"},
{id: "champeta", text: "champeta"},
{id: "changuí", text: "changuí"},
{id: "chanson", text: "chanson"},
{id: "chant", text: "chant"},
{id: "charanga", text: "charanga"},
{id: "charanga-vallenata", text: "charanga-vallenata"},
{id: "charikawi", text: "charikawi"},
{id: "chastushki", text: "chastushki"},
{id: "chau van", text: "chau van"},
{id: "chemical breaks", text: "chemical breaks"},
{id: "chicago blues", text: "chicago blues"},
{id: "chicago house", text: "chicago house"},
{id: "chicago soul", text: "chicago soul"},
{id: "chicano rap", text: "chicano rap"},
{id: "chicha", text: "chicha"},
{id: "chicken scratch", text: "chicken scratch"},
{id: "children's music", text: "children's music"},
{id: "chillout", text: "chillout"},
{id: "chillwave", text: "chillwave"},
{id: "chimurenga", text: "chimurenga"},
{id: "chinese music", text: "chinese music"},
{id: "chinese pop", text: "chinese pop"},
{id: "chinese rock", text: "chinese rock"},
{id: "chip music", text: "chip music"},
{id: "cho-kantrum", text: "cho-kantrum"},
{id: "chongak", text: "chongak"},
{id: "chopera", text: "chopera"},
{id: "chorinho", text: "chorinho"},
{id: "choro", text: "choro"},
{id: "chouval bwa", text: "chouval bwa"},
{id: "chowtal", text: "chowtal"},
{id: "christian alternative", text: "christian alternative"},
{id: "christian black metal", text: "christian black metal"},
{id: "christian electronic music", text: "christian electronic music"},
{id: "christian hardcore", text: "christian hardcore"},
{id: "christian hip hop", text: "christian hip hop"},
{id: "christian industrial", text: "christian industrial"},
{id: "christian metal", text: "christian metal"},
{id: "christian music", text: "christian music"},
{id: "christian punk", text: "christian punk"},
{id: "christian r&b", text: "christian r&b"},
{id: "christian rock", text: "christian rock"},
{id: "christian ska", text: "christian ska"},
{id: "christmas carol", text: "christmas carol"},
{id: "christmas music", text: "christmas music"},
{id: "chumba", text: "chumba"},
{id: "chut-kai-pang", text: "chut-kai-pang"},
{id: "chutney", text: "chutney"},
{id: "chutney soca", text: "chutney soca"},
{id: "chutney-bhangra", text: "chutney-bhangra"},
{id: "chutney-hip hop", text: "chutney-hip hop"},
{id: "chutney-soca", text: "chutney-soca"},
{id: "chylandyk", text: "chylandyk"},
{id: "chzalni", text: "chzalni"},
{id: "chèo", text: "chèo"},
{id: "cigányzene", text: "cigányzene"},
{id: "classic", text: "classic"},
{id: "classic country", text: "classic country"},
{id: "classic female blues", text: "classic female blues"},
{id: "classic rock", text: "classic rock"},
{id: "classical music", text: "classical music"},
{id: "classical music era", text: "classical music era"},
{id: "clicks n cuts", text: "clicks n cuts"},
{id: "close harmony", text: "close harmony"},
{id: "club music", text: "club music"},
{id: "cocobale", text: "cocobale"},
{id: "coimbra fado", text: "coimbra fado"},
{id: "coladeira", text: "coladeira"},
{id: "colombianas", text: "colombianas"},
{id: "combined rhythm", text: "combined rhythm"},
{id: "comedy rap", text: "comedy rap"},
{id: "comedy rock", text: "comedy rock"},
{id: "comic opera", text: "comic opera"},
{id: "comparsa", text: "comparsa"},
{id: "compas direct", text: "compas direct"},
{id: "compas meringue", text: "compas meringue"},
{id: "concert overture", text: "concert overture"},
{id: "concerto", text: "concerto"},
{id: "concerto grosso", text: "concerto grosso"},
{id: "congo", text: "congo"},
{id: "conjunto", text: "conjunto"},
{id: "contemporary christian", text: "contemporary christian"},
{id: "contemporary christian music", text: "contemporary christian music"},
{id: "contemporary r&b", text: "contemporary r&b"},
{id: "contonbley", text: "contonbley"},
{id: "contradanza", text: "contradanza"},
{id: "cool jazz", text: "cool jazz"},
{id: "corrido", text: "corrido"},
{id: "corsican polyphonic song", text: "corsican polyphonic song"},
{id: "cothoza mfana", text: "cothoza mfana"},
{id: "country", text: "country"},
{id: "country blues", text: "country blues"},
{id: "country gospel", text: "country gospel"},
{id: "country music", text: "country music"},
{id: "country pop", text: "country pop"},
{id: "country r&b", text: "country r&b"},
{id: "country rock", text: "country rock"},
{id: "country-rap", text: "country-rap"},
{id: "countrypolitan", text: "countrypolitan"},
{id: "couple de sonneurs", text: "couple de sonneurs"},
{id: "coupé-décalé", text: "coupé-décalé"},
{id: "cowpunk", text: "cowpunk"},
{id: "cretan music", text: "cretan music"},
{id: "crossover jazz", text: "crossover jazz"},
{id: "crossover music", text: "crossover music"},
{id: "crossover thrash", text: "crossover thrash"},
{id: "crossover thrash metal", text: "crossover thrash metal"},
{id: "crunk", text: "crunk"},
{id: "crunk&b", text: "crunk&b"},
{id: "crunkcore", text: "crunkcore"},
{id: "crust punk", text: "crust punk"},
{id: "csárdás", text: "csárdás"},
{id: "cuarteto", text: "cuarteto"},
{id: "cuban rumba", text: "cuban rumba"},
{id: "cuddlecore", text: "cuddlecore"},
{id: "cueca", text: "cueca"},
{id: "cumbia", text: "cumbia"},
{id: "cumbia villera", text: "cumbia villera"},
{id: "cybergrind", text: "cybergrind"},
{id: "dabka", text: "dabka"},
{id: "dadra", text: "dadra"},
{id: "daina", text: "daina"},
{id: "dalauna", text: "dalauna"},
{id: "dance", text: "dance"},
{id: "dance music", text: "dance music"},
{id: "dance-pop", text: "dance-pop"},
{id: "dance-punk", text: "dance-punk"},
{id: "dance-rock", text: "dance-rock"},
{id: "dancehall", text: "dancehall"},
{id: "dangdut", text: "dangdut"},
{id: "danger music", text: "danger music"},
{id: "dansband", text: "dansband"},
{id: "danza", text: "danza"},
{id: "danzón", text: "danzón"},
{id: "dark ambient", text: "dark ambient"},
{id: "dark cabaret", text: "dark cabaret"},
{id: "dark pop", text: "dark pop"},
{id: "darkcore", text: "darkcore"},
{id: "darkstep", text: "darkstep"},
{id: "darkwave", text: "darkwave"},
{id: "de ascultat la servici", text: "de ascultat la servici"},
{id: "de codru", text: "de codru"},
{id: "de dragoste", text: "de dragoste"},
{id: "de jale", text: "de jale"},
{id: "de pahar", text: "de pahar"},
{id: "death industrial", text: "death industrial"},
{id: "death metal", text: "death metal"},
{id: "death rock", text: "death rock"},
{id: "death/doom", text: "death/doom"},
{id: "deathcore", text: "deathcore"},
{id: "deathgrind", text: "deathgrind"},
{id: "deathrock", text: "deathrock"},
{id: "deep funk", text: "deep funk"},
{id: "deep house", text: "deep house"},
{id: "deep soul", text: "deep soul"},
{id: "degung", text: "degung"},
{id: "delta blues", text: "delta blues"},
{id: "dementia", text: "dementia"},
{id: "desert rock", text: "desert rock"},
{id: "desi", text: "desi"},
{id: "detroit blues", text: "detroit blues"},
{id: "detroit techno", text: "detroit techno"},
{id: "dhamar", text: "dhamar"},
{id: "dhimotiká", text: "dhimotiká"},
{id: "dhrupad", text: "dhrupad"},
{id: "dhun", text: "dhun"},
{id: "digital hardcore", text: "digital hardcore"},
{id: "dirge", text: "dirge"},
{id: "dirty dutch", text: "dirty dutch"},
{id: "dirty rap", text: "dirty rap"},
{id: "dirty rap/pornocore", text: "dirty rap/pornocore"},
{id: "dirty south", text: "dirty south"},
{id: "disco", text: "disco"},
{id: "disco house", text: "disco house"},
{id: "disco polo", text: "disco polo"},
{id: "disney", text: "disney"},
{id: "disney hardcore", text: "disney hardcore"},
{id: "disney pop", text: "disney pop"},
{id: "diva house", text: "diva house"},
{id: "divine rock", text: "divine rock"},
{id: "dixieland", text: "dixieland"},
{id: "dixieland jazz", text: "dixieland jazz"},
{id: "djambadon", text: "djambadon"},
{id: "djent", text: "djent"},
{id: "dodompa", text: "dodompa"},
{id: "doina", text: "doina"},
{id: "dombola", text: "dombola"},
{id: "dondang sayang", text: "dondang sayang"},
{id: "donegal fiddle tradition", text: "donegal fiddle tradition"},
{id: "dongjing", text: "dongjing"},
{id: "doo wop", text: "doo wop"},
{id: "doom metal", text: "doom metal"},
{id: "doomcore", text: "doomcore"},
{id: "downtempo", text: "downtempo"},
{id: "drag", text: "drag"},
{id: "dream pop", text: "dream pop"},
{id: "drone doom", text: "drone doom"},
{id: "drone metal", text: "drone metal"},
{id: "drone music", text: "drone music"},
{id: "dronology", text: "dronology"},
{id: "drum and bass", text: "drum and bass"},
{id: "dub", text: "dub"},
{id: "dub house", text: "dub house"},
{id: "dubanguthu", text: "dubanguthu"},
{id: "dubstep", text: "dubstep"},
{id: "dubtronica", text: "dubtronica"},
{id: "dunedin sound", text: "dunedin sound"},
{id: "dunun", text: "dunun"},
{id: "dutch jazz", text: "dutch jazz"},
{id: "décima", text: "décima"},
{id: "early music", text: "early music"},
{id: "east coast blues", text: "east coast blues"},
{id: "east coast hip hop", text: "east coast hip hop"},
{id: "easy listening", text: "easy listening"},
{id: "electric blues", text: "electric blues"},
{id: "electric folk", text: "electric folk"},
{id: "electro", text: "electro"},
{id: "electro backbeat", text: "electro backbeat"},
{id: "electro hop", text: "electro hop"},
{id: "electro house", text: "electro house"},
{id: "electro punk", text: "electro punk"},
{id: "electro-industrial", text: "electro-industrial"},
{id: "electro-swing", text: "electro-swing"},
{id: "electroclash", text: "electroclash"},
{id: "electrofunk", text: "electrofunk"},
{id: "electronic", text: "electronic"},
{id: "electronic art music", text: "electronic art music"},
{id: "electronic body music", text: "electronic body music"},
{id: "electronic dance", text: "electronic dance"},
{id: "electronic luk thung", text: "electronic luk thung"},
{id: "electronic music", text: "electronic music"},
{id: "electronic rock", text: "electronic rock"},
{id: "electronica", text: "electronica"},
{id: "electropop", text: "electropop"},
{id: "elevator music", text: "elevator music"},
{id: "emo", text: "emo"},
{id: "emo pop", text: "emo pop"},
{id: "emo rap", text: "emo rap"},
{id: "emocore", text: "emocore"},
{id: "emotronic", text: "emotronic"},
{id: "enka", text: "enka"},
{id: "eremwu eu", text: "eremwu eu"},
{id: "essential rock", text: "essential rock"},
{id: "ethereal pop", text: "ethereal pop"},
{id: "ethereal wave", text: "ethereal wave"},
{id: "euro", text: "euro"},
{id: "euro disco", text: "euro disco"},
{id: "eurobeat", text: "eurobeat"},
{id: "eurodance", text: "eurodance"},
{id: "europop", text: "europop"},
{id: "eurotrance", text: "eurotrance"},
{id: "eurourban", text: "eurourban"},
{id: "exotica", text: "exotica"},
{id: "experimental music", text: "experimental music"},
{id: "experimental noise", text: "experimental noise"},
{id: "experimental pop", text: "experimental pop"},
{id: "experimental rock", text: "experimental rock"},
{id: "extreme metal", text: "extreme metal"},
{id: "ezengileer", text: "ezengileer"},
{id: "fado", text: "fado"},
{id: "falak", text: "falak"},
{id: "fandango", text: "fandango"},
{id: "farruca", text: "farruca"},
{id: "fife and drum blues", text: "fife and drum blues"},
{id: "filk", text: "filk"},
{id: "film score", text: "film score"},
{id: "filmi", text: "filmi"},
{id: "filmi-ghazal", text: "filmi-ghazal"},
{id: "finger-style", text: "finger-style"},
{id: "fjatpangarri", text: "fjatpangarri"},
{id: "flamenco", text: "flamenco"},
{id: "flamenco rumba", text: "flamenco rumba"},
{id: "flower power", text: "flower power"},
{id: "foaie verde", text: "foaie verde"},
{id: "fofa", text: "fofa"},
{id: "folk hop", text: "folk hop"},
{id: "folk metal", text: "folk metal"},
{id: "folk music", text: "folk music"},
{id: "folk pop", text: "folk pop"},
{id: "folk punk", text: "folk punk"},
{id: "folk rock", text: "folk rock"},
{id: "folktronica", text: "folktronica"},
{id: "forró", text: "forró"},
{id: "franco-country", text: "franco-country"},
{id: "freak-folk", text: "freak-folk"},
{id: "freakbeat", text: "freakbeat"},
{id: "free improvisation", text: "free improvisation"},
{id: "free jazz", text: "free jazz"},
{id: "free music", text: "free music"},
{id: "freestyle", text: "freestyle"},
{id: "freestyle house", text: "freestyle house"},
{id: "freetekno", text: "freetekno"},
{id: "french pop", text: "french pop"},
{id: "frenchcore", text: "frenchcore"},
{id: "frevo", text: "frevo"},
{id: "fricote", text: "fricote"},
{id: "fuji", text: "fuji"},
{id: "fuji music", text: "fuji music"},
{id: "fulia", text: "fulia"},
{id: "full on", text: "full on"},
{id: "funaná", text: "funaná"},
{id: "funeral doom", text: "funeral doom"},
{id: "funk", text: "funk"},
{id: "funk metal", text: "funk metal"},
{id: "funk rock", text: "funk rock"},
{id: "funkcore", text: "funkcore"},
{id: "funky house", text: "funky house"},
{id: "furniture music", text: "furniture music"},
{id: "fusion jazz", text: "fusion jazz"},
{id: "g-funk", text: "g-funk"},
{id: "gaana", text: "gaana"},
{id: "gabba", text: "gabba"},
{id: "gabber", text: "gabber"},
{id: "gagaku", text: "gagaku"},
{id: "gaikyoku", text: "gaikyoku"},
{id: "gaita", text: "gaita"},
{id: "galant", text: "galant"},
{id: "gamad", text: "gamad"},
{id: "gambang kromong", text: "gambang kromong"},
{id: "gamelan", text: "gamelan"},
{id: "gamelan angklung", text: "gamelan angklung"},
{id: "gamelan bang", text: "gamelan bang"},
{id: "gamelan bebonangan", text: "gamelan bebonangan"},
{id: "gamelan buh", text: "gamelan buh"},
{id: "gamelan degung", text: "gamelan degung"},
{id: "gamelan gede", text: "gamelan gede"},
{id: "gamelan kebyar", text: "gamelan kebyar"},
{id: "gamelan salendro", text: "gamelan salendro"},
{id: "gamelan selunding", text: "gamelan selunding"},
{id: "gamelan semar pegulingan", text: "gamelan semar pegulingan"},
{id: "gamewave", text: "gamewave"},
{id: "gammeldans", text: "gammeldans"},
{id: "gandrung", text: "gandrung"},
{id: "gangsta rap", text: "gangsta rap"},
{id: "gar", text: "gar"},
{id: "garage rock", text: "garage rock"},
{id: "garrotin", text: "garrotin"},
{id: "gavotte", text: "gavotte"},
{id: "gelugpa chanting", text: "gelugpa chanting"},
{id: "gender wayang", text: "gender wayang"},
{id: "gending", text: "gending"},
{id: "german folk music", text: "german folk music"},
{id: "gharbi", text: "gharbi"},
{id: "gharnati", text: "gharnati"},
{id: "ghazal", text: "ghazal"},
{id: "ghazal-song", text: "ghazal-song"},
{id: "ghetto house", text: "ghetto house"},
{id: "ghettotech", text: "ghettotech"},
{id: "girl group", text: "girl group"},
{id: "glam metal", text: "glam metal"},
{id: "glam punk", text: "glam punk"},
{id: "glam rock", text: "glam rock"},
{id: "glitch", text: "glitch"},
{id: "gnawa", text: "gnawa"},
{id: "go-go", text: "go-go"},
{id: "goa", text: "goa"},
{id: "goa trance", text: "goa trance"},
{id: "gong-chime music", text: "gong-chime music"},
{id: "goombay", text: "goombay"},
{id: "goregrind", text: "goregrind"},
{id: "goshu ondo", text: "goshu ondo"},
{id: "gospel music", text: "gospel music"},
{id: "gothic metal", text: "gothic metal"},
{id: "gothic rock", text: "gothic rock"},
{id: "granadinas", text: "granadinas"},
{id: "grebo", text: "grebo"},
{id: "gregorian chant", text: "gregorian chant"},
{id: "grime", text: "grime"},
{id: "grindcore", text: "grindcore"},
{id: "groove metal", text: "groove metal"},
{id: "group sounds", text: "group sounds"},
{id: "grunge", text: "grunge"},
{id: "grupera", text: "grupera"},
{id: "guaguanbo", text: "guaguanbo"},
{id: "guajira", text: "guajira"},
{id: "guasca", text: "guasca"},
{id: "guitarra baiana", text: "guitarra baiana"},
{id: "guitarradas", text: "guitarradas"},
{id: "gumbe", text: "gumbe"},
{id: "gunchei", text: "gunchei"},
{id: "gunka", text: "gunka"},
{id: "guoyue", text: "guoyue"},
{id: "gwo ka", text: "gwo ka"},
{id: "gwo ka moderne", text: "gwo ka moderne"},
{id: "gypsy jazz", text: "gypsy jazz"},
{id: "gypsy punk", text: "gypsy punk"},
{id: "gypsybilly", text: "gypsybilly"},
{id: "gyu ke", text: "gyu ke"},
{id: "habanera", text: "habanera"},
{id: "hajnali", text: "hajnali"},
{id: "hakka", text: "hakka"},
{id: "halling", text: "halling"},
{id: "hambo", text: "hambo"},
{id: "hands up", text: "hands up"},
{id: "hapa haole", text: "hapa haole"},
{id: "happy hardcore", text: "happy hardcore"},
{id: "haqibah", text: "haqibah"},
{id: "hard", text: "hard"},
{id: "hard bop", text: "hard bop"},
{id: "hard house", text: "hard house"},
{id: "hard rock", text: "hard rock"},
{id: "hard trance", text: "hard trance"},
{id: "hardcore hip hop", text: "hardcore hip hop"},
{id: "hardcore metal", text: "hardcore metal"},
{id: "hardcore punk", text: "hardcore punk"},
{id: "hardcore techno", text: "hardcore techno"},
{id: "hardstyle", text: "hardstyle"},
{id: "harepa", text: "harepa"},
{id: "harmonica blues", text: "harmonica blues"},
{id: "hasaposérviko", text: "hasaposérviko"},
{id: "heart attack", text: "heart attack"},
{id: "heartland rock", text: "heartland rock"},
{id: "heavy beat", text: "heavy beat"},
{id: "heavy metal", text: "heavy metal"},
{id: "hesher", text: "hesher"},
{id: "hi-nrg", text: "hi-nrg"},
{id: "highlands", text: "highlands"},
{id: "highlife", text: "highlife"},
{id: "highlife fusion", text: "highlife fusion"},
{id: "hillybilly music", text: "hillybilly music"},
{id: "hindustani classical music", text: "hindustani classical music"},
{id: "hip hop", text: "hip hop"},
{id: "hip hop & rap", text: "hip hop & rap"},
{id: "hip hop soul", text: "hip hop soul"},
{id: "hip house", text: "hip house"},
{id: "hiplife", text: "hiplife"},
{id: "hiragasy", text: "hiragasy"},
{id: "hiva usu", text: "hiva usu"},
{id: "hong kong and cantonese pop", text: "hong kong and cantonese pop"},
{id: "hong kong english pop", text: "hong kong english pop"},
{id: "honky tonk", text: "honky tonk"},
{id: "honkyoku", text: "honkyoku"},
{id: "hora lunga", text: "hora lunga"},
{id: "hornpipe", text: "hornpipe"},
{id: "horror punk", text: "horror punk"},
{id: "horrorcore", text: "horrorcore"},
{id: "horrorcore rap", text: "horrorcore rap"},
{id: "house", text: "house"},
{id: "house music", text: "house music"},
{id: "hua'er", text: "hua'er"},
{id: "huasteco", text: "huasteco"},
{id: "huayno", text: "huayno"},
{id: "hula", text: "hula"},
{id: "humppa", text: "humppa"},
{id: "hunguhungu", text: "hunguhungu"},
{id: "hyangak", text: "hyangak"},
{id: "hymn", text: "hymn"},
{id: "hyphy", text: "hyphy"},
{id: "hát chau van", text: "hát chau van"},
{id: "hát chèo", text: "hát chèo"},
{id: "hát cãi luong", text: "hát cãi luong"},
{id: "hát tuồng", text: "hát tuồng"},
{id: "ibiza music", text: "ibiza music"},
{id: "icaro", text: "icaro"},
{id: "idm", text: "idm"},
{id: "igbo music", text: "igbo music"},
{id: "ijexá", text: "ijexá"},
{id: "ilahije", text: "ilahije"},
{id: "illbient", text: "illbient"},
{id: "impressionist music", text: "impressionist music"},
{id: "improvisational", text: "improvisational"},
{id: "incidental music", text: "incidental music"},
{id: "indian pop", text: "indian pop"},
{id: "indie folk", text: "indie folk"},
{id: "indie music", text: "indie music"},
{id: "indie pop", text: "indie pop"},
{id: "indie rock", text: "indie rock"},
{id: "indietronica", text: "indietronica"},
{id: "indo jazz", text: "indo jazz"},
{id: "indo rock", text: "indo rock"},
{id: "indonesian pop", text: "indonesian pop"},
{id: "indoyíftika", text: "indoyíftika"},
{id: "industrial death metal", text: "industrial death metal"},
{id: "industrial hip-hop", text: "industrial hip-hop"},
{id: "industrial metal", text: "industrial metal"},
{id: "industrial music", text: "industrial music"},
{id: "industrial musical", text: "industrial musical"},
{id: "industrial rock", text: "industrial rock"},
{id: "instrumental rock", text: "instrumental rock"},
{id: "intelligent dance music", text: "intelligent dance music"},
{id: "international latin", text: "international latin"},
{id: "inuit music", text: "inuit music"},
{id: "iranian pop", text: "iranian pop"},
{id: "irish folk", text: "irish folk"},
{id: "irish rebel music", text: "irish rebel music"},
{id: "iscathamiya", text: "iscathamiya"},
{id: "isicathamiya", text: "isicathamiya"},
{id: "isikhwela jo", text: "isikhwela jo"},
{id: "island", text: "island"},
{id: "isolationist", text: "isolationist"},
{id: "italo dance", text: "italo dance"},
{id: "italo disco", text: "italo disco"},
{id: "italo house", text: "italo house"},
{id: "itsmeños", text: "itsmeños"},
{id: "izvorna bosanska muzika", text: "izvorna bosanska muzika"},
{id: "j'ouvert", text: "j'ouvert"},
{id: "j-fusion", text: "j-fusion"},
{id: "j-pop", text: "j-pop"},
{id: "j-rock", text: "j-rock"},
{id: "jaipongan", text: "jaipongan"},
{id: "jaliscienses", text: "jaliscienses"},
{id: "jam band", text: "jam band"},
{id: "jam rock", text: "jam rock"},
{id: "jamana kura", text: "jamana kura"},
{id: "jamrieng samai", text: "jamrieng samai"},
{id: "jangle pop", text: "jangle pop"},
{id: "japanese pop", text: "japanese pop"},
{id: "jarana", text: "jarana"},
{id: "jariang", text: "jariang"},
{id: "jarochos", text: "jarochos"},
{id: "jawaiian", text: "jawaiian"},
{id: "jazz", text: "jazz"},
{id: "jazz blues", text: "jazz blues"},
{id: "jazz fusion", text: "jazz fusion"},
{id: "jazz metal", text: "jazz metal"},
{id: "jazz rap", text: "jazz rap"},
{id: "jazz-funk", text: "jazz-funk"},
{id: "jazz-rock", text: "jazz-rock"},
{id: "jegog", text: "jegog"},
{id: "jenkka", text: "jenkka"},
{id: "jesus music", text: "jesus music"},
{id: "jibaro", text: "jibaro"},
{id: "jig", text: "jig"},
{id: "jig punk", text: "jig punk"},
{id: "jing ping", text: "jing ping"},
{id: "jingle", text: "jingle"},
{id: "jit", text: "jit"},
{id: "jitterbug", text: "jitterbug"},
{id: "jive", text: "jive"},
{id: "joged", text: "joged"},
{id: "joged bumbung", text: "joged bumbung"},
{id: "joik", text: "joik"},
{id: "jonnycore", text: "jonnycore"},
{id: "joropo", text: "joropo"},
{id: "jota", text: "jota"},
{id: "jtek", text: "jtek"},
{id: "jug band", text: "jug band"},
{id: "jujitsu", text: "jujitsu"},
{id: "juju", text: "juju"},
{id: "juke joint blues", text: "juke joint blues"},
{id: "jump blues", text: "jump blues"},
{id: "jumpstyle", text: "jumpstyle"},
{id: "jungle", text: "jungle"},
{id: "junkanoo", text: "junkanoo"},
{id: "juré", text: "juré"},
{id: "jùjú", text: "jùjú"},
{id: "k-pop", text: "k-pop"},
{id: "kaba", text: "kaba"},
{id: "kabuki", text: "kabuki"},
{id: "kachāshī", text: "kachāshī"},
{id: "kadans", text: "kadans"},
{id: "kagok", text: "kagok"},
{id: "kagyupa chanting", text: "kagyupa chanting"},
{id: "kaiso", text: "kaiso"},
{id: "kalamatianó", text: "kalamatianó"},
{id: "kalattuut", text: "kalattuut"},
{id: "kalinda", text: "kalinda"},
{id: "kamba pop", text: "kamba pop"},
{id: "kan ha diskan", text: "kan ha diskan"},
{id: "kansas city blues", text: "kansas city blues"},
{id: "kantrum", text: "kantrum"},
{id: "kantádhes", text: "kantádhes"},
{id: "kargyraa", text: "kargyraa"},
{id: "karma", text: "karma"},
{id: "kaseko", text: "kaseko"},
{id: "katajjaq", text: "katajjaq"},
{id: "kawachi ondo", text: "kawachi ondo"},
{id: "kayōkyoku", text: "kayōkyoku"},
{id: "ke-kwe", text: "ke-kwe"},
{id: "kebyar", text: "kebyar"},
{id: "kecak", text: "kecak"},
{id: "kecapi suling", text: "kecapi suling"},
{id: "kertok", text: "kertok"},
{id: "khaleeji", text: "khaleeji"},
{id: "khap", text: "khap"},
{id: "khelimaski djili", text: "khelimaski djili"},
{id: "khene", text: "khene"},
{id: "khoomei", text: "khoomei"},
{id: "khorovodi", text: "khorovodi"},
{id: "khplam wai", text: "khplam wai"},
{id: "khrung sai", text: "khrung sai"},
{id: "khyal", text: "khyal"},
{id: "kilapanda", text: "kilapanda"},
{id: "kinko", text: "kinko"},
{id: "kirtan", text: "kirtan"},
{id: "kiwi rock", text: "kiwi rock"},
{id: "kizomba", text: "kizomba"},
{id: "klape", text: "klape"},
{id: "klasik", text: "klasik"},
{id: "klezmer", text: "klezmer"},
{id: "kliningan", text: "kliningan"},
{id: "kléftiko", text: "kléftiko"},
{id: "kochare", text: "kochare"},
{id: "kolomyjka", text: "kolomyjka"},
{id: "komagaku", text: "komagaku"},
{id: "kompa", text: "kompa"},
{id: "konpa", text: "konpa"},
{id: "korean pop", text: "korean pop"},
{id: "koumpaneia", text: "koumpaneia"},
{id: "kpanlogo", text: "kpanlogo"},
{id: "krakowiak", text: "krakowiak"},
{id: "krautrock", text: "krautrock"},
{id: "kriti", text: "kriti"},
{id: "kroncong", text: "kroncong"},
{id: "krump", text: "krump"},
{id: "krzesany", text: "krzesany"},
{id: "kuduro", text: "kuduro"},
{id: "kulintang", text: "kulintang"},
{id: "kulning", text: "kulning"},
{id: "kumina", text: "kumina"},
{id: "kun-borrk", text: "kun-borrk"},
{id: "kundere", text: "kundere"},
{id: "kundiman", text: "kundiman"},
{id: "kussundé", text: "kussundé"},
{id: "kutumba wake", text: "kutumba wake"},
{id: "kveding", text: "kveding"},
{id: "kvæði", text: "kvæði"},
{id: "kwaito", text: "kwaito"},
{id: "kwassa kwassa", text: "kwassa kwassa"},
{id: "kwela", text: "kwela"},
{id: "käng", text: "käng"},
{id: "kélé", text: "kélé"},
{id: "kĩkũyũ pop", text: "kĩkũyũ pop"},
{id: "la la", text: "la la"},
{id: "latin american", text: "latin american"},
{id: "latin jazz", text: "latin jazz"},
{id: "latin pop", text: "latin pop"},
{id: "latin rap", text: "latin rap"},
{id: "lavway", text: "lavway"},
{id: "laïko", text: "laïko"},
{id: "laïkó", text: "laïkó"},
{id: "le leagan", text: "le leagan"},
{id: "legényes", text: "legényes"},
{id: "lelio", text: "lelio"},
{id: "letkajenkka", text: "letkajenkka"},
{id: "levenslied", text: "levenslied"},
{id: "lhamo", text: "lhamo"},
{id: "lieder", text: "lieder"},
{id: "light music", text: "light music"},
{id: "light rock", text: "light rock"},
{id: "likanos", text: "likanos"},
{id: "liquid drum&bass", text: "liquid drum&bass"},
{id: "liquid funk", text: "liquid funk"},
{id: "liquindi", text: "liquindi"},
{id: "llanera", text: "llanera"},
{id: "llanto", text: "llanto"},
{id: "lo-fi", text: "lo-fi"},
{id: "lo-fi music", text: "lo-fi music"},
{id: "loki djili", text: "loki djili"},
{id: "long-song", text: "long-song"},
{id: "louisiana blues", text: "louisiana blues"},
{id: "louisiana swamp pop", text: "louisiana swamp pop"},
{id: "lounge music", text: "lounge music"},
{id: "lovers rock", text: "lovers rock"},
{id: "lowercase", text: "lowercase"},
{id: "lubbock sound", text: "lubbock sound"},
{id: "lucknavi thumri", text: "lucknavi thumri"},
{id: "luhya omutibo", text: "luhya omutibo"},
{id: "luk grung", text: "luk grung"},
{id: "lullaby", text: "lullaby"},
{id: "lundu", text: "lundu"},
{id: "lundum", text: "lundum"},
{id: "m-base", text: "m-base"},
{id: "madchester", text: "madchester"},
{id: "madrigal", text: "madrigal"},
{id: "mafioso rap", text: "mafioso rap"},
{id: "maglaal", text: "maglaal"},
{id: "magnificat", text: "magnificat"},
{id: "mahori", text: "mahori"},
{id: "mainstream jazz", text: "mainstream jazz"},
{id: "makossa", text: "makossa"},
{id: "makossa-soukous", text: "makossa-soukous"},
{id: "malagueñas", text: "malagueñas"},
{id: "malawian jazz", text: "malawian jazz"},
{id: "malhun", text: "malhun"},
{id: "maloya", text: "maloya"},
{id: "maluf", text: "maluf"},
{id: "maluka", text: "maluka"},
{id: "mambo", text: "mambo"},
{id: "manaschi", text: "manaschi"},
{id: "mandarin pop", text: "mandarin pop"},
{id: "manding swing", text: "manding swing"},
{id: "mango", text: "mango"},
{id: "mangue bit", text: "mangue bit"},
{id: "mangulina", text: "mangulina"},
{id: "manikay", text: "manikay"},
{id: "manila sound", text: "manila sound"},
{id: "manouche", text: "manouche"},
{id: "manzuma", text: "manzuma"},
{id: "mapouka", text: "mapouka"},
{id: "mapouka-serré", text: "mapouka-serré"},
{id: "marabi", text: "marabi"},
{id: "maracatu", text: "maracatu"},
{id: "marga", text: "marga"},
{id: "mariachi", text: "mariachi"},
{id: "marimba", text: "marimba"},
{id: "marinera", text: "marinera"},
{id: "marrabenta", text: "marrabenta"},
{id: "martial industrial", text: "martial industrial"},
{id: "martinetes", text: "martinetes"},
{id: "maskanda", text: "maskanda"},
{id: "mass", text: "mass"},
{id: "matamuerte", text: "matamuerte"},
{id: "math rock", text: "math rock"},
{id: "mathcore", text: "mathcore"},
{id: "matt bello", text: "matt bello"},
{id: "maxixe", text: "maxixe"},
{id: "mazurka", text: "mazurka"},
{id: "mbalax", text: "mbalax"},
{id: "mbaqanga", text: "mbaqanga"},
{id: "mbube", text: "mbube"},
{id: "mbumba", text: "mbumba"},
{id: "medh", text: "medh"},
{id: "medieval folk rock", text: "medieval folk rock"},
{id: "medieval metal", text: "medieval metal"},
{id: "medieval music", text: "medieval music"},
{id: "meditation", text: "meditation"},
{id: "mejorana", text: "mejorana"},
{id: "melhoun", text: "melhoun"},
{id: "melhûn", text: "melhûn"},
{id: "melodic black metal", text: "melodic black metal"},
{id: "melodic death metal", text: "melodic death metal"},
{id: "melodic hardcore", text: "melodic hardcore"},
{id: "melodic metalcore", text: "melodic metalcore"},
{id: "melodic music", text: "melodic music"},
{id: "melodic trance", text: "melodic trance"},
{id: "memphis blues", text: "memphis blues"},
{id: "memphis rap", text: "memphis rap"},
{id: "memphis soul", text: "memphis soul"},
{id: "mento", text: "mento"},
{id: "merengue", text: "merengue"},
{id: "merengue típico moderno", text: "merengue típico moderno"},
{id: "merengue-bomba", text: "merengue-bomba"},
{id: "meringue", text: "meringue"},
{id: "merseybeat", text: "merseybeat"},
{id: "metal", text: "metal"},
{id: "metalcore", text: "metalcore"},
{id: "metallic hardcore", text: "metallic hardcore"},
{id: "mexican pop", text: "mexican pop"},
{id: "mexican rock", text: "mexican rock"},
{id: "mexican son", text: "mexican son"},
{id: "meykhana", text: "meykhana"},
{id: "mezwed", text: "mezwed"},
{id: "miami bass", text: "miami bass"},
{id: "microhouse", text: "microhouse"},
{id: "middle of the road", text: "middle of the road"},
{id: "midwest hip hop", text: "midwest hip hop"},
{id: "milonga", text: "milonga"},
{id: "min'yo", text: "min'yo"},
{id: "mineras", text: "mineras"},
{id: "mini compas", text: "mini compas"},
{id: "mini-jazz", text: "mini-jazz"},
{id: "minimal techno", text: "minimal techno"},
{id: "minimalist music", text: "minimalist music"},
{id: "minimalist trance", text: "minimalist trance"},
{id: "minneapolis sound", text: "minneapolis sound"},
{id: "minstrel show", text: "minstrel show"},
{id: "minuet", text: "minuet"},
{id: "mirolóyia", text: "mirolóyia"},
{id: "modal jazz", text: "modal jazz"},
{id: "modern classical music", text: "modern classical music"},
{id: "modern laika", text: "modern laika"},
{id: "modern rock", text: "modern rock"},
{id: "modinha", text: "modinha"},
{id: "mohabelo", text: "mohabelo"},
{id: "montuno", text: "montuno"},
{id: "monumental dance", text: "monumental dance"},
{id: "mor lam", text: "mor lam"},
{id: "mor lam sing", text: "mor lam sing"},
{id: "morna", text: "morna"},
{id: "motorpop", text: "motorpop"},
{id: "motown", text: "motown"},
{id: "mozambique", text: "mozambique"},
{id: "mpb", text: "mpb"},
{id: "mugam", text: "mugam"},
{id: "multicultural", text: "multicultural"},
{id: "murga", text: "murga"},
{id: "musette", text: "musette"},
{id: "museve", text: "museve"},
{id: "mushroom jazz", text: "mushroom jazz"},
{id: "music drama", text: "music drama"},
{id: "music hall", text: "music hall"},
{id: "musiqi-e assil", text: "musiqi-e assil"},
{id: "musique concrète", text: "musique concrète"},
{id: "mutuashi", text: "mutuashi"},
{id: "muwashshah", text: "muwashshah"},
{id: "muzak", text: "muzak"},
{id: "méringue", text: "méringue"},
{id: "música campesina", text: "música campesina"},
{id: "música criolla", text: "música criolla"},
{id: "música de la interior", text: "música de la interior"},
{id: "música llanera", text: "música llanera"},
{id: "música nordestina", text: "música nordestina"},
{id: "música popular brasileira", text: "música popular brasileira"},
{id: "música tropical", text: "música tropical"},
{id: "nagauta", text: "nagauta"},
{id: "nakasi", text: "nakasi"},
{id: "nangma", text: "nangma"},
{id: "nanguan", text: "nanguan"},
{id: "narcocorrido", text: "narcocorrido"},
{id: "nardcore", text: "nardcore"},
{id: "narodna muzika", text: "narodna muzika"},
{id: "nasheed", text: "nasheed"},
{id: "nashville sound", text: "nashville sound"},
{id: "nashville sound/countrypolitan", text: "nashville sound/countrypolitan"},
{id: "national socialist black metal", text: "national socialist black metal"},
{id: "naturalismo", text: "naturalismo"},
{id: "nederpop", text: "nederpop"},
{id: "neo soul", text: "neo soul"},
{id: "neo-classical metal", text: "neo-classical metal"},
{id: "neo-medieval", text: "neo-medieval"},
{id: "neo-prog", text: "neo-prog"},
{id: "neo-psychedelia", text: "neo-psychedelia"},
{id: "neoclassical", text: "neoclassical"},
{id: "neoclassical music", text: "neoclassical music"},
{id: "neofolk", text: "neofolk"},
{id: "neotraditional country", text: "neotraditional country"},
{id: "nerdcore", text: "nerdcore"},
{id: "neue deutsche härte", text: "neue deutsche härte"},
{id: "neue deutsche welle", text: "neue deutsche welle"},
{id: "new age music", text: "new age music"},
{id: "new beat", text: "new beat"},
{id: "new instrumental", text: "new instrumental"},
{id: "new jack swing", text: "new jack swing"},
{id: "new orleans blues", text: "new orleans blues"},
{id: "new orleans jazz", text: "new orleans jazz"},
{id: "new pop", text: "new pop"},
{id: "new prog", text: "new prog"},
{id: "new rave", text: "new rave"},
{id: "new romantic", text: "new romantic"},
{id: "new school hip hop", text: "new school hip hop"},
{id: "new taiwanese song", text: "new taiwanese song"},
{id: "new wave", text: "new wave"},
{id: "new wave of british heavy metal", text: "new wave of british heavy metal"},
{id: "new wave of new wave", text: "new wave of new wave"},
{id: "new weird america", text: "new weird america"},
{id: "new york blues", text: "new york blues"},
{id: "new york house", text: "new york house"},
{id: "newgrass", text: "newgrass"},
{id: "nganja", text: "nganja"},
{id: "niche", text: "niche"},
{id: "nightcore", text: "nightcore"},
{id: "nintendocore", text: "nintendocore"},
{id: "nisiótika", text: "nisiótika"},
{id: "no wave", text: "no wave"},
{id: "noh", text: "noh"},
{id: "noise music", text: "noise music"},
{id: "noise pop", text: "noise pop"},
{id: "noise rock", text: "noise rock"},
{id: "nongak", text: "nongak"},
{id: "norae undong", text: "norae undong"},
{id: "nordic folk dance music", text: "nordic folk dance music"},
{id: "nordic folk music", text: "nordic folk music"},
{id: "nortec", text: "nortec"},
{id: "norteño", text: "norteño"},
{id: "northern soul", text: "northern soul"},
{id: "nota", text: "nota"},
{id: "nu breaks", text: "nu breaks"},
{id: "nu jazz", text: "nu jazz"},
{id: "nu metal", text: "nu metal"},
{id: "nu soul", text: "nu soul"},
{id: "nueva canción", text: "nueva canción"},
{id: "nyatiti", text: "nyatiti"},
{id: "néo kýma", text: "néo kýma"},
{id: "obscuro", text: "obscuro"},
{id: "oi!", text: "oi!"},
{id: "old school hip hop", text: "old school hip hop"},
{id: "old-time", text: "old-time"},
{id: "oldies", text: "oldies"},
{id: "olonkho", text: "olonkho"},
{id: "oltului", text: "oltului"},
{id: "ondo", text: "ondo"},
{id: "opera", text: "opera"},
{id: "operatic pop", text: "operatic pop"},
{id: "oratorio", text: "oratorio"},
{id: "orchestra", text: "orchestra"},
{id: "organ trio", text: "organ trio"},
{id: "organic ambient", text: "organic ambient"},
{id: "organum", text: "organum"},
{id: "orgel", text: "orgel"},
{id: "oriental metal", text: "oriental metal"},
{id: "ottava rima", text: "ottava rima"},
{id: "outlaw country", text: "outlaw country"},
{id: "outsider music", text: "outsider music"},
{id: "p-funk", text: "p-funk"},
{id: "pagan metal", text: "pagan metal"},
{id: "pagan rock", text: "pagan rock"},
{id: "pagode", text: "pagode"},
{id: "paisley underground", text: "paisley underground"},
{id: "palm wine", text: "palm wine"},
{id: "palm-wine", text: "palm-wine"},
{id: "pambiche", text: "pambiche"},
{id: "panambih", text: "panambih"},
{id: "panchai baja", text: "panchai baja"},
{id: "panchavadyam", text: "panchavadyam"},
{id: "pansori", text: "pansori"},
{id: "paranda", text: "paranda"},
{id: "parang", text: "parang"},
{id: "parody", text: "parody"},
{id: "parranda", text: "parranda"},
{id: "partido alto", text: "partido alto"},
{id: "pasillo", text: "pasillo"},
{id: "patriotic", text: "patriotic"},
{id: "peace punk", text: "peace punk"},
{id: "pelimanni music", text: "pelimanni music"},
{id: "petenera", text: "petenera"},
{id: "peyote song", text: "peyote song"},
{id: "philadelphia soul", text: "philadelphia soul"},
{id: "piano blues", text: "piano blues"},
{id: "piano rock", text: "piano rock"},
{id: "piedmont blues", text: "piedmont blues"},
{id: "pimba", text: "pimba"},
{id: "pinoy pop", text: "pinoy pop"},
{id: "pinoy rock", text: "pinoy rock"},
{id: "pinpeat orchestra", text: "pinpeat orchestra"},
{id: "piphat", text: "piphat"},
{id: "piyyutim", text: "piyyutim"},
{id: "plainchant", text: "plainchant"},
{id: "plena", text: "plena"},
{id: "pleng phua cheewit", text: "pleng phua cheewit"},
{id: "pleng thai sakorn", text: "pleng thai sakorn"},
{id: "political hip hop", text: "political hip hop"},
{id: "polka", text: "polka"},
{id: "polo", text: "polo"},
{id: "polonaise", text: "polonaise"},
{id: "pols", text: "pols"},
{id: "polska", text: "polska"},
{id: "pong lang", text: "pong lang"},
{id: "pop", text: "pop"},
{id: "pop folk", text: "pop folk"},
{id: "pop music", text: "pop music"},
{id: "pop punk", text: "pop punk"},
{id: "pop rap", text: "pop rap"},
{id: "pop rock", text: "pop rock"},
{id: "pop sunda", text: "pop sunda"},
{id: "pornocore", text: "pornocore"},
{id: "porro", text: "porro"},
{id: "post disco", text: "post disco"},
{id: "post-britpop", text: "post-britpop"},
{id: "post-disco", text: "post-disco"},
{id: "post-grunge", text: "post-grunge"},
{id: "post-hardcore", text: "post-hardcore"},
{id: "post-industrial", text: "post-industrial"},
{id: "post-metal", text: "post-metal"},
{id: "post-minimalism", text: "post-minimalism"},
{id: "post-punk", text: "post-punk"},
{id: "post-rock", text: "post-rock"},
{id: "post-romanticism", text: "post-romanticism"},
{id: "pow-wow", text: "pow-wow"},
{id: "power electronics", text: "power electronics"},
{id: "power metal", text: "power metal"},
{id: "power noise", text: "power noise"},
{id: "power pop", text: "power pop"},
{id: "powerviolence", text: "powerviolence"},
{id: "ppongtchak", text: "ppongtchak"},
{id: "praise song", text: "praise song"},
{id: "program symphony", text: "program symphony"},
{id: "progressive bluegrass", text: "progressive bluegrass"},
{id: "progressive country", text: "progressive country"},
{id: "progressive death metal", text: "progressive death metal"},
{id: "progressive electronic", text: "progressive electronic"},
{id: "progressive electronic music", text: "progressive electronic music"},
{id: "progressive folk", text: "progressive folk"},
{id: "progressive folk music", text: "progressive folk music"},
{id: "progressive house", text: "progressive house"},
{id: "progressive metal", text: "progressive metal"},
{id: "progressive rock", text: "progressive rock"},
{id: "progressive trance", text: "progressive trance"},
{id: "protopunk", text: "protopunk"},
{id: "psych folk", text: "psych folk"},
{id: "psychedelic music", text: "psychedelic music"},
{id: "psychedelic pop", text: "psychedelic pop"},
{id: "psychedelic rock", text: "psychedelic rock"},
{id: "psychedelic trance", text: "psychedelic trance"},
{id: "psychobilly", text: "psychobilly"},
{id: "punk blues", text: "punk blues"},
{id: "punk cabaret", text: "punk cabaret"},
{id: "punk jazz", text: "punk jazz"},
{id: "punk rock", text: "punk rock"},
{id: "punta", text: "punta"},
{id: "punta rock", text: "punta rock"},
{id: "qasidah", text: "qasidah"},
{id: "qasidah modern", text: "qasidah modern"},
{id: "qawwali", text: "qawwali"},
{id: "quadrille", text: "quadrille"},
{id: "quan ho", text: "quan ho"},
{id: "queercore", text: "queercore"},
{id: "quiet storm", text: "quiet storm"},
{id: "rada", text: "rada"},
{id: "raga", text: "raga"},
{id: "raga rock", text: "raga rock"},
{id: "ragga", text: "ragga"},
{id: "ragga jungle", text: "ragga jungle"},
{id: "raggamuffin", text: "raggamuffin"},
{id: "ragtime", text: "ragtime"},
{id: "rai", text: "rai"},
{id: "rake-and-scrape", text: "rake-and-scrape"},
{id: "ramkbach", text: "ramkbach"},
{id: "ramvong", text: "ramvong"},
{id: "ranchera", text: "ranchera"},
{id: "rap", text: "rap"},
{id: "rap metal", text: "rap metal"},
{id: "rap rock", text: "rap rock"},
{id: "rapcore", text: "rapcore"},
{id: "rara", text: "rara"},
{id: "rare groove", text: "rare groove"},
{id: "rasiya", text: "rasiya"},
{id: "rave", text: "rave"},
{id: "raw rock", text: "raw rock"},
{id: "raï", text: "raï"},
{id: "rebetiko", text: "rebetiko"},
{id: "red dirt", text: "red dirt"},
{id: "reel", text: "reel"},
{id: "reggae", text: "reggae"},
{id: "reggae fusion", text: "reggae fusion"},
{id: "reggae highlife", text: "reggae highlife"},
{id: "reggaefusion", text: "reggaefusion"},
{id: "reggaeton", text: "reggaeton"},
{id: "rekilaulu", text: "rekilaulu"},
{id: "relax music", text: "relax music"},
{id: "religious", text: "religious"},
{id: "rembetiko", text: "rembetiko"},
{id: "renaissance music", text: "renaissance music"},
{id: "requiem", text: "requiem"},
{id: "rhapsody", text: "rhapsody"},
{id: "rhyming spiritual", text: "rhyming spiritual"},
{id: "rhythm & blues", text: "rhythm & blues"},
{id: "rhythm and blues", text: "rhythm and blues"},
{id: "ricercar", text: "ricercar"},
{id: "riot grrrl", text: "riot grrrl"},
{id: "rock", text: "rock"},
{id: "rock and roll", text: "rock and roll"},
{id: "rock en español", text: "rock en español"},
{id: "rock opera", text: "rock opera"},
{id: "rockabilly", text: "rockabilly"},
{id: "rocksteady", text: "rocksteady"},
{id: "rococo", text: "rococo"},
{id: "romantic period in music", text: "romantic period in music"},
{id: "rondeaux", text: "rondeaux"},
{id: "ronggeng", text: "ronggeng"},
{id: "roots reggae", text: "roots reggae"},
{id: "roots rock", text: "roots rock"},
{id: "roots rock reggae", text: "roots rock reggae"},
{id: "rumba", text: "rumba"},
{id: "russian pop", text: "russian pop"},
{id: "rímur", text: "rímur"},
{id: "sabar", text: "sabar"},
{id: "sacred harp", text: "sacred harp"},
{id: "sadcore", text: "sadcore"},
{id: "saibara", text: "saibara"},
{id: "sakara", text: "sakara"},
{id: "salegy", text: "salegy"},
{id: "salsa", text: "salsa"},
{id: "salsa erotica", text: "salsa erotica"},
{id: "salsa romantica", text: "salsa romantica"},
{id: "saltarello", text: "saltarello"},
{id: "samba", text: "samba"},
{id: "samba-canção", text: "samba-canção"},
{id: "samba-reggae", text: "samba-reggae"},
{id: "samba-rock", text: "samba-rock"},
{id: "sambai", text: "sambai"},
{id: "sanjo", text: "sanjo"},
{id: "sato kagura", text: "sato kagura"},
{id: "sawt", text: "sawt"},
{id: "saya", text: "saya"},
{id: "scat", text: "scat"},
{id: "schlager", text: "schlager"},
{id: "schottisch", text: "schottisch"},
{id: "schranz", text: "schranz"},
{id: "scottish baroque music", text: "scottish baroque music"},
{id: "screamo", text: "screamo"},
{id: "scrumpy and western", text: "scrumpy and western"},
{id: "sea shanty", text: "sea shanty"},
{id: "sean nós", text: "sean nós"},
{id: "second viennese school", text: "second viennese school"},
{id: "sega music", text: "sega music"},
{id: "seggae", text: "seggae"},
{id: "seis", text: "seis"},
{id: "semba", text: "semba"},
{id: "sephardic music", text: "sephardic music"},
{id: "serialism", text: "serialism"},
{id: "set dance", text: "set dance"},
{id: "sevdalinka", text: "sevdalinka"},
{id: "sevillana", text: "sevillana"},
{id: "shabab", text: "shabab"},
{id: "shabad", text: "shabad"},
{id: "shalako", text: "shalako"},
{id: "shan'ge", text: "shan'ge"},
{id: "shango", text: "shango"},
{id: "shape note", text: "shape note"},
{id: "shibuya-kei", text: "shibuya-kei"},
{id: "shidaiqu", text: "shidaiqu"},
{id: "shima uta", text: "shima uta"},
{id: "shock rock", text: "shock rock"},
{id: "shoegaze", text: "shoegaze"},
{id: "shoegazer", text: "shoegazer"},
{id: "shoka", text: "shoka"},
{id: "shomyo", text: "shomyo"},
{id: "show tune", text: "show tune"},
{id: "sica", text: "sica"},
{id: "siguiriyas", text: "siguiriyas"},
{id: "silat", text: "silat"},
{id: "sinawi", text: "sinawi"},
{id: "singer-songwriter", text: "singer-songwriter"},
{id: "situational", text: "situational"},
{id: "ska", text: "ska"},
{id: "ska punk", text: "ska punk"},
{id: "skacore", text: "skacore"},
{id: "skald", text: "skald"},
{id: "skate punk", text: "skate punk"},
{id: "skiffle", text: "skiffle"},
{id: "slack-key guitar", text: "slack-key guitar"},
{id: "slide", text: "slide"},
{id: "slowcore", text: "slowcore"},
{id: "sludge metal", text: "sludge metal"},
{id: "slängpolska", text: "slängpolska"},
{id: "smooth jazz", text: "smooth jazz"},
{id: "soca", text: "soca"},
{id: "soft rock", text: "soft rock"},
{id: "son", text: "son"},
{id: "son montuno", text: "son montuno"},
{id: "son-batá", text: "son-batá"},
{id: "sonata", text: "sonata"},
{id: "songo", text: "songo"},
{id: "songo-salsa", text: "songo-salsa"},
{id: "sophisti-pop", text: "sophisti-pop"},
{id: "soukous", text: "soukous"},
{id: "soul", text: "soul"},
{id: "soul blues", text: "soul blues"},
{id: "soul jazz", text: "soul jazz"},
{id: "soul music", text: "soul music"},
{id: "soundtrack", text: "soundtrack"},
{id: "southern gospel", text: "southern gospel"},
{id: "southern harmony", text: "southern harmony"},
{id: "southern hip hop", text: "southern hip hop"},
{id: "southern metal", text: "southern metal"},
{id: "southern rock", text: "southern rock"},
{id: "southern soul", text: "southern soul"},
{id: "space age pop", text: "space age pop"},
{id: "space music", text: "space music"},
{id: "space rock", text: "space rock"},
{id: "spectralism", text: "spectralism"},
{id: "speed garage", text: "speed garage"},
{id: "speed metal", text: "speed metal"},
{id: "speedcore", text: "speedcore"},
{id: "spirituals", text: "spirituals"},
{id: "spouge", text: "spouge"},
{id: "sprechgesang", text: "sprechgesang"},
{id: "square dance", text: "square dance"},
{id: "squee", text: "squee"},
{id: "st. louis blues", text: "st. louis blues"},
{id: "steelband", text: "steelband"},
{id: "stoner metal", text: "stoner metal"},
{id: "stoner rock", text: "stoner rock"},
{id: "straight edge", text: "straight edge"},
{id: "strathspeys", text: "strathspeys"},
{id: "stride", text: "stride"},
{id: "string", text: "string"},
{id: "string quartet", text: "string quartet"},
{id: "sufi music", text: "sufi music"},
{id: "suite", text: "suite"},
{id: "sunshine pop", text: "sunshine pop"},
{id: "suomirock", text: "suomirock"},
{id: "super eurobeat", text: "super eurobeat"},
{id: "surf ballad", text: "surf ballad"},
{id: "surf instrumental", text: "surf instrumental"},
{id: "surf music", text: "surf music"},
{id: "surf pop", text: "surf pop"},
{id: "surf rock", text: "surf rock"},
{id: "swamp blues", text: "swamp blues"},
{id: "swamp pop", text: "swamp pop"},
{id: "swamp rock", text: "swamp rock"},
{id: "swing", text: "swing"},
{id: "swing music", text: "swing music"},
{id: "swingbeat", text: "swingbeat"},
{id: "sygyt", text: "sygyt"},
{id: "symphonic black metal", text: "symphonic black metal"},
{id: "symphonic metal", text: "symphonic metal"},
{id: "symphonic poem", text: "symphonic poem"},
{id: "symphonic rock", text: "symphonic rock"},
{id: "symphony", text: "symphony"},
{id: "synthpop", text: "synthpop"},
{id: "synthpunk", text: "synthpunk"},
{id: "t'ong guitar", text: "t'ong guitar"},
{id: "taarab", text: "taarab"},
{id: "tai tu", text: "tai tu"},
{id: "taiwanese pop", text: "taiwanese pop"},
{id: "tala", text: "tala"},
{id: "talempong", text: "talempong"},
{id: "tambu", text: "tambu"},
{id: "tamburitza", text: "tamburitza"},
{id: "tamil christian keerthanai", text: "tamil christian keerthanai"},
{id: "tango", text: "tango"},
{id: "tanguk", text: "tanguk"},
{id: "tappa", text: "tappa"},
{id: "tarana", text: "tarana"},
{id: "tarantella", text: "tarantella"},
{id: "taranto", text: "taranto"},
{id: "tech", text: "tech"},
{id: "tech house", text: "tech house"},
{id: "tech trance", text: "tech trance"},
{id: "technical death metal", text: "technical death metal"},
{id: "technical metal", text: "technical metal"},
{id: "techno", text: "techno"},
{id: "technoid", text: "technoid"},
{id: "technopop", text: "technopop"},
{id: "techstep", text: "techstep"},
{id: "techtonik", text: "techtonik"},
{id: "teen pop", text: "teen pop"},
{id: "tejano", text: "tejano"},
{id: "tejano music", text: "tejano music"},
{id: "tekno", text: "tekno"},
{id: "tembang sunda", text: "tembang sunda"},
{id: "texas blues", text: "texas blues"},
{id: "thai pop", text: "thai pop"},
{id: "thillana", text: "thillana"},
{id: "thrash metal", text: "thrash metal"},
{id: "thrashcore", text: "thrashcore"},
{id: "thumri", text: "thumri"},
{id: "tibetan pop", text: "tibetan pop"},
{id: "tiento", text: "tiento"},
{id: "timbila", text: "timbila"},
{id: "tin pan alley", text: "tin pan alley"},
{id: "tinga", text: "tinga"},
{id: "tinku", text: "tinku"},
{id: "toeshey", text: "toeshey"},
{id: "togaku", text: "togaku"},
{id: "trad jazz", text: "trad jazz"},
{id: "traditional bluegrass", text: "traditional bluegrass"},
{id: "traditional pop music", text: "traditional pop music"},
{id: "trallalero", text: "trallalero"},
{id: "trance", text: "trance"},
{id: "tribal house", text: "tribal house"},
{id: "trikitixa", text: "trikitixa"},
{id: "trip hop", text: "trip hop"},
{id: "trip rock", text: "trip rock"},
{id: "trip-hop", text: "trip-hop"},
{id: "tropicalia", text: "tropicalia"},
{id: "tropicalismo", text: "tropicalismo"},
{id: "tropipop", text: "tropipop"},
{id: "truck-driving country", text: "truck-driving country"},
{id: "tumba", text: "tumba"},
{id: "turbo-folk", text: "turbo-folk"},
{id: "turkish music", text: "turkish music"},
{id: "turkish pop", text: "turkish pop"},
{id: "turntablism", text: "turntablism"},
{id: "tuvan throat-singing", text: "tuvan throat-singing"},
{id: "twee pop", text: "twee pop"},
{id: "twist", text: "twist"},
{id: "two tone", text: "two tone"},
{id: "táncház", text: "táncház"},
{id: "uk garage", text: "uk garage"},
{id: "uk pub rock", text: "uk pub rock"},
{id: "unblack metal", text: "unblack metal"},
{id: "underground music", text: "underground music"},
{id: "uplifting", text: "uplifting"},
{id: "uplifting trance", text: "uplifting trance"},
{id: "urban cowboy", text: "urban cowboy"},
{id: "urban folk", text: "urban folk"},
{id: "urban jazz", text: "urban jazz"},
{id: "vallenato", text: "vallenato"},
{id: "vaudeville", text: "vaudeville"},
{id: "venezuela", text: "venezuela"},
{id: "verbunkos", text: "verbunkos"},
{id: "verismo", text: "verismo"},
{id: "video game music", text: "video game music"},
{id: "viking metal", text: "viking metal"},
{id: "villanella", text: "villanella"},
{id: "virelai", text: "virelai"},
{id: "vispop", text: "vispop"},
{id: "visual kei", text: "visual kei"},
{id: "visual music", text: "visual music"},
{id: "vocal", text: "vocal"},
{id: "vocal house", text: "vocal house"},
{id: "vocal jazz", text: "vocal jazz"},
{id: "vocal music", text: "vocal music"},
{id: "volksmusik", text: "volksmusik"},
{id: "waila", text: "waila"},
{id: "waltz", text: "waltz"},
{id: "wangga", text: "wangga"},
{id: "warabe uta", text: "warabe uta"},
{id: "wassoulou", text: "wassoulou"},
{id: "weld", text: "weld"},
{id: "were music", text: "were music"},
{id: "west coast hip hop", text: "west coast hip hop"},
{id: "west coast jazz", text: "west coast jazz"},
{id: "western", text: "western"},
{id: "western blues", text: "western blues"},
{id: "western swing", text: "western swing"},
{id: "witch house", text: "witch house"},
{id: "wizard rock", text: "wizard rock"},
{id: "women's music", text: "women's music"},
{id: "wong shadow", text: "wong shadow"},
{id: "wonky pop", text: "wonky pop"},
{id: "wood", text: "wood"},
{id: "work song", text: "work song"},
{id: "world fusion", text: "world fusion"},
{id: "world fusion music", text: "world fusion music"},
{id: "world music", text: "world music"},
{id: "worldbeat", text: "worldbeat"},
{id: "xhosa music", text: "xhosa music"},
{id: "xoomii", text: "xoomii"},
{id: "yo-pop", text: "yo-pop"},
{id: "yodeling", text: "yodeling"},
{id: "yukar", text: "yukar"},
{id: "yé-yé", text: "yé-yé"},
{id: "zajal", text: "zajal"},
{id: "zapin", text: "zapin"},
{id: "zarzuela", text: "zarzuela"},
{id: "zeibekiko", text: "zeibekiko"},
{id: "zeuhl", text: "zeuhl"},
{id: "ziglibithy", text: "ziglibithy"},
{id: "zouglou", text: "zouglou"},
{id: "zouk", text: "zouk"},
{id: "zouk chouv", text: "zouk chouv"},
{id: "zouklove", text: "zouklove"},
{id: "zulu music", text: "zulu music"},
{id: "zydec", text: "zydec"}
 ];

export default genres;