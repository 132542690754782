import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax.js';

class Unsubscribe extends Component {
	
	constructor(props) {
		super(props);
		this.state = {verified: false};
	}
	
	componentWillMount() {
		Ajax.unsubscribe(this.props.match.params.list, this.props.match.params.profileId, this.props.match.params.subscriberId).then((result) => {
            
        });
	}
	
	render() {
		return (
		<div className="Unsubscribe">
			Successfully unsubscribed!
		</div>
		);
	}
}

export { Unsubscribe };