import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import './Login.css';
import '../common/Overlay.css';
import { cookies } from '../../cookies/Cookies.js';

var FontAwesome = require('react-fontawesome');

class Login extends Component {
	
	constructor(props) {
		super(props);
		this.close = this.props.close;
		this.register = () => { this.close(); this.props.register(); };
		this.forgot = () => { this.close(); this.props.forgot(); };
		this.login = this.login.bind(this);
		this.pressLogin = this.pressLogin.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);	
		this.state = {values: {}, error: false};		
	}
	
	handleUpdate(event) {		
		var values = this.state.values;
		values[event.target.id] = event.target.value;
		this.setState({values: values});
	}

	pressLogin(event) {
		if (event.key == "Enter") {
			this.login();
		}
	}
	
	login(e) {
		var obj = this; 
		Ajax.logout().then(() => {
			cookies.removeSessionCookie();
			Ajax.login(this.state.values.email, this.state.values.password).then(function (res) {
				if (res === undefined) {
					obj.setState({loginMessage: "Error logging in."})
				}
				else if (res.error) {
					obj.setState({loginMessage: res.error});
				} else {
					window.location.href = "/artists/dashboard";
				}
			});
		});
		e.preventDefault();
	}

	//<div className="LoginRegisterLink" onClick={this.register}>No account yet? Register here!</div>
	
	render() {
		return (
		<div className="Window Login">
			<div className="WindowTopBar"><div className="WindowClose" onClick={this.close}><FontAwesome name='window-close' /></div></div>
			<div className="LoginRightTitle">Sign in to your account</div>
			<div className="Form">
				<form method="POST" onSubmit={this.login} action="signin">
					<div className="FormField">
						<div className="FormFieldLabel">E-Mail</div>
						<input type="email" id="email" placeholder="Enter e-mail here" onChange={this.handleUpdate} autoComplete="username email" />
					</div>
					<div className="FormField">
						<div className="FormFieldLabel">Password</div>
						<input type="password" id="password" placeholder="Enter password here" onChange={this.handleUpdate} autoComplete="password" /><br/><br/>
					</div>
					<div className="Button" onClick={this.login}>Sign in</div>
					<input type="submit" value="submit" className="HiddenSubmit" />
				</form>
				<div className="Message">{this.state.loginMessage}</div>
				
				<div className="PasswordForgottenLink" onClick={this.forgot}>Forgotten your password?</div>
			</div>
		</div>
		);
	}
}

export { Login };