import React, { Component } from 'react';
import { Popup } from '../common/Popup.js';
import { Ajax } from '../../ajax/Ajax';
import './Redirect.css';

class Redirect extends Component {
	
	constructor(props) {
        super(props);
        this.state = { 
            list: this.props.match.params.list, 
            platform: this.props.match.params.platform, 
            releaseId:  this.props.match.params.releaseId, 
            subscriberId: this.props.match.params.subscriberId,
            showNDA: false
        };
        this.downloadZip = this.downloadZip.bind(this);
        this.setShowNDA = this.setShowNDA.bind(this);
        this.setCloseNDA = this.setCloseNDA.bind(this);
	}
	
	componentWillMount() {        
        var obj = this;  
		Ajax.getPublicReleaseById(this.state.releaseId).then((release) => {
            if (obj.state.platform === "minipage") {
                if (obj.state.list === "presskit") {
                    Ajax.statsPresskitClick(obj.state.releaseId, obj.state.subscriberId).then(() => {
                        window.location.href = "/mini/" + release.slug;
                    });
                } else {
                    Ajax.statsNewsletterClick(obj.state.releaseId, obj.state.subscriberId).then(() => {
                        window.location.href = "/mini/" + release.slug;
                    });
                }     
            } else if (obj.state.platform === "download") {
                if (obj.state.list === "presskit") {
                    // TODO: save some stats here also
                    obj.setShowNDA();                  
                }
            } else {
                var links = release.links;
                var clickedLink = false;
                for (var i = 0; i < links.length; i++) {
                    var link = links[i];
                    var platform = obj.state.platform;
                    platform = platform.replace(/-/g, " ");
                    if (link.platform === platform) {
                        clickedLink = link;
                        break;
                    }
                }
                if (clickedLink) {
                    if (obj.state.list === "presskit") {
                        Ajax.statsPresskitClick(obj.state.releaseId, obj.state.subscriberId).then(() => {
                            Ajax.statsPresskitPlatformClick(obj.state.releaseId, obj.state.subscriberId, clickedLink.platform).then(() => {
                                window.location.href = clickedLink.url;
                            });
                        });
                    } else {
                        Ajax.statsNewsletterClick(obj.state.releaseId, obj.state.subscriberId).then(() => {
                            Ajax.statsNewsletterPlatformClick(obj.state.releaseId, obj.state.subscriberId, clickedLink.platform).then(() => {
                                window.location.href = clickedLink.url;
                            });
                        });
                    }
                }
                
            }           
        });
    }

    setShowNDA() {
        this.setState({showNDA: true});
    }

    setCloseNDA() {
        this.setState({showNDA: false});
    }

    downloadZip() {
        window.location.href = Ajax.getReleaseAsZip(this.state.releaseId, this.state.subscriberId);
    }
	
	render() {
        var obj = this;
        return (
            <div className="Redirect">
                {obj.state.showNDA ? (
                <Popup>
                    <p className="NDATitle">Non Disclosure Agreement<br/>Accept to download advance copy.<br/></p>
                    <div className="NDA">
                    <p>
                    Please do not distribute or share the files included in the advance copy in any way. By clicking accept you acknowledge that you will only use the advance copy yourself and that the files will not be copied or transferred in any way beyond your own personal use. Any breach of this agreement will be fully investigated and passed on to the appropriate authorities where required.
                    </p>                    
                    </div>
                    <div className="ButtonContainer"><div className="Button" onClick={this.downloadZip}>Accept</div></div>
                </Popup>) : null}
            </div>
        )
	}
}

export { Redirect };