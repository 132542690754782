import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Login } from '../auth/Login.js';
import { Forgot } from '../auth/Forgot.js';
import { BetaRequest } from '../auth/BetaRequest';
import { Register } from '../auth/Register.js';
import { Carousel } from '../common/Carousel.js';
import { Ajax } from '../../ajax/Ajax';

import './Landing.css';
import '../common/Overlay.css';

class Landing extends Component {
	
	constructor(props) {
		super(props);
		this.state = {values: {}, showRegister: false, showLogin: false, showForgot: false, bottomText: "to overview", nextPage: "Overview"};

		this.showRegister = this.showRegister.bind(this);
		this.showLogin = this.showLogin.bind(this);
		this.showRequestBeta = this.showRequestBeta.bind(this);
		this.showForgot = this.showForgot.bind(this);
		
		this.href = this.href.bind(this);
		this.handleWheel = this.handleWheel.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.timer = null;
		this.scrolling = false;
		this.lastScrollTop = 0;
		this.scrollTo = this.scrollTo.bind(this);
		this.stop = this.stop.bind(this);
		this.goto = this.goto.bind(this);

		this.handleUpdate = this.handleUpdate.bind(this);		

		this.overview = [
			{ poster: "overview_lightbulb.png",media: {webm: "overview_lightbulb.webm", mp4: "overview_lightbulb.mp4"}, title: "Simplify your promotion", subtitle: "Create new releases with presskits, newsletters and promotional mini-pages!" },
			{ poster: "overview_megaphone.png", media: {webm: "overview_megaphone.webm", mp4: "overview_megaphone.mp4"}, title: "Notify your subscribers", subtitle: "Manage separate mailing lists for your presskits and your release anouncements. Allow reviewers to listen to your music early." },
			{ poster: "overview_laptop.png", media: {webm: "overview_laptop.webm", mp4: "overview_laptop.mp4"}, title: "Share your releases", subtitle: "Easy to share mini-pages with links to the platforms you are on and an audio player for previews." },
			{ poster: "overview_group.png", media: {webm: "overview_group.webm", mp4: "overview_group.mp4"}, title: "Promote eachother", subtitle: "Streamline your reposting and grow your audience." },
		];

		this.currentPage = "Top";

		this.prevPage = {
			Overview: "Top",
			Pricing: "Features",
			Features: "Overview",
			Top: "Top"
		};

		this.nextPage = {
			Top: "Overview",
			Overview: "Features",
			// Features: "Pricing",
			// Pricing: "Top"
			Features: "Top"
		};

		this.bottomText = {
			Top: "To Overview",
			Overview: "To Features",
			// Features: "To Pricing",
			// Pricing: "Back to Top"
			Features: "Back to top"
		};
	}	

	stop() {
		clearTimeout(this.timer);
		this.scrolling = false;
	}

	scrollTo(id, callback) {
		var settings = {
			duration: 1000,
			easing: {
				outQuint: function (x, t, b, c, d) {
					return c*((t=t/d-1)*t*t*t*t + 1) + b;
				}
			}
		};
		var obj = this;		
		var percentage;
		var startTime;
		var node = document.getElementById(id);
		var nodeTop = node.offsetTop;
		var nodeHeight = node.offsetHeight;
		var body = document.body;
		var html = document.documentElement;
		var height = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.clientHeight,
			html.scrollHeight,
			html.offsetHeight
		);
		var windowHeight = window.innerHeight
		var offset = window.pageYOffset;
		var delta = nodeTop - offset;
		if (delta === 0) {
			obj.scrolling = false;
			return false;
		}
		var bottomScrollableY = height - windowHeight;
		var targetY = (bottomScrollableY < delta) ?
			bottomScrollableY - (height - nodeTop - nodeHeight + offset):
			delta;

		startTime = Date.now();
		percentage = 0;

		if (obj.timer) {
			clearInterval(obj.timer);			
		}

		function step () {
			var yScroll;
			var elapsed = Date.now() - startTime;

			if (elapsed > settings.duration) {
				obj.stop();
			}

			percentage = elapsed / settings.duration;

			if (percentage > 1) {
				obj.stop();

				if (callback) {
					callback();
				}
			} else {
				yScroll = settings.easing.outQuint(0, elapsed, offset, targetY, settings.duration);
				window.scrollTo(0, yScroll);
				obj.timer = setTimeout(step, 10);     
			}
		}

		obj.timer = setTimeout(step, 10);		
	}
  
	componentWillMount() {
		window.addEventListener('wheel', this.handleWheel, { passive: false });
		window.addEventListener('scroll', this.handleScroll);
	}
  
	componentWillUnmount() {
		window.removeEventListener('wheel', this.handleWheel);
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleWheel(e) {
		e.preventDefault();
		e.stopPropagation();
		if (! this.scrolling ) {			
			var obj = this;				
			var direction = e.deltaY > 0 ? 1 : -1;
			var destination = direction > 0 ? obj.nextPage[obj.currentPage] : obj.prevPage[obj.currentPage];
			if ("Pricing" === obj.currentPage && destination === "Top") {
				return false;
			} else {
				obj.currentPage = destination;
				obj.scrolling = true;
				obj.scrollTo(destination);
				obj.setState({bottomText: obj.bottomText[destination], nextPage: obj.nextPage[destination]});
			}			
		}
	}

	handleScroll(e) {
		e.preventDefault();
		e.stopPropagation();
		if (! this.scrolling) {
			var newPosition = window.pageYOffset;
			var oldPosition = this.lastScrollTop;
			var obj = this;
			console.log(oldPosition, newPosition);
			var direction = oldPosition - newPosition < 0 ? 1 : -1;
			console.log(direction);
			console.log(e);
			var destination = direction > 0 ? obj.nextPage[obj.currentPage] : obj.prevPage[obj.currentPage];
			console.log(destination);
			if ("Pricing" === obj.currentPage && destination === "Top") {
				return false;
			} else {
				obj.currentPage = destination;
				obj.scrolling = true;
				obj.scrollTo(destination);
				obj.setState({bottomText: obj.bottomText[destination], nextPage: obj.nextPage[destination]});
			}
		}			
	}

	goto(destination) {
		var obj = this;		
		return () => {
			if (! obj.scrolling) {
				obj.currentPage = destination;
				obj.scrolling = true;
				obj.scrollTo(destination);
				obj.setState({bottomText: obj.bottomText[destination], nextPage: obj.nextPage[destination]});
			}
		};		
	}


	showRegister(value) {
		var obj = this;
		return function() {
			obj.setState({showRegister: value});
		}
	}
	
	showLogin(value) {
		var obj = this;
		return function() {
			obj.setState({showLogin: value});
		}
	}

	showRequestBeta(value) {
		var obj = this;
		return function() {
			obj.setState({showRequestBeta: value});
		}
	}

	showForgot(value) {
		var obj = this;
		return function() {
			obj.setState({showForgot: value});
		}
	}

	href(path) {
		return function() {
			window.location.href = path;
		};
	}

	handleUpdate(event) {		
		var values = this.state.values;
		values[event.target.id] = event.target.value;
		this.setState({values: values});
	}
	
	render() {
		var obj = this;
		var n = obj.state.nextPage;
		return (		
			<div className="Landing">
				<div className="LandingMenu">
					<div className="LandingLeftMenu">
						<div className={n === "Overview" ? "LandingLeftMenuItem Selected" : "LandingLeftMenuItem"} onClick={ this.goto('Top') } >Home</div>
						<div className={n === "Features" ? "LandingLeftMenuItem Selected" : "LandingLeftMenuItem"} onClick={ this.goto('Overview') } >Overview</div>
						<div className={n === "Pricing" ? "LandingLeftMenuItem Selected" : "LandingLeftMenuItem"} onClick={ this.goto('Features') } >Features</div>
						<div className="LandingLeftMenuItem" onClick={ this.href('/feed') } >Feed</div>
						
					</div>
					<div className="LandingRightMenu">
						<div className="LandingRightMenuItem" onClick={this.showLogin(true)}>Sign in</div>
						<div className="LandingRightMenuItem" onClick={this.showRequestBeta(true)}>Request Beta</div>
						
					</div>
				</div>
				<div className="LandingPageBottom">									
					<div className="LandingPageBottomLink" onClick={ this.goto(this.state.nextPage) }>						
						<div className="LandingPageBottomLinkText">
							{this.state.bottomText}
						</div>
						<div className="LandingPageBottomChevron">
							{this.state.nextPage === "Top" ? (<FontAwesome name="chevron-circle-up" />) : (<FontAwesome name="chevron-circle-down" />)}
						</div>							
					</div>
				</div>

				<div className="LandingPage" id="Top">
					<div className="LandingHeader">
						<video autoPlay loop muted>
							<source src="banner.webm" type="video/webm" />
							<source src="banner.mp4" type="video/mp4" />
						</video>
					</div>
					<div className="LandingHeaderText">
						<div className="LandingHeaderTextLeft">All the tools you need to promote your music in one place</div>
					</div>
				</div>
				
				<div className="LandingPage" id="Overview">
					<div className="LandingPageTitle">Overview</div>
					<div className="LandingOverviewCarousel">
						 <Carousel elements={this.overview} delay={40000} />
					</div>
					<div className="LandingOverviewImage" />
				</div>

				<div className="LandingPage" id="Features">
					<div className="LandingPageTitle">Features</div>
					<div className="Features">
						<div className="FeatureHeaders">
							<div className="FeatureName"></div>
							<div className="FeatureCategory">
								<div className="FeatureCategoryName">Free Account</div>
								<div className="FeatureCategoryPrice">Free</div>
							</div>
							<div className="FeatureCategory">
								<div className="FeatureCategoryName">Premium Account</div>
								<div className="FeatureCategoryPrice">20€ Monthly<br/>or<br/>200€ Yearly</div>
							</div>
						</div>
					
						<div className="Feature">
							<div className="FeatureName">
								<div className="FeatureTitle">Release Page</div>
								<div className="FeatureDescription">Music release promo page with unique url<br/>Easily share your release with just one link<br/> Share a complete page with links to stores and more</div>							
							</div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>							
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
						</div>
						<div className="Feature">
							<div className="FeatureName">
								<div className="FeatureTitle">Press Release</div>
								<div className="FeatureDescription">Send access to presskit prior to official release</div>							
							</div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
						</div>
						<div className="Feature">
							<div className="FeatureName">
								<div className="FeatureTitle">Newsletter creation and distribution</div>
								<div className="FeatureDescription">Automated but customizable newsletter for your release</div>
							</div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
						</div>
						<div className="Feature">
							<div className="FeatureName">
								<div className="FeatureTitle">Subscriber management</div>
								<div className="FeatureDescription">Keep public and press mailing lists separate</div>
							</div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
						</div>
						<div className="Feature">
							<div className="FeatureName">
								<div className="FeatureTitle">Catalog and Link Page</div>
								<div className="FeatureDescription">Share your full discography in one page<br/>Create a page of links to your playlists, stores, ...</div>
							</div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>							
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
						</div>
						<div className="Feature">
							<div className="FeatureName">
								<div className="FeatureTitle">Get new subscribers</div>
								<div className="FeatureDescription">Take full advantage of our tools to expand your fanbase</div>
							</div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
						</div>
						<div className="Feature">
							<div className="FeatureName">
								<div className="FeatureTitle">In depth statistics and reports</div>
								<div className="FeatureDescription">Mailing lists opens and clicks<br/>Release page visits and clicks<br/>Traffic generated to your shops</div>
							</div>
							<div className="FeatureCategory FeatureCategoryText">Limited</div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
						</div>
						<div className="Feature">
							<div className="FeatureName">
								<div className="FeatureTitle">Unlimited Active releases</div>
								<div className="FeatureDescription">Number of online/public releases you can have at the same time</div>
							</div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
							<div className="FeatureCategory"><FontAwesome name="check" /></div>
						</div>
					</div>
				</div>


				{ this.state.showRegister ? (<div className="Overlay"><Register close={this.showRegister(false)} /></div>) : null }
				{ this.state.showLogin ? (<div className="Overlay"><Login register={this.showRegister(true)} forgot={this.showForgot(true)} close={this.showLogin(false)}/></div>) : null }
				{ this.state.showRequestBeta ? (<div className="Overlay"><BetaRequest close={this.showRequestBeta(false)}/></div>) : null }
				{ this.state.showForgot ? (<div className="Overlay"><Forgot register={this.showRegister(true)} signin={this.showLogin(true)} close={this.showForgot(false)}/></div>) : null }
			</div>
		
		);
	}
}

export { Landing };
