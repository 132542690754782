import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class StackedBarChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            options: {
                plotOptions: {
                    bar: {
                        horizontal: props.horizontal,
                        columnWidth: '70%'
                    },
                },
                chart: {
                    toolbar: {
                        tools: {
                            download: false
                        }
                    },
                    type: 'bar',
                    stacked: true
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: props.labels       
                },
                yaxis: {
                    forceNiceScale: true,
                    min: 0,
                    max: props.max + 1,
                    show: props.horizontal
                },
                fill: {
                    opacity: 1
                },
                legend: {
                  position: 'top',
                  horizontalAlign: 'left',
                  offsetX: 40
                }
            },
            series: props.counts,
            width: props.width,
            height: props.height,
            releaseId: props.releaseId
        };
    }

    componentDidUpdate(prevProps) {
        var props = this.props;
        if (prevProps.releaseId === props.releaseId) {
            return;
        }
        this.setState({ 
          options: {
              plotOptions: {
                  bar: {
                      horizontal: props.horizontal,
                      columnWidth: '70%'
                  },
              },
              chart: {
                  toolbar: {
                      tools: {
                          download: false
                      }
                  },
                  type: 'bar',
                  stacked: true
              },
              dataLabels: {
                  enabled: true
              },
              stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
              },
              xaxis: {
                  categories: props.labels       
              },
              yaxis: {
                  forceNiceScale: true,
                  min: 0,
                  max: props.max + 1,
                  show: props.horizontal
              },
              fill: {
                  opacity: 1
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }
          },
          series: props.counts,
          width: props.width,
          height: props.height,
          releaseId: props.releaseId
      });
    }

    render() {
        return (
            <Chart options={this.state.options} series={this.state.series} type="bar" width={this.state.width} height={this.state.height} />
        );
    }
}

export { StackedBarChart };