import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import './FeedRelease.css';
import { ArtistFormatter } from '../common/ArtistFormatter';
import { Ajax } from '../../ajax/Ajax.js';

class FeedRelease extends Component {
	
	constructor(props) {
		super(props);
		this.state = {release: this.props.release};
    }
	
	render() {
        var obj = this;
        var release = obj.state.release;
        var cover = false;
        if (release.cover.large) {
            cover = Ajax.getFile(release.cover.large);
            if (release.cover.small) {
                cover = Ajax.getFile(release.cover.small);
            }
        }		
		return (		
			<div className="FeedRelease">
                <a target="_blank" href={"/mini/" + release.slug}>
                    <div className="FeedReleaseCover">
                        {cover ? (<img src={cover} alt="release cover" />) : (<FontAwesome name="image" />) }
                    </div>
                </a>
                <div className="FeedReleaseInfo">
                    <div className="FeedReleaseTitle">{release.title}</div>
                    <div className="FeedReleaseArtist">by { new ArtistFormatter(release.artists).format() }                        
                    </div>
                </div>                                    
			</div>
		
		);
	}
}

export { FeedRelease };