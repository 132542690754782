import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';

import './Forgot.css';
import '../common/Overlay.css';

var FontAwesome = require('react-fontawesome');

class Forgot extends Component {
	
	constructor(props) {
		super(props);
        this.close = this.props.close;
        this.register = () => { this.close(); this.props.register(); };
        this.signin = () => {this.close(); this.props.signin(); };
		this.forgot = this.forgot.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);	
		this.state = {values: {}, error: false, forgotMessage: false};		
	}
	
	handleUpdate(event) {		
		var values = this.state.values;
		values[event.target.id] = event.target.value;
		this.setState({values: values});
	}
	
	forgot() {
		var obj = this; 
		Ajax.forgotPassword(this.state.values.email).then(function (res) {
			console.log(res);
			if (res.error) {
				obj.setState({forgotMessage: res.error});
			} else {
				obj.setState({forgotMessage: "Password reset e-mail sent!"});
			}
		});
	}
	// <div className="ForgotRegisterLink" onClick={this.register}>No account yet? Register here!</div>

	render() {
		return (
		<div className="Window Forgot">
			<div className="WindowTopBar"><div className="WindowClose" onClick={this.close}><FontAwesome name='window-close' /></div></div>
			<div className="ForgotRightTitle">Reset your password</div>
			{! this.state.forgotMessage ? (
			<div className="Form">
				<div className="FormField">
					<div className="FormFieldLabel">E-Mail</div>
					<input type="text" id="email" placeholder="Enter e-mail here" onChange={this.handleUpdate} />
				</div>
				<div className="Button" onClick={this.forgot}>Reset password</div>
				<div className="SignInLink" onClick={this.signin}>Sign In</div>
				<div className="RegisterLink" onClick={this.register}>Register</div>				
			</div>) : (
				<div className="Message">{this.state.forgotMessage}</div>
			) }
			
		</div>
		);
	}
}

export { Forgot };