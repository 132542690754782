import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';

import './Login.css';
import '../common/Overlay.css';

var FontAwesome = require('react-fontawesome');

class BetaRequest extends Component {
	
	constructor(props) {
		super(props);
		this.close = this.props.close;
		this.beta = this.beta.bind(this);		
		this.handleUpdate = this.handleUpdate.bind(this);	
		this.state = {
			values: {name: false, link: false, email: false},
			errors: {name: false, link: false, email: false},
			error: false,
			sending: false,
			sent: false
		};		
	}
	
	handleUpdate(event) {
		var values = this.state.values;
		var name = event.target.id;
		var value = event.target.value;
		values[name] = value;
		this.setState({values: values});
		this.validate(name, value);
	}

	detailsOkay() {
		var errors = this.state.errors;
		var content = this.state.values.email;
		return content && (! errors.email);
	}
	
	validate(name, value) {
		if (name === "email") {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (re.test(value)) {				
				var errors = this.state.errors;
				errors.email = false;				
				this.setState({errors: errors});
			}
			else {
				errors = this.state.errors;
				errors.email = "Invalid e-mail address";
				this.setState({errors: errors});
			}
		}
	}
	
	
	beta() {
		var obj = this;
		obj.setState({sending: true}, () => {
			Ajax.beta(obj.state.values.email, obj.state.values.name, obj.state.values.link).then((result) => {
				if (result.error) {
					obj.setState({error: "Failed to send, try again later."});
				} else {
					obj.setState({sending: false, sent: true});
				}
			});
		});		
	}

	//<div className="LoginRegisterLink" onClick={this.register}>No account yet? Register here!</div>
	
	render() {
		return (
		<div className="Window Login">
			<div className="WindowTopBar"><div className="WindowClose" onClick={this.close}><FontAwesome name='window-close' /></div></div>
			<div className="LoginRightTitle">Request Closed Beta Access</div>
			<div className="Form">
				<div className="FormField">
					<div className="FormFieldLabel">Name</div>
					<input type="text" id="name" placeholder="Enter name here" onChange={this.handleUpdate} />
					{ this.state.errors.name ? (<div className="Error">{this.state.errors.name}</div>) : null }
				</div>
				<div className="FormField">
					<div className="FormFieldLabel">Website/Soundcloud/...</div>
					<input type="text" id="link" placeholder="Enter link here" onChange={this.handleUpdate} />
					{ this.state.errors.link ? (<div className="Error">{this.state.errors.link}</div>) : null }
				</div>
				<div className="FormField">
					<div className="FormFieldLabel">E-Mail *</div>
					<input type="text" id="email" placeholder="Enter e-mail here" onChange={this.handleUpdate} />
					{ this.state.errors.email ? (<div className="Error">{this.state.errors.email}</div>) : null }
				</div>
				<div className="RequestButton">
					{ this.detailsOkay() && (! this.state.sending) && (! this.state.sent) ? (<div className="Button" onClick={this.beta}>Send Request</div>) : (<div className="Button Inactive">Send Request</div>) }
					{ this.state.sending ? (<div className="ButtonInfo">Sending...</div>) : null }
					{ this.state.sent ? (<div className="ButtonInfo">Sent <FontAwesome name="check"/></div>) : null }

				</div>
				{ this.state.error ? (<div className="Error">{this.state.error}</div>) : null }
			</div>
		</div>
		);
	}
}

export { BetaRequest };