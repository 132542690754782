import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import ReactTooltip from 'react-tooltip';

import './Dashboard.css';
import '../common/Overlay.css';
import { Ajax } from '../../ajax/Ajax';

class DashboardMenu extends Component {
	
	constructor(props) {
		super(props);
		this.href = this.href.bind(this);	
		this.openBugReport = this.openBugReport.bind(this);
		this.closeBugReport = this.closeBugReport.bind(this);
		this.setBugDescription = this.setBugDescription.bind(this);
		this.sendBugReport = this.sendBugReport.bind(this);
		this.state = {userId : "", showBugReportOverlay: false, sendingBugReport: false, bugDescription: ""};
	}

	componentWillMount() {
		var obj = this;
		Ajax.getUserId().then((response) => {
			obj.setState({userId: response.userId});
		});
	}
	
	href(path) {
		return function() {
			window.location.href = path;
		};
	}
	
	openBugReport() {
		this.setState({showBugReportOverlay: true});
	}

	closeBugReport() {
		this.setState({showBugReportOverlay: false});
	}

	setBugDescription(event) {
		var bugDescription = event.target.value;
		this.setState({bugDescription: bugDescription});
	}

	sendBugReport() {
		var obj = this;
		obj.setState({sendingBugReport: true}, () => {
			var context = {
				url: window.location.href,
				userId: obj.state.userId,
				browser: window.navigator
			};
			Ajax.bugReport(obj.state.bugDescription, context).then(() => {
				obj.setState({sendingBugReport: false, showBugReportOverlay: false, bugDescription: ""});
			});
		});
	}

	render() {
		var obj = this;
		return (	
		<div className="DashboardMenuSide">
			<div className="DashboardMenuTab">
				<div className="DashboardMenuTabTitle" onClick={this.href("/artists/dashboard")}><FontAwesome name="chart-pie" />Dashboard</div>
			</div>
			<div className="DashboardMenuTab">
				<div className="DashboardMenuTabTitle" onClick={this.href("/artists/releases")}><FontAwesome name="music" />Releases</div>
				<div className="DashboardMenuItem" key="tt08">
					<div className="DashboardMenuItemText" data-for="tt-08" data-tip="Create a new release and create a promotional mailing and minipage." onClick={this.href("/artists/newrelease")}>
						<FontAwesome name="plus-square" />New Release
					</div>
					<ReactTooltip id="tt-08" className="Tooltip" delayShow={500} />
				</div>
				<div className="DashboardMenuItem" key="tt09">
					<div className="DashboardMenuItemText" data-for="tt-09" data-tip="View previous releases including mailing stats and traffic stats to minipage." onClick={this.href("/artists/releases")}>
						<FontAwesome name="eye" />View All
					</div>
					<ReactTooltip id="tt-09" className="Tooltip" delayShow={500} />
				</div>
				<div className="DashboardMenuItem" key="tt12">
					<div className="DashboardMenuItemText" data-for="tt-12" data-tip="View the stats for your releases." onClick={this.href("/artists/stats")}>
						<FontAwesome name="chart-bar" />Stats
					</div>
					<ReactTooltip id="tt-12" className="Tooltip" delayShow={500} />
				</div>				
			</div>
			<div className="DashboardMenuTab">
				<div className="DashboardMenuTabTitle" onClick={this.href("/artists/catalog")}><FontAwesome name="paperclip" />Public Pages</div>
				<div className="DashboardMenuItem" key="tt10">
					<div className="DashboardMenuItemText" data-for="tt-10" data-tip="Edit and share your catalog." onClick={this.href("/artists/catalog")}>
						<FontAwesome name="newspaper" />Catalog
					</div>
					<ReactTooltip id="tt-10" className="Tooltip" delayShow={500} />
				</div>
				<div className="DashboardMenuItem" key="tt14">
					<div className="DashboardMenuItemText" data-for="tt-14" data-tip="Edit and share a simple landing page that provides easy links to you on different platforms." onClick={this.href("/artists/linkpage")}>
						<FontAwesome name="share-alt" />Link page
					</div>
					<ReactTooltip id="tt-14" className="Tooltip" delayShow={500} />
				</div>
			</div>
			<div className="DashboardMenuTab">
				<div className="DashboardMenuTabTitle" onClick={this.href("/artists/subscribers")}><FontAwesome name="paper-plane" />Mailing List</div>
				<div className="DashboardMenuItem" key="tt03">
					<div className="DashboardMenuItemText" data-for="tt-03" data-tip="View your subscribers, export or import with csv, clean up your subscriber list based on their stats." onClick={this.href("/artists/subscribers")}>
						<FontAwesome name="list" />Subscribers
					</div>
					<ReactTooltip id="tt-03" className="Tooltip" delayShow={500} />
				</div>
				<div className="DashboardMenuItem" key="tt04">
					<div className="DashboardMenuItemText" data-for="tt-04" data-tip="Generate subscription widgets for your mailing list." onClick={this.href("/artists/widgets")}>
						<FontAwesome name="columns" />Widgets
					</div>
					<ReactTooltip id="tt-04" className="Tooltip" delayShow={500} />
				</div>
			</div>	
			<div className="DashboardMenuTab">
				<div className="DashboardMenuTabTitle" onClick={this.href("/artists/profile")}><FontAwesome name="user-circle" />Account</div>
				<div className="DashboardMenuItem" key="tt01">
					<div className="DashboardMenuItemText" data-for="tt-01" data-tip="Edit the details that appear in the heading of your mailings such as logo, label name, ... and change your password" onClick={this.href("/artists/profile")}>
						<FontAwesome name="edit" />Edit Details
					</div>
					<ReactTooltip id="tt-01" className="Tooltip" delayShow={500} />
				</div>

			</div>						
			<div className="DashboardMenuTab">
				<div className="DashboardMenuTabTitle" onClick={this.href("/artists/contact")}><FontAwesome name="life-ring" />Support</div>				
				<div className="DashboardMenuItem" key="tt11">
					<div className="DashboardMenuItemText" data-for="tt-11" data-tip="Contact us for any help you need." onClick={this.href("/artists/contact")}>
						<FontAwesome name="envelope" />Contact
					</div>
					<ReactTooltip id="tt-11" className="Tooltip" delayShow={500} />
				</div>
				<div className="DashboardMenuItem" key="tt13">
					<a href="https://discord.gg/zpJ3REN3yw" target="_blank">
						<div className="DashboardMenuItemText" data-for="tt-13" data-tip="Join us on discord." >
							<FontAwesome name="comments" />Join Discord
						</div>
					</a>
					<ReactTooltip id="tt-13" className="Tooltip" delayShow={500} />
				</div>
			</div>

			<div className="DashboardMenuTab DashboardMenuBugReport">
				<div className="DashboardMenuTabTitle" onClick={this.openBugReport}><FontAwesome name="bug" />Report a Bug</div>
			</div>

			{this.state.showBugReportOverlay ? (
				<div className="Overlay">
					<div className="Window BugReport">
						<div className="WindowTopBar"><div className="WindowClose" onClick={obj.closeBugReport}><FontAwesome name='window-close' /></div></div>
						<div className="WindowTitle"><FontAwesome name="bug" /> Report a Bug <FontAwesome name="bug" /></div>
						<div className="BugForm">
							<div className="FormField">
								<div className="FormFieldLabel">Tell us what happened</div>
								<textarea className="BugDescription" onChange={this.setBugDescription} placeholder="Bug Description" value={this.state.bugDescription}></textarea>
							</div>
							<div className={obj.state.sendingBugReport ? "Button Inactive" : "Button"} onClick={obj.sendBugReport}>Send</div>							
							{ obj.state.sendingBugReport ? (<div className="Message">Sending...</div>) : null }
						</div>
					</div>
				</div>
			): null }
		</div>
		);
	}
}

/*
				<div className="DashboardMenuItem" key="tt02">
					<div className="DashboardMenuItemText" data-for="tt-02" data-tip="Edit or choose a billing plan to allow creating more release mini pages and mailings." onClick={this.href("/artists/billing")}>
						<FontAwesome name="dollar-sign" />Billing Plan
					</div>
					<ReactTooltip id="tt-02" className="Tooltip" delayShow={500} />
				</div>
*/

export { DashboardMenu };