import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';

import { Ajax } from '../../ajax/Ajax.js';
import '../common/Overlay.css';
import { Popup } from '../common/Popup.js';
import './Releases.css';

class ReleaseList extends Component {
	
	constructor(props) {
		super(props);
		this.state = {releases: this.props.releases, showStats: this.props.showStats, showDeletePopup: false, deleteIdx: false, deleteRelease: false};
		this.formatDate = this.formatDate.bind(this);
		this.deleteRelease = this.deleteRelease.bind(this);
		this.confirmDelete = this.confirmDelete.bind(this);
		this.cancelDelete = this.cancelDelete.bind(this);
		this.activate = this.props.activate;
		this.deactivate = this.props.deactivate;
	}
	
	formatDate(date) {
		var monthNames = [
			"January", "February", "March",
			"April", "May", "June", "July",	
			"August", "September", "October",
			"November", "December"
		];

		var day = date.getDate();
		var monthIndex = date.getMonth();
		var year = date.getFullYear();
		return day + ' ' + monthNames[monthIndex] + ' ' + year;
	}

	deleteRelease(release, idx) {
		return () => {
			this.setState({showDeletePopup: true, deleteIdx: idx, deleteRelease: release});
		};		
	}

	confirmDelete() {
		var obj = this;
		var releases = obj.state.releases;
		releases.splice(obj.state.deleteIdx, 1);
		obj.setState({releases: releases, showDeletePopup: false, deleteIdx: false, deleteRelease: false});
		Ajax.removeRelease(obj.state.deleteRelease);
	}

	cancelDelete() {
		this.setState({showDeletePopup: false, deleteIdx: false, deleteRelease: false});
	}
	
	render() {
		var releases = this.state.releases;
		var obj = this;
		return (
		<div className="ReleaseList">
			{
			releases.map(function (release, idx) {
				var cover = false;
				if (release.cover.large) {
					cover = Ajax.getFile(release.cover.large);
					if (release.cover.small) {
						cover = Ajax.getFile(release.cover.small);
					}
				}				
				return (
				<div className={obj.state.showStats ? "Release WithStats" : "Release"} key={idx}>
					<div className="ReleaseLeft">
						<div className="ReleaseCover" onClick={() => {window.location.href = "/artists/release/" + release._id;}}>
							{cover ? (<img src={cover} alt="release cover" />) : (<FontAwesome name="image" />) }
						</div>
						<div className="ReleaseInfo">
							<div className="ReleaseField">
								<div className="ReleaseFieldTitle">Title</div>
								<div className="ReleaseFieldValue">{release.title}</div>
							</div>
							<div className="ReleaseField">
								<div className="ReleaseFieldTitle">Artist</div>
								<div className="ReleaseFieldValue">{typeof(release.artists) === "string" ? release.artists : 
									release.artists.map(function (artist, idx) {
										if ((idx + 1) < release.artists.length)
											return artist.text + ", ";
										else
											return artist.text;
										})
									}
								</div>
							</div>
							<div className="ReleaseField">
								<div className="ReleaseFieldTitle">Tracks</div>
								<div className="ReleaseFieldValue">{release.tracks.length}</div>
							</div>
							<div className="ReleaseField">
								<div className="ReleaseFieldTitle">Presskit</div>
								<div className="ReleaseFieldValue">{obj.formatDate(new Date(release.prereleaseDate))}</div>
							</div>
							<div className="ReleaseField">
								<div className="ReleaseFieldTitle">Newsletter</div>
								<div className="ReleaseFieldValue">{obj.formatDate(new Date(release.releaseDate))}</div>
							</div>
						</div>
						<div className="ReleaseBottom">
							<div className="ReleaseActions">
								<div className="ReleaseAction Delete" onClick={obj.deleteRelease(release, idx)}><FontAwesome name="minus-circle"/> Delete </div>
								<div className="ReleaseAction" onClick={() => {window.location.href = "/artists/release/" + release._id;}}><FontAwesome name="edit"/> Edit</div>
								{ release.status === 2 ? (
								<div className="ReleaseAction" onClick={obj.deactivate(release, idx)}><FontAwesome name="unlink"/> Draft</div>
								) : (
								<div className="ReleaseAction" onClick={obj.activate(release, idx)}><FontAwesome name="share"/> Publish</div>
								)}
							</div>								
							
						</div>
					</div>					
				</div>
				);
			})
			}
			{obj.state.showDeletePopup ? (
                <Popup>
                    <p>Are you sure you want to delete this release?<br/><br/></p>
                    <div className="ButtonContainer"><div className="Button" onClick={obj.confirmDelete}>Delete</div></div>
                    <div className="ButtonContainer"><div className="Button" onClick={obj.cancelDelete}>Cancel</div></div>
                </Popup>
                ) : null }
		</div>		
		
		);
	}
	
}


export { ReleaseList };