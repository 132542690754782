import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import { PayPalButton } from "react-paypal-button-v2";

import './Register.css';
import '../common/Overlay.css';

var FontAwesome = require('react-fontawesome');

class Register extends Component {
	
	constructor(props) {
		super(props);
		this.close = this.props.close;
		this.register = this.register.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.validate = this.validate.bind(this);
		this.detailsOkay = this.detailsOkay.bind(this);
		this.registerAfterCheckout = this.registerAfterCheckout.bind(this);
		this.state = {
			values: {plan: false, name: false, email: false, password: false, confirmPassword: false},
			errors: {name: false, email: false, password: false, confirmPassword: false},
			error: false,
			showVerifyEmail: false,
			showCheckout: false
		};
	}
	
	detailsOkay() {
		var errors = this.state.errors;
		var content = this.state.values.plan && this.state.values.name && this.state.values.email && this.state.values.password && this.state.values.confirmPassword;
		return content && (! (errors.name || errors.email || errors.password || errors.confirmPassword));
	}
	
	validate(name, value) {
		if (name === "email") {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (re.test(value)) {				
				var errors = this.state.errors;
				errors.email = false;				
				this.setState({errors: errors});
			}
			else {
				errors = this.state.errors;
				errors.email = "Invalid e-mail address";
				this.setState({errors: errors});
			}
		} else if (name === "password") {
			var lowerCaseLetters = /[a-z]/g;
			var upperCaseLetters = /[A-Z]/g;
			var numbers = /[0-9]/g;			
			if (value.match(lowerCaseLetters) && value.match(upperCaseLetters) && value.match(numbers) && value.length >= 8) {
				errors = this.state.errors;
				errors.password = false;				
				this.setState({errors: errors});
			} else {
				errors = this.state.errors;
				errors.password = "Password invalid";				
				this.setState({errors: errors});
			}
		} else if (name === "confirmPassword") {
			if (value === this.state.values.password) {
				errors = this.state.errors;
				errors.confirmPassword = false;				
				this.setState({errors: errors});
			} else {
				errors = this.state.errors;
				errors.confirmPassword = "Needs to match password";				
				this.setState({errors: errors});
			}
		}
	}
	
	handleUpdate(event) {
		var values = this.state.values;
		var name = event.target.id;
		var value = event.target.value;
		values[name] = value;
		this.setState({values: values});
		this.validate(name, value);
	}
	
	handleUpdateValues(name, value, price) {
		var obj = this;
		return function(event) {
			var values = obj.state.values;
			values[name] = value;
			values.price = price;
			obj.setState({values: values});
			obj.validate(name, value);
		}
	}

	setPlan(value) {
		var obj = this;
		return function() {
			var values = obj.state.values;
			values.plan = value;
			obj.setState({values: values});
		}
	}
	
	register() {
		var obj = this;
		var values = this.state.values;
		console.log(values.email);
		Ajax.verifyEmail(values.email).then(function (verification) {
			if (verification.error !== undefined) {
				obj.setState({error: verification.error});
			} else {
				if (obj.state.values.plan === "free") {
					Ajax.register({info: values}).then(function (res) {
						if (res.error) {
							obj.setState({error: res.error});
						} else {
							obj.setState({showVerifyEmail: true});
						}
					});
				} else {
					obj.setState({showCheckout: true});
				}
			}
		});
	}

	registerAfterCheckout(data) {
		var obj = this;
		return (details) => {
			var values = this.state.values;
			values.subscriptionId = data.subscriptionID;
			values.orderId = data.orderID;
			Ajax.register({info: values}).then(function (res) {
				if (res.error) {
					obj.setState({error: res.error});
				} else {
					obj.setState({showVerifyEmail: true});
				}
			});
		}		
	}
	
	render() {
		var obj = this;
		var plan = obj.state.values.plan;
		return (
			<div className="Window Register">
				<div className="WindowTopBar"><div className="WindowClose" onClick={this.close}><FontAwesome name='window-close' /></div></div>		
				{! this.state.showVerifyEmail && ! this.state.showCheckout ? (
					
				<div className="RegisterForm">
					<div className="RegisterFormTitle">Register</div>
					<div className="Form">
						<div className="FormField">
							<div className="FormFieldLabel">Artist name or Label name</div>
							<input type="text" id="name" placeholder="Artist name or label name" value={this.state.values.name ? this.state.values.name : ""} onChange={this.handleUpdate} />
							{ this.state.errors.name ? (<div className="Error">{this.state.errors.name}</div>) : null }
						</div>
						<div className="FormField">
							<div className="FormFieldLabel">E-Mail</div>
							<input type="email" id="email" placeholder="E-mail" value={this.state.values.email ? this.state.values.email : ""} onChange={this.handleUpdate} />
							{ this.state.errors.email ? (<div className="Error">{this.state.errors.email}</div>) : null }
						</div>
						<div className="FormField">
							<div className="FormFieldLabel">Password</div>
							<input type="password" id="password" placeholder="Password" value={this.state.values.password ? this.state.values.password : ""} onChange={this.handleUpdate} /><br/>
							<p>Password must contain at least one uppercase letter, one lowercase letter, one number, and must be at least 8 characters long</p>
							{ this.state.errors.password ? (<div className="Error">{this.state.errors.password}</div>) : null }
						</div>
						<div className="FormField">
							<div className="FormFieldLabel">Confirm Password</div>
							<input type="password" id="confirmPassword" placeholder="Confirm password" value={this.state.values.confirmPassword ? this.state.values.confirmPassword : ""} onChange={this.handleUpdate} />
							{ this.state.errors.confirmPassword ? (<div className="Error">{this.state.errors.confirmPassword}</div>) : null }
						</div>
						<div className="FormField">
							<div className="FormFieldLabelBig">Choose Account Type</div>
							<div className={plan !== undefined && plan === "free" ? "FormFieldOption FormFieldOptionSelected" : "FormFieldOption"} onClick={this.setPlan("free")}>Standard<br/>Free</div>
							<div className={plan !== undefined && plan === "premium" ? "FormFieldOptionPremium FormFieldOptionSelected" : "FormFieldOptionPremium"} onClick={this.setPlan("premium")}><FontAwesome name="sun"/> Premium<br/>€69.99/month</div>
						</div>								
					</div>
					<div className="RegisterButton">					
						{ this.detailsOkay() ? (<div className="Button" onClick={this.register}>Register</div>) : (<div className="Button Inactive">Register</div>) }
						{ this.state.error ? (<div className="Error">{this.state.error}</div>) : null }
					</div>
				</div>	
				) : null }
				{ this.state.showVerifyEmail ? (
					<div className="RegisterVerify">
						<div className="RegisterVerifyLogo" />
						<div className="RegisterVerifyText">
							Thank you for registering an Early Grooves account! A verification e-mail has been sent to you. Please verify your e-mail to sign in and start using your account.
						</div>
					</div>
				): null }
				{ this.state.showCheckout ? (
					<div className="RegisterVerify">
						<div className="RegisterVerifyPrice">
							Premium Account: &euro;69.99/Month<br/>
						</div>
						<PayPalButton
							options={{vault: true, intent: "subscription", currency: "EUR", clientId: "AauUdj68A0fw_ilVbnABFx2tqbob_soSJGur0txb8evqyhSrGEzhMOSgCLfwHxRHJtVhy5ps2cIus9pd"}}
							createSubscription={(data, actions) => {
							return actions.subscription.create({
								plan_id: 'P-6J1654201P789340NMBAFZRQ'
							});
							}}
							onApprove={(data, actions) => {
							// Capture the funds from the transaction
							return actions.subscription.get().then(obj.registerAfterCheckout(data));
							}}
						/>
						<div className="RegisterButton">
							<div className="Button" onClick={() => {obj.setState({showCheckout: false, showVerifyEmail: false});}}>Back</div>
						</div>
					</div>
				): null }						
			</div>
		
		);
	}
}

export { Register };
