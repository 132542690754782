import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import FontAwesome from 'react-fontawesome';

import './LinkPage.css';

import { isUndefined } from 'util';
import { ArtistFormatter } from '../common/ArtistFormatter';

const ACTIVE = 2;

class LinkPage extends Component {
	
	constructor(props) {
        super(props);
        if (! isUndefined(this.props.profile)) {
            this.state = {slug: this.props.profile.slug, releases: [], loading: true, profile: this.props.profile};
        } else {
            this.state = {slug: this.props.match.params.slug, releases: [], loading: true, profile: false};
        }
        this.open = this.open.bind(this);
    }
    
    componentWillMount() {        
        var obj = this;
        var slug = this.state.slug;
        Ajax.getProfileSlugs().then((slugs) => {
            var userId = slugs[slug];
            Ajax.getPublicReleases(userId).then((releases) => {
                var active = [];            
                for (var i = 0; i < releases.length; i++) {
                    var release = releases[i];
                    switch (release.status) {                    
                        case ACTIVE:
                            active.push(release);
                            break;
                        default:
                            break;
                    }
                }
                obj.setState({releases: active, loading: false});
            });
            Ajax.getPublicProfile(userId).then((profile) => {
                obj.setState({profile: profile});
            });
        })
        
    }

    componentDidUpdate() {
        const newProps = this.props;
        if (! isUndefined(newProps.change) && newProps.change !== this.state.change) {
            var profile = newProps.profile;        
            this.setState({profile: profile, change: newProps.change});
        }
    }    

    open(link) {
        return () => { window.open(link); };
    }
	
	render() {
        var obj = this;
        var profile = this.state.profile;
        var website, facebook, twitter, soundcloud, instagram, youtube, spotify, bandcamp;
        var backgroundStyle;
        var links;

        if (profile) {            
            // check socials and set to false if they have weird values
            var socials = profile.socials;
            if ((socials !== undefined) && (socials !== null) && socials) {
                socials.facebook === undefined ? facebook = false : facebook = socials.facebook;
                socials.twitter === undefined ? twitter = false : twitter = socials.twitter;
                socials.soundcloud === undefined ? soundcloud = false : soundcloud = socials.soundcloud;
                socials.instagram === undefined ? instagram = false : instagram = socials.instagram;
                socials.youtube === undefined ? youtube = false : youtube = socials.youtube;
                socials.spotify === undefined ? spotify = false : spotify = socials.spotify;
                socials.bandcamp === undefined ? bandcamp = false : bandcamp = socials.bandcamp;
            } else {
                socials = false;
            }
            
            // check website
            if (profile.website === undefined) {
                website = false;
            } else {
                website = profile.website;
            }            
            
            // check logo
            var logo = false;
            if (profile.logo) {
                logo = Ajax.getFile(profile.logo);
            }

            // set up background style        
            var linkpage = profile.linkpage;
            links = [];
            if (linkpage !== undefined && linkpage.links !== undefined) {
                links = linkpage.links;
            }
            backgroundStyle = {backgroundColor: "rgba(255,255,255,1)"};
            var background = false;
            if (linkpage !== undefined && linkpage !== null && linkpage) {
                if  (linkpage.backgroundCover && linkpage.backgroundCover !== undefined) {
                    background = Ajax.getFile(linkpage.backgroundSrc);
                    if (linkpage.backgroundBlur !== undefined && linkpage.backgroundBlur) {
                        backgroundStyle = {backgroundImage: "url(" + background + ")", filter: "blur(15px)"};
                    } else {
                        backgroundStyle = {backgroundImage: "url(" + background + ")"};
                    }
                } else {
                    if ((linkpage.backgroundColor !== undefined) && linkpage.backgroundColor) {
                        backgroundStyle = {backgroundColor: "rgba(" + linkpage.backgroundColor.r + ", " + linkpage.backgroundColor.g + ", " + linkpage.backgroundColor.b + ", " + linkpage.backgroundColor.a + ")"};       
                    }
                }
            }
        }  


		return (
            <div className="LinkPageWrapper">
                <div className="LinkPageBackground" style={backgroundStyle}/>
                <div className="LinkPage">
                    <div className="LinkPageHeader"></div>
                    { profile ? (
                    <div className="LinkPageUserInfo">
                        <div className="LinkPageUserLogo">{logo ? (<img src={logo} alt="user logo" />) : null }</div>
                        <div className="LinkPageUserName">{profile.name && profile.name !== undefined ? profile.name : ""}</div>            
                        {linkpage.includeProfileLinks ? (
                        <div className="LinkPageUserSocials">
                            { website ? (<div className="LinkPageUserSocial" onClick={obj.open(website)}><FontAwesome name="globe"/> Official Website</div>) : null }
                            { facebook ? (<div className="LinkPageUserSocial" onClick={obj.open(facebook)}><span className="fab fa-facebook" /> Facebook</div>) : null } 
                            { twitter ? (<div className="LinkPageUserSocial" onClick={obj.open(twitter)}><span className="fab fa-twitter" /> Twitter</div>) : null } 
                            { soundcloud ? (<div className="LinkPageUserSocial" onClick={obj.open(soundcloud)}><span className="fab fa-soundcloud" /> Soundcloud</div>) : null } 
                            { instagram ? (<div className="LinkPageUserSocial" onClick={obj.open(instagram)}><span className="fab fa-instagram" /> Instagram</div>) : null } 
                            { youtube ? (<div className="LinkPageUserSocial" onClick={obj.open(youtube)}><span className="fab fa-youtube" /> Youtube</div>) : null } 
                            { spotify ? (<div className="LinkPageUserSocial" onClick={obj.open(spotify)}><span className="fab fa-spotify" /> Spotify</div>) : null } 
                            { bandcamp ? (<div className="LinkPageUserSocial" onClick={obj.open(bandcamp)}><span className="fab fa-bandcamp" /> Bandcamp</div>) : null } 
                        </div>) : null }
                        <div className="LinkPageCustomLinks">
                            {links.map((link, idx) => {
                                return (
                                    <div className="LinkPageCustomLink" key={idx} onClick={obj.open(link.url)}><FontAwesome name="link" /> {link.name}</div>
                                )
                            })}
                        </div>                                 
                    </div>) : null }                  
                </div>
            </div>
				
		
		);
	}
}

export { LinkPage };