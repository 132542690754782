import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import { PayPalButton } from "react-paypal-button-v2";

import './Register.css';
import '../common/Overlay.css';

var FontAwesome = require('react-fontawesome');

class Cancel extends Component {
	
	constructor(props) {
		super(props);
		this.close = this.props.close;
		this.cancel = this.cancel.bind(this);
		this.state = {
            showSuccess: false,
            subscriptionId: this.props.subscriptionId
		};
	}
	

	cancel() {
		var obj = this;
		return () => {
			Ajax.cancelSubscription(obj.state.subscriptionId).then(function (profile) {
                console.log(profile);
				if (profile.error) {
					obj.setState({error: profile.error});
				} else {
					obj.setState({showSuccess: true});
				}
			});
		}		
	}
	
	render() {
		var obj = this;		
		return (
			<div className="Window Cancel">
				<div className="WindowTopBar"><div className="WindowClose" onClick={this.close}><FontAwesome name='window-close' /></div></div>		
					<div className="RegisterVerify">
						<div className="RegisterVerifyPrice">
							Cancel Premium Plan
						</div>
                        {obj.state.showSuccess ? (
                            <div>
                                Your subscription was successfully cancelled. Your account will remain Premium until the next scheduled billing time.
                            </div>
                        ) : (
                            <div>
                                <p>You are about to cancel your subscription. You will still benefit from the full Premium features until the next scheduled billing time.</p>
                                <div className="Button" onClick={this.cancel()}>Cancel Plan</div>
                            </div>
                        )}  
						
					</div>		
			</div>
		
		);
	}
}

export { Cancel };
