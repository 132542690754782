import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { cookies } from '../../cookies/Cookies.js'
import { Ajax } from '../../ajax/Ajax.js';
import { ChangePassword } from '../auth/ChangePassword.js';

import './Dashboard.css';

class DashboardMenuTop extends Component {
	
	constructor(props) {
		super(props);
		this.href = this.href.bind(this);
		this.showAccountMenu = this.showAccountMenu.bind(this);
		this.showNotifications = this.showNotifications.bind(this);
		this.logout = this.logout.bind(this);
		this.setSeen = this.setSeen.bind(this);
		this.showChangePassword = this.showChangePassword.bind(this);
		this.state = { showChangePassword: false, showAccountMenu: false, showNotifications: false, notifications: [], seen: true };
	}

	componentWillMount() {			
		var obj = this;	
		Ajax.getProfile().then((profile) => {
			// check plan validity
			if (profile.plan === "premium") {
				var validUntil;
				if (profile.validUntil !== undefined) {
					validUntil = new Date(profile.validUntil);
				}
				if (profile.validUntil === undefined || validUntil < new Date()) {					
					Ajax.getSubscription(profile.subscriptionId).then((subscription) => {
						var status = subscription.subscription.status;
						if (status === "ACTIVE") {
							profile.validUntil = subscription.subscription.billing_info.next_billing_time;
							Ajax.updateProfile(profile);
						} else {
							profile.plan = "free";
							Ajax.updateProfile(profile);
						}
					});
				}
			}
			cookies.setName(profile.name);
			this.setState({name: profile.name});
			Ajax.getNotifications().then((notifications) => {
				var seen = true;
				for (var i = 0; i < notifications.length; i++) {
					var notification = notifications[i];
					if (!notification.seen) {						
						seen = false;
						break;
					}
				}
				obj.setState({notifications: notifications, seen: seen});
			});
		});
	}

	setSeen(notification) {
		var obj = this;
		return () => {
			if (notification.seen) {
				return;
			}
			notification.seen = true;
			Ajax.updateNotification(notification).then(() => {
				Ajax.getNotifications().then((notifications) => {
					var seen = true;
					for (var i = 0; i < notifications.length; i++) {
						var notification = notifications[i];
						if (!notification.seen) {						
							seen = false;
							break;
						}
					}
					obj.setState({notifications: notifications, seen: seen});
				});
			});
		};
	}
	
	href(path) {
		return function() {
			window.location.href = path;
		};
	}
	
	showAccountMenu(val) {
		var obj = this;
		return function () {
			obj.setState({ showAccountMenu: val });
		}
	}

	

	showNotifications(val) {
		var obj = this;
		return function () {
			obj.setState({ showNotifications: val });
		}
	}
	
	showChangePassword(value) {
		var obj = this;
		return function() {
			obj.setState({showChangePassword: value});
		}
	}
	
	logout() {
		Ajax.logout().then(() => {
			window.location.href = "/";
		});
	}
	
	render() {
		var obj = this;
		var notifications = this.state.notifications;
		var seen = this.state.seen;
		return (
		<div className="DashboardMenuTop">			
			<div className="DashboardMenuTopItems">
				<div className="DashboardMenuTopItem DashboardMenuTopItemHome" onClick={this.href("/artists/dashboard")}><FontAwesome name="home" /></div>
				<div className="DashboardMenuTopItem DashboardMenuTopItemNotifications" onMouseEnter={this.showNotifications(true)} onMouseLeave={this.showNotifications(false)}>
					<FontAwesome name="bell" />
					{seen ? null : (<div className="NewNotificationsMarker"/>)}
					{ this.state.showNotifications ? (
							<div className="Notifications">								
								{notifications.map((notification, idx) => {
									return (
										<div onMouseEnter={obj.setSeen(notification)} key={notification._id} className={notification.seen ? "Notification" : "Notification NewNotification"} onClick={obj.href(notification.url)}>
											{notification.message}
										</div>
									);
								})}
							</div>
						) : null }
				</div>
				<div className="DashboardMenuTopItem" onMouseEnter={this.showAccountMenu(true)} onMouseLeave={this.showAccountMenu(false)}>
					<FontAwesome name="user-circle" /> <div className="DashboardMenuTopProfileName">{this.state.name}</div>
					{ this.state.showAccountMenu ? (
					<div className="AccountMenu">
						<div className="AccountMenuItem" onClick={this.href("/artists/profile")}><FontAwesome name="edit" /> Edit Details</div>
						<div className="AccountMenuItem" onClick={this.showChangePassword(true)}><FontAwesome name="key" /> Change Password</div>
						
						<div className="AccountMenuItem" onClick={this.logout}><FontAwesome name="sign-out-alt" />Sign Out</div>
					</div>
					) : null }
					
				</div>
			</div>
			{ this.state.showChangePassword ? (<div className="Overlay"><ChangePassword close={this.showChangePassword(false)} /></div>) : null }		
		</div>
		);
	}
}
/* <div className="AccountMenuItem" onClick={this.href("/artists/billing")}><FontAwesome name="dollar-sign" /> Billing Plan</div> */
export { DashboardMenuTop };

