import { isUndefined } from 'util';

class StatFormatter {
	
	constructor(release, minipage, newsletter, presskit) {
        this.release = release;
        this.minipage = minipage;
        this.newsletter = newsletter;
        this.presskit = presskit;
	}

	formatReferrer(referrer) {
		var referrers = ["facebook", "instagram", "newsletter", "google", "youtube", "earlygrooves.com/artist"];
		var referrersCapitalized = ["Facebook", "Instagram", "EG Newsletter", "Google", "YouTube", "unknown"];
		var formattedReferrer = false;
		var lower = referrer.toLowerCase();
		for (var i = 0; i < referrers.length; i++) {
			if (lower === "") {
				formattedReferrer = "unknown";
				break;
			} else if (lower.includes(referrers[i])) {				
				formattedReferrer = referrersCapitalized[i];
				break;
			}
		}
		if (! formattedReferrer) {
			formattedReferrer = lower;
		}
		return formattedReferrer;
	}

    format() {
        var release = this.release;
        var minipage = this.minipage;
        var newsletter = this.newsletter;
        var presskit = this.presskit;

        if (isUndefined(release) || ! release) {
			return false;
        }

        var today = new Date();
        /*if (! release.newsletterSent) {
            console.log("yep");
            return false;
        }*/
        
		var releaseDate = new Date(release.releaseDate);		
		releaseDate.setHours(0, 0, 0, 0);
		var groupedVisits = {};
		var today = new Date();
		var currentTime = releaseDate;
		while (currentTime <= today) {
			groupedVisits[currentTime] = 0;
			currentTime.setTime(currentTime.getTime() + 1000 * 60 * 60 * 24);
		}
		var stats = {};
		var visits = [];
		var minipagePlatformClicks = {};
		var newsletterPlatformClicks = {};
		var listens = [];
		var emails = {opened: 0, clicked: 0};
		var platformLabels = [];
		var minipagePlatformCounts = [];
		var newsletterPlatformCounts = [];
		var minipageReferrers = {};
		var geolocation = {};

		// go over minipage stuff
		for (var i = 0; i < minipage.length; i++) {
			var m = minipage[i];
			var mTime = new Date(m.time);
			mTime.setHours(0, 0, 0, 0);
			if (m.action === "opened") {				
				var referrer = m.referrer;
				var formattedReferrer = this.formatReferrer(referrer);
				if (minipageReferrers[formattedReferrer] === undefined) {
					minipageReferrers[formattedReferrer] = 1;
				} else {
					minipageReferrers[formattedReferrer] = minipageReferrers[formattedReferrer] + 1;
				}
				
				if (! (m.geo === undefined) && !(m.geo === null)) {
					console.log(m.geo);
					var country = m.geo.country;
					if (geolocation[country] === undefined) {
						geolocation[country] = 1;
					} else {
						geolocation[country] = geolocation[country] + 1;
					}
				}

				if (isUndefined(groupedVisits[mTime])) {
					groupedVisits[mTime] = 1;
				} else {
					groupedVisits[mTime] = groupedVisits[mTime] + 1;
				}

			}
			if (m.action === "clicked") {
				var p = m.platform;
				if (isUndefined(minipagePlatformClicks[p])) {
					minipagePlatformClicks[p] = 1;
				}else {
					minipagePlatformClicks[p] = minipagePlatformClicks[p] + 1;
				}
			}
		}

		// go over newsletter stuff
		for (var i = 0; i < newsletter.length; i++) {
			var n = newsletter[i];
			// emails.sent = emails.sent + 1;
			if (n.opened) {
				emails.opened = emails.opened + 1;
			}
			if (n.clicked) {
				emails.clicked = emails.clicked + 1;
			}
			if (n.platformClicked) {
				var platforms = Object.keys(n.platformClicked);
				for (var j = 0; j < platforms.length; j++) {
					var p = platforms[j];
					if (isUndefined(newsletterPlatformClicks[p])) {
						newsletterPlatformClicks[p] = 1;
					}else {
						newsletterPlatformClicks[p] = newsletterPlatformClicks[p] + 1;
					}
				}
			}
		}

		// create platform labels
		var minipagePlatformLabels = Object.keys(minipagePlatformClicks);
		//var newsletterPlatformLabels = Object.keys(newsletterPlatformClicks);
		var platformLabels = [...minipagePlatformLabels];
		/*for (var i = 0; i < newsletterPlatformLabels; i++) {
			var label = newsletterPlatformLabels[i];
			if (platformLabels.includes(label)) {
				continue;
			} else {
				platformLabels.push(label);
			}
		}*/
		platformLabels.sort((a, b) => {
			return minipagePlatformClicks[b] - minipagePlatformClicks[a];
		});

		var platformMax = 0;
		// platform clicks count
		for (var i = 0; i < platformLabels.length; i++) {
			var miniVal = minipagePlatformClicks[platformLabels[i]];
			if (isUndefined(miniVal)) {
				miniVal = 0;
				minipagePlatformCounts.push(0);
			} else {
				minipagePlatformCounts.push(miniVal);
			}
			
			var newsVal = newsletterPlatformClicks[platformLabels[i]];
			if (isUndefined(newsVal)) {
				newsVal = 0;
				newsletterPlatformCounts.push(0);
			} else {
				newsletterPlatformCounts.push(newsVal);
			}
			if (miniVal + newsVal > platformMax) {
				platformMax = miniVal + newsVal;
			}
		}

		var platformCounts = [{name: "Minipage", data: minipagePlatformCounts}];

		var times = Object.keys(groupedVisits);
		for (var i = 0; i < times.length; i++) {
			var t = times[i];
			visits.push([new Date(t).getTime(), groupedVisits[t]]);
		}
		visits = visits.sort((a, b) => {return a[0] - b[0];});

		var emailLabels = Object.keys(emails);
		var emailCounts = [];
		var emailMax = 0;
		for (var i = 0; i < emailLabels.length; i++) {
			var val = emails[emailLabels[i]];
			if (val > emailMax) {
				emailMax = val;
			}
			emailCounts.push(val);
		}
		var capitalizedLabels = [];
		for (var i = 0; i < emailLabels.length; i++) {
			var label = emailLabels[i];
			capitalizedLabels.push(label.charAt(0).toUpperCase() + label.slice(1));
		} 


		const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
		var visitLabels = [];
		var visitCounts = [];
		var visitMax = 0;
		for (var i = 0; i < visits.length; i++) {
			var label = visits[i][0];
			var visitCount = visits[i][1];
			if (visitCount > visitMax)   {
				visitMax = visitCount;
			}				
			var labelDate = new Date(label);
			var formattedLabel = months[labelDate.getMonth()] + " " + labelDate.getDate();
			visitLabels.push(formattedLabel);
			visitCounts.push(visits[i][1]);
		}

		var referrerLabels = Object.keys(minipageReferrers);
		referrerLabels.sort((a, b) => {
			return minipageReferrers[b] - minipageReferrers[a];
		});
		var referrerCounts = [];
		var referrerMax = 0;
		for (var i = 0; i < referrerLabels.length; i++) {
			var val = minipageReferrers[referrerLabels[i]];
			if (val > referrerMax) {
				referrerMax = val;
			}
			referrerCounts.push(val);
		}

		var geo = [];
		var geoKeys = Object.keys(geolocation);
		console.log(geolocation);
		for (var i = 0; i < geoKeys.length; i++) {
			var geoKey = geoKeys[i];
			geo.push({country: geoKey.toLowerCase(), value: geolocation[geoKey]});
		  }

		stats.visits = visits;
		stats.visits2 = {labels: visitLabels, counts: visitCounts, max: visitMax};
		stats.platform = {labels: platformLabels, counts: platformCounts, max: platformMax};
		stats.email = {labels: capitalizedLabels, counts: emailCounts, max: emailMax};
		stats.referrers = {labels: referrerLabels, counts: referrerCounts, max: referrerMax};
		stats.geo = geo;
		stats.releaseId = release._id;

		return stats;
    }

}

export { StatFormatter };