import React, { Component } from 'react';
import './Overlay.css';

class Loading extends Component {
	
	render() {
		return (
			<div className="Overlay">
				<div className="Loading">
					<div className="LoadingInfo">
						<img src="/loading.gif" alt="Loading..." /><br/>
						Loading...
					</div>
				</div>
			</div>
		);
	}
}

export { Loading };