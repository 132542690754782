class GenreFormatter {
    constructor(genres) {
        this.genres = genres;
    }

    format() {
        var genresString = "";
        for (var idx = 0; idx < this.genres.length; idx++) {
            var genre = this.genres[idx].text;
            genresString += genre;
            if (idx < this.genres.length - 1) {
                genresString += ", ";
            }
        }
        return genresString;
    }
}

export { GenreFormatter };