import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import './Reset.css';
var FontAwesome = require('react-fontawesome');

class ChangePassword extends Component {
	
	constructor(props) {
		super(props);
		this.change = this.change.bind(this);
        this.close = this.props.close;
		this.handleUpdate = this.handleUpdate.bind(this);
		this.validate = this.validate.bind(this);
		this.detailsOkay = this.detailsOkay.bind(this);
		this.state = {
			values: {oldPassword: false, newPassword: false, confirmPassword: false},
			errors: {password: false, confirmPassword: false},
			message: false,
			done: false
		}
	}

	handleUpdate(event) {
		var values = this.state.values;
		var name = event.target.id;
		var value = event.target.value;
		values[name] = value;
		this.setState({values: values});
		this.validate(name, value);
	}

	validate(name, value) {
		if (name === "newPassword") {
			var lowerCaseLetters = /[a-z]/g;
			var upperCaseLetters = /[A-Z]/g;
			var numbers = /[0-9]/g;			
			if (value.match(lowerCaseLetters) && value.match(upperCaseLetters) && value.match(numbers) && value.length >= 8) {
				var errors = this.state.errors;
				errors.password = false;				
				this.setState({errors: errors});
			} else {
				var errors = this.state.errors;
				errors.password = "Password invalid";				
				this.setState({errors: errors});
			}
		} else if (name === "confirmPassword") {
			if (value === this.state.values.newPassword) {
				var errors = this.state.errors;
				errors.confirmPassword = false;				
				this.setState({errors: errors});
			} else {
				var errors = this.state.errors;
				errors.confirmPassword = "Needs to match password";				
				this.setState({errors: errors});
			}
		}
	}

	detailsOkay() {
		var errors = this.state.errors;
		var content = this.state.values.newPassword;
		return content && (! (errors.password || errors.confirmPassword));
	}
	
	change() {
		var obj = this; 
		return () => {
			var oldPassword = obj.state.values.oldPassword;
            var newPassword = obj.state.values.newPassword;
			Ajax.changePassword(oldPassword, newPassword).then(function (res) {
				if (res.error) {
					obj.setState({message: res.error});
				}
				else {
					obj.setState({message: "Password changed successfully.", done: true});	
				}			
			});
		};		
	}
	
	render() {
		var done = this.state.done;
		return (
            <div className="Window Change">
			<div className="WindowTopBar"><div className="WindowClose" onClick={this.close}><FontAwesome name='window-close' /></div></div>
			<div className="LoginRightTitle">Change your password</div>
			{done ? (
			<div className="ResetSuccessMessage">
				<p>Password successfully changed! You can continue!</p>
			</div>) : (
			<div className="Form">
				<p>You have requested to change your password. Enter your new password below.</p>
                <div className="FormField">
					<div className="FormFieldLabel">Old Password</div>
					<input type="password" id="oldPassword" placeholder="Old Password" value={this.state.values.oldPassword ? this.state.values.oldPassword : ""} onChange={this.handleUpdate} /><br/>										
				</div>
				<div className="FormField">
					<div className="FormFieldLabel">New Password</div>
					<input type="password" id="newPassword" placeholder="New Password" value={this.state.values.newPassword ? this.state.values.newPassword : ""} onChange={this.handleUpdate} /><br/>
					<p>Password must contain at least one uppercase letter, one lowercase letter, one number, and must be at least 8 characters long</p>
					{ this.state.errors.password ? (<div className="Error">{this.state.errors.password}</div>) : null }
				</div>
				<div className="FormField">
					<div className="FormFieldLabel">Confirm New Password</div>
					<input type="password" id="confirmPassword" placeholder="Confirm new password" value={this.state.values.confirmPassword ? this.state.values.confirmPassword : ""} onChange={this.handleUpdate} />
					{ this.state.errors.confirmPassword ? (<div className="Error">{this.state.errors.confirmPassword}</div>) : null }
				</div>
				<div className="ResetButton">					
					{ this.detailsOkay() ? (<div className="Button" onClick={this.change()}>Change Password</div>) : (<div className="Button Inactive">Change Password</div>) }
				</div>
				<div className="Message">{this.state.message}</div>
			</div>
			) 
			}
		</div>
		);
	}
}

export { ChangePassword };