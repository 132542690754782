import React, { Component } from 'react';
import {  BrowserRouter as Router, Route } from 'react-router-dom'
import './App.css';

import { Verify } from './components/auth/Verify.js';
import { Finalize } from './components/auth/Finalize.js';
import { Landing } from './components/pages/Landing.js';
import { DashboardLanding } from './components/pages/DashboardLanding.js';
import { NewRelease } from './components/pages/NewRelease.js';
import { EditRelease } from './components/pages/EditRelease.js';
import { Releases } from './components/releases/Releases.js';
import { Subscribers } from './components/pages/Subscribers.js';
import { ResetPassword } from './components/auth/ResetPassword.js';
import { Forgot } from './components/auth/Forgot.js';
import { Widgets } from './components/pages/Widgets.js';
import { Profile } from './components/pages/Profile.js';
import { Billing } from './components/pages/Billing.js';
import { FAQ } from './components/pages/FAQ.js';
import { Support } from './components/pages/Support.js';
import { Minipage } from './components/minipage/Minipage.js';
import { Catalog } from './components/catalog/Catalog.js';
import { LinkPage } from './components/linkpage/LinkPage.js';
import { Unsubscribe } from './components/pages/Unsubscribe.js';
import { Redirect } from './components/minipage/Redirect.js';
import { DefaultWidget } from './components/widgets/DefaultWidget.js';
import { Stats } from './components/pages/Stats.js';
import { Feed } from './components/feed/Feed.js';
import { EditCatalog } from './components/pages/EditCatalog';
import { EditLinkPage } from './components/pages/EditLinkPage';

class App extends Component {
  render() {
    return (
    <Router>
			<div className="Wrapper">
				<Route exact path='/' component={Landing}/>
				<Route exact path='/feed' component={Feed}/>
				<Route exact path='/artists' component={Landing}/>
				<Route path='/verify/:token' component={Verify}/>
				<Route path='/finalize/:token' component={Finalize}/>
				<Route path='/resetpassword/:token' component={ResetPassword}/>
				<Route path='/forgotpassword' component={Forgot}/>
				<Route exact path='/artists/dashboard' component={DashboardLanding}/>
				<Route exact path='/artists/newrelease' component={NewRelease}/>
				<Route exact path='/artists/release/:releaseId' component={EditRelease}/>
				<Route exact path='/artists/releases' component={Releases}/>
				<Route exact path='/artists/stats' component={Stats}/>
				<Route exact path='/artists/subscribers' component={Subscribers}/>
				<Route exact path='/artists/widgets' component={Widgets}/>
				<Route exact path='/artists/catalog' component={EditCatalog}/>
				<Route exact path='/artists/linkpage' component={EditLinkPage}/>
				<Route exact path='/artists/profile' component={Profile}/>
				<Route exact path='/artists/billing' component={Billing}/>
				<Route exact path='/artists/faq' component={FAQ}/>
				<Route exact path='/artists/contact' component={Support}/>
				<Route path='/mini/:releaseId' component={Minipage}/>
				<Route exact path='/catalog/:slug' component={Catalog}/>
				<Route exact path='/links/:slug' component={LinkPage}/>
				<Route path='/mini/:releaseId/:subscriberId' component={Minipage}/>
				<Route path='/unsubscribe/:list/:profileId/:subscriberId' component={Unsubscribe}/>
				<Route path='/redirect/:list/:platform/:releaseId/:subscriberId' component={Redirect}/>
				<Route path='/subwidget/:profileId' component={DefaultWidget}/>
    	</div>
		</Router>
    );
  }
}

export default App;
