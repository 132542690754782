const testMode = false;
const localMode = false;
const livePort = 3001;
const testPort = 3002;
const config = {
    localMode: localMode,
    test: testMode,
    livePort: livePort,
    testPort: testPort,

    local: {
        frontend: "http://localhost:3000/",
        backend: "http://localhost:3001/",
        backendPrivate: "http://localhost:3001/private/",
        backendPublic: "http://localhost:3001/public/",
        backendFiles: "http://localhost:3001/files/",
        ffmpeg: "E:/DRIVE/PLUVID/PRODUCTS/EARLY GROOVES/ffmpeg/ffmpeg.exe"
    },

    deployed: {
        frontend: testMode ? "https://test.earlygrooves.com/" : "https://earlygrooves.com/",
        backend: testMode ? "https://test.earlygrooves.com:" + testPort : "https://earlygrooves.com:" + livePort,
        backendPrivate: testMode ? "https://test.earlygrooves.com:" + testPort + "/private/" : "https://earlygrooves.com:" + livePort + "/private/",
        backendPublic: testMode ? "https://test.earlygrooves.com:" + testPort + "/public/" : "https://earlygrooves.com:" + livePort + "/public/",
        backendFiles: testMode ? "https://test.earlygrooves.com:" + testPort + "/files/" : "https://earlygrooves.com:" + livePort + "/files/",
        ffmpeg: "ffmpeg"
    },

    cookies: {
        sessionCookieName: "connect.sid",
        nameCookieName: "profileName",
        minipageVisitedCookieName: (releaseId) => { return "minipage_" + releaseId }
    },    
};

export { config };