import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import { config } from '../../config/config';

import './Finalize.css';

class Finalize extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			finalized: false, 
			token: this.props.match.params.token,
			email: false,
			values: {name: false, password: false, confirmPassword: false},
			errors: {name: false, password: false, confirmPassword: false},
			error: false,
		};
		this.href = this.href.bind(this);
		this.finalize = this.finalize.bind(this);
		this.detailsOkay = this.detailsOkay.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.validate = this.validate.bind(this);
	}

	componentWillMount() {
		var obj = this;
		Ajax.getPublicProfileByFinalizeToken(this.state.token).then((profile) => {			
			obj.setState({email: profile.email});
		});
	}

	finalize() {
		var obj = this;
		Ajax.finalize(this.state.token, this.state.values.name, this.state.values.password).then((output) => {			
			obj.setState({finalized: true});
		});
	}

	detailsOkay() {
		var errors = this.state.errors;
		var content = this.state.values.name && this.state.values.password && this.state.values.confirmPassword;
		return content && (! (errors.email || errors.password || errors.confirmPassword || errors.name)) && (! this.state.error);
	}
	
	validate(name, value) {
		var errors;
		if (name === "password") {
			var lowerCaseLetters = /[a-z]/g;
			var upperCaseLetters = /[A-Z]/g;
			var numbers = /[0-9]/g;			
			if (value.match(lowerCaseLetters) && value.match(upperCaseLetters) && value.match(numbers) && value.length >= 8) {
				errors = this.state.errors;
				errors.password = false;				
				this.setState({errors: errors});
			} else {
				errors = this.state.errors;
				errors.password = "Password invalid";				
				this.setState({errors: errors});
			}
		} else if (name === "confirmPassword") {
			if (value === this.state.values.password) {
				errors = this.state.errors;
				errors.confirmPassword = false;				
				this.setState({errors: errors});
			} else {
				errors = this.state.errors;
				errors.confirmPassword = "Needs to match password";				
				this.setState({errors: errors});
			}
		} else if (name === "name") {
			if (value && (value !== "")) {
				errors = this.state.errors;
				errors.name = false;
				this.setState({errors: errors});
			} else {
				errors = this.state.errors;
				errors.name = "You must enter a name";
				this.setState({errors: errors});
			}
		}
	}

	handleUpdate(event) {
		var values = this.state.values;
		var name = event.target.id;
		var value = event.target.value;
		values[name] = value;
		this.setState({values: values});
		this.validate(name, value);
	}

	href(path) {
		return function() {
			window.location.href = path;
		};
	}
	
	render() {
		var finalized = this.state.finalized;
		return (
		<div className="Finalize">
			<div className="FinalizeLogo"/>
			{finalized ? (
			<div className="FinalizeText">
				Thank you for registering an Early Grooves account! <a href={config.localMode ? config.local.frontend : config.deployed.frontend}>Sign in here!</a>
			</div>			
			) : (
			<div className="FinalizeForm">
				<div className="FinalizeFormTitle">Register</div>
				<div className="Form">
					<div className="FormField">
						<div className="FormFieldLabel">Artist name or Label name</div>
						<input type="text" id="name" placeholder="Artist name or label name" value={this.state.values.name ? this.state.values.name : ""} onChange={this.handleUpdate} />
						{ this.state.errors.name ? (<div className="Error">{this.state.errors.name}</div>) : null }
					</div>
					<div className="FormField">
						<div className="FormFieldLabel">E-Mail</div>
						<div className="FormFieldFixed">{this.state.email}</div>
					</div>
					<div className="FormField">
						<div className="FormFieldLabel">Password</div>
						<input type="password" id="password" placeholder="Password" value={this.state.values.password ? this.state.values.password : ""} onChange={this.handleUpdate} /><br/>
						<p>Password must contain at least one uppercase letter, one lowercase letter, one number, and must be at least 8 characters long</p>
						{ this.state.errors.password ? (<div className="Error">{this.state.errors.password}</div>) : null }
					</div>
					<div className="FormField">
						<div className="FormFieldLabel">Confirm Password</div>
						<input type="password" id="confirmPassword" placeholder="Confirm password" value={this.state.values.confirmPassword ? this.state.values.confirmPassword : ""} onChange={this.handleUpdate} />
						{ this.state.errors.confirmPassword ? (<div className="Error">{this.state.errors.confirmPassword}</div>) : null }
					</div>
					<div className="FinalizeButton">					
						{ this.detailsOkay() ? (<div className="Button" onClick={this.finalize}>Register</div>) : (<div className="Button Inactive">Register</div>) }
						{ this.state.error ? (<div className="Error">{this.state.error}</div>) : null }
					</div>				
				</div>				
			</div>) }
		</div>
		);
	}
}

export { Finalize };