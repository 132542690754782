import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import $ from 'jquery';

import './AudioPlaylist.css';

class AudioPlaylist extends Component {
	
	constructor(props) {		
		super(props);
		this.state = {mp3s: [], currentIdx: 0, playing: false, change: undefined}
		
		this.handlePlay = this.handlePlay.bind(this);
		this.handleStop = this.handleStop.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handlePlay = this.handlePlay.bind(this);
		this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
		this.handleLoad = this.handleLoad.bind(this);
		this.handleSeek = this.handleSeek.bind(this);
		this.handleVolumeChange = this.handleVolumeChange.bind(this);
		this.handleVolumeUpdate = this.handleVolumeUpdate.bind(this);
	}
	
	componentWillMount() {
		var mp3s = this.props.mp3;
		mp3s.sort(function(a, b) { return a.nr - b.nr });
		var idx = 0;
		var mp3 = mp3s[idx];
		var count = 0;
		while (! mp3.playable && count < mp3s.length) {
			count++;
			idx++;
			if (idx < mp3s.length)
				mp3 = mp3s[idx];
			else {
				this.setState({mp3s: mp3s, currentIdx: -1});
				return;
			}				
		}
		this.setState({mp3s: mp3s, currentIdx: idx, playing: false});
	}
	
	componentDidMount() {
		if (this.state.currentIdx !== -1)
			document.getElementById('player').volume = 0.8;
	}

	componentDidUpdate() {	
		var props = this.props;
		
		if (props.change !== this.state.change) {
			var mp3s = props.mp3;
			mp3s.sort(function(a, b) { return a.nr - b.nr });
			var idx = 0;
			var mp3 = mp3s[idx];
			var count = 0;
			while (! mp3.playable && count < mp3s.length) {
				count++;
				idx++;
				if (idx < mp3s.length)
					mp3 = mp3s[idx];
				else {
					this.setState({mp3s: mp3s, currentIdx: -1, change: props.change, playing: false});
					return;
				}				
			}
			this.setState({mp3s: mp3s, currentIdx: idx, change: props.change, playing: false}, () => {
				this.handleStop();
				this._setSource(true);	
			});
		}		
	}
	
	_setSource(hold) {
		document.getElementById('playerSrc').src = this.state.mp3s[this.state.currentIdx].src;
		document.getElementById('player').load();
		$("#progressBar").width(0);
		if (! hold)
			this.handlePlay();
	}
	
	handlePlay() {
		console.log(this.state);
		
		this.setState({playing: true}, () => {
			document.getElementById('player').play();
		});
	}
	
	handleStop() {
		this.setState({playing: false}, () => {
			document.getElementById('player').pause();
		});
	}
	
	handleNext() {
		var mp3s = this.state.mp3s;
		var idx = this.state.currentIdx;
		if (idx >= mp3s.length - 1) {				
			idx = 0;
		} else {
			idx++;								
		}
		var mp3 = mp3s[idx];
		var count = 0;
		while (! mp3.playable && count < mp3s.length) {
			count++;
			if (idx >= mp3s.length - 1) {				
				idx = 0;
			} else {
				idx++;								
			}		
			mp3 = mp3s[idx];
		}
		console.log(idx);
		this.setState({currentIdx: idx});
		this._setSource();
	}
	
	handlePrev() {
		var mp3s = this.state.mp3s;
		var idx = this.state.currentIdx;
		if (idx === 0) {				
			idx = mp3s.length - 1;
		} else {
			idx--;								
		}
		var mp3 = mp3s[idx];
		var count = 0;
		while (! mp3.playable && count < mp3s.length) {
			count++;
			if (idx === 0) {				
				idx = mp3s.length - 1;
			} else {
				idx--;								
			}		
			mp3 = mp3s[idx];
		}
		this.setState({currentIdx: idx});
		this._setSource();	
	}
	
	handleSelect(idx) {
		if (idx < this.state.mp3s.length) {
			this.setState({currentIdx: idx});
		}
		this._setSource();		
	}
	
	_formatTime(time) {
		if (isNaN(time))
			return "";
		var hours   = Math.floor(time / 3600);
		var minutes = Math.floor((time - (hours * 3600)) / 60);
		var seconds = Math.floor(time - (hours * 3600) - (minutes * 60));
		var formattedHours, formattedMinutes, formattedSeconds
		hours < 10 ? formattedHours = "0"+hours : formattedHours = hours;
		minutes < 10 ? formattedMinutes = "0"+minutes : formattedMinutes = minutes;
		seconds < 10 ? formattedSeconds = "0"+seconds : formattedSeconds = seconds;
		
		if (hours > 0)
			return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
		else
			return formattedMinutes + ":" + formattedSeconds;
		
	}
	
	handleTimeUpdate() {
		var player = document.getElementById('player');
		var duration = player.duration;
		var curr = player.currentTime;
		var ratio = curr/duration;
		$("#progressBar").width(ratio * 100 + '%');		
		$("#time").text("-" + this._formatTime(duration - curr));
	}
	
	handleVolumeUpdate() {
		var player = document.getElementById('player');
		var volume = player.volume;
		$("#volumeBar").width(volume * 100 + '%');
	}
	
	handleLoad() {
		var player = document.getElementById('player');
		var duration = player.duration;
		$("#progressBar").width(0);		
		$("#time").text("-" + this._formatTime(duration));		
	}
	
	handleVolumeChange(e) {
		var volumeBar = $("#volumeBarContainer");
		var w = volumeBar.width();
		var offset = volumeBar.offset();
		var x = e.clientX - offset.left;
		var player = document.getElementById('player');
		player.volume = Math.max(0, Math.min(1, x / w));
	}
	
	handleSeek(e) {
		var progressBar = $("#progressBarContainer");
		var w = progressBar.width();		
		var offset = progressBar.offset();
		var x = e.clientX - offset.left;
		var player = document.getElementById('player');
		player.currentTime = Math.floor(player.duration * Math.max(0, Math.min(1, (x / w))));
		if (! this.state.playing)
			this.handlePlay();
	}

	render() {
		var obj = this;
		var mp3s = this.state.mp3s;
		var various = this.props.various;
			
		if (mp3s.length > 0) {
			var idx = this.state.currentIdx;
			var current = mp3s[idx];
			return (
				<div className="AudioPlaylist">
				{ idx !== -1 ? (<audio className="AudioPlaylistHiddenPlayer" id="player" onEnded={this.handleNext} onTimeUpdate={this.handleTimeUpdate} onLoadedMetadata={this.handleLoad} onVolumeChange={this.handleVolumeUpdate}>
					<source id="playerSrc" src={current.src}/>
				</audio>) : null }				
				{ idx !== - 1 ? (<div className="AudioPlaylistControls">
					{ this.state.playing ? (<div className="AudioPlaylistStop AudioPlaylistButton" onClick={this.handleStop}><FontAwesome name="pause"/></div>) :
					(<div className="AudioPlaylistPlay AudioPlaylistButton" onClick={this.handlePlay}><FontAwesome name="play"/></div>) }
					<div className="AudioPlaylistNext AudioPlaylistButton" onClick={this.handlePrev}><FontAwesome name="step-backward"/></div>
					<div className="AudioPlaylistPrev AudioPlaylistButton" onClick={this.handleNext}><FontAwesome name="step-forward"/></div>					
					<div className="AudioPlaylistProgressBarContainer" id="progressBarContainer" onClick={this.handleSeek}>
						<div className="AudioPlaylistInfoTitle" dangerouslySetInnerHTML={ {__html: current.title} } /><div id="progressBar" className="AudioPlaylistProgressBar"/>
					</div>
					<div className="AudioPlaylistTime" id="time"></div>
					<div className="AudioPlaylistVolumeBarContainer" onClick={this.handleVolumeChange} id="volumeBarContainer">
						<div className="AudioPlaylistVolumeBar" id="volumeBar"/><FontAwesome name="volume-up"/>
					</div>
				</div>) : null }
				<div className="AudioPlaylistItems">
					{ mp3s ? mp3s.map(function(mp3, key) {
						var entry = mp3.nr + ". " + mp3.title; 
						if (various) {
							entry += " (" + mp3.artist + ")";
						}
						if (mp3.playable) {
							return (
								<div key={key} className={key === idx ? "AudioPlaylistItem AudioPlaylistItemPlaying" : "AudioPlaylistItem"}  onClick={function() { obj.handleSelect(key); }}>
									<FontAwesome name="play"/>
									<span className="AudioPlaylistItemTitle" dangerouslySetInnerHTML={ {__html:entry} } />
									<span className="AudioPlaylistItemLength" dangerouslySetInnerHTML={ {__html:mp3.length} } />
								</div>
								);
						} else {
							return (
								<div key={key} className="AudioPlaylistItem Unplayable">
									<FontAwesome name="play"/>
									<span className="AudioPlaylistItemTitle" dangerouslySetInnerHTML={ {__html:entry} } />
									<span className="AudioPlaylistItemLength" dangerouslySetInnerHTML={ {__html:mp3.length} } />
								</div>
								);
						}						
						}) : null
					}
				</div>
				</div>
			);	
		} else {
			return null;
		}
		
	}
}

export { AudioPlaylist };