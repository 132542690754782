import React, { Component } from 'react';

import { Ajax } from '../../ajax/Ajax.js';
import { Catalog } from '../catalog/Catalog.js';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from '../common/Loading.js';
import { config } from '../../config/config.js'
import FontAwesome from 'react-fontawesome';
import { SketchPicker } from 'react-color';

import './EditCatalog.css';

class EditCatalog extends Component {
	
	constructor(props) {
		super(props);
	
        this.selectAll = this.selectAll.bind(this);
        this.save = this.save.bind(this);
        this.selectBackgroundCover = this.selectBackgroundCover.bind(this);
        this.selectBackgroundColor = this.selectBackgroundColor.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
		this.toggleBlur = this.toggleBlur.bind(this);       
		this.uploadImage = this.uploadImage.bind(this);
		this.state = { profile: false, loading: true, saved: true, url: false,
			toggles: {
				showBackdrop: true,
				showTrackSelection: true
			}, change: false, uploadedImage: false
		};
		
	}
	
	componentWillMount() {
		var obj = this;		
		Ajax.getProfile().then((profile) => {
			if (profile.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});				
			} else {
				if (profile.catalog === undefined || ! profile.catalog) {
					profile.catalog = {};
					profile.catalog.backgroundCover = false;
					profile.catalog.backgroundSrc = (profile.logo !== undefined && profile.logo) ? profile.logo : false;
					profile.catalog.backgroundColor = "#ffffff";
				}		
				obj.setState({profile: profile, loading: false, url: config.localMode ? config.local.frontend + "catalog/" + profile.slug : config.deployed.frontend + "catalog/" + profile.slug}); 
			}
		});
    }
    
    selectAll(event) {
        event.target.setSelectionRange(0, event.target.value.length);
	}
	
    save() {
		var profile = this.state.profile;
		Ajax.updateProfile(profile).then((profile) => {
		});
    }
    
    selectBackgroundCover() {
        var profile = this.state.profile;
        if (profile.catalog === undefined) {
            profile.catalog = {};
        }
		profile.catalog.backgroundCover = true;
		if (this.state.uploadedImage) {
			profile.catalog.backgroundSrc = this.state.uploadedImage;
		} else {
			if (profile.catalog.backgroundSrc === undefined) {
				profile.catalog.backgroundSrc = profile.logo;
			}
		}
        this.setState({profile: profile, change: ! this.state.change}, this.save);
    }

    selectBackgroundColor(color) {
        var profile = this.state.profile;
        if (profile.catalog === undefined) {
            profile.catalog = {};
        }
        profile.catalog.backgroundCover = false;
        profile.catalog.backgroundColor = color.rgb;
        this.setState({profile: profile, change: ! this.state.change}, this.save);
    }

    handleToggle(field) {
		var obj = this;
		return function(event) {
            var toggles = obj.state.toggles;
            var toggle = toggles[field];
            var newToggle = ! toggle;
            toggles[field] = newToggle;
			obj.setState({toggles: toggles});
		}
	}
	
	toggleBlur() {
        var obj = this;
        return (event) => {
            var profile = obj.state.profile;
            if (profile.catalog === undefined) {
                profile.catalog = {};
            }
            if (profile.catalog.backgroundBlur === undefined) {
                profile.catalog.backgroundBlur = false;
            }
            profile.catalog.backgroundBlur = ! profile.catalog.backgroundBlur;
            obj.setState({profile: profile, change: ! obj.state.change}, obj.save);
        }        
	}
	
	uploadImage(event) {
		var obj = this;		
		var file = event.target.files[0];
		Ajax.upload(file, () => {}).then((data) => {
			var profile = obj.state.profile;
			var imageId = data.file;
			profile.catalog.backgroundSrc = imageId;
			profile.catalog.backgroundCover = true;
			obj.setState({profile: profile, uploadedImage: imageId, change: ! obj.state.change}, obj.save);
		});
	}
	
	render() {
        var obj = this;
		var profile = this.state.profile;
		
		if (profile) {
			var img = profile.catalog.backgroundSrc;
			if (img === undefined) {
				img = (profile.logo !== undefined && profile.logo) ? profile.logo : false;
			}
			console.log(img);
			return (
				<DashboardComponent>
                    <div className="EditCatalog">
						<div className="DashboardComponentHeader">
							<div className="DashboardComponentTitle"><FontAwesome name="newspaper" /> Catalog</div>
							<div className="DashboardComponentMenu">
                                <div className={ obj.state.saved ? "DashboardComponentMenuItem":"DashboardComponentMenuItem Unsaved" } onClick={obj.save} ><FontAwesome name="save" /> Save</div>
							</div>
						</div>
						<div className="DashboardComponentSection">
                            The Catalog uses information that you can edit on your profile. Click below to edit your profile.<br/><br/>
							<div className="Button" onClick={() => {window.location = "/artists/profile";}}>Edit Profile</div>
                        </div>
                        <div className="DashboardComponentSection">
                            <div className="PublicUrl">
                                <div className="PublicUrlTitle">Public URL</div>
                                <div className="PublicUrlInput"><input onClick={obj.selectAll} type="text" name="" value={obj.state.url} readOnly /></div>
                                <div className="PublicUrlButton"><a href={obj.state.url} target="_blank"><div className="Button">Visit</div></a></div>
                        
                            </div>
                        </div>
						<div className="DashboardComponentSection">
                            <div className="DashboardComponentSectionTitle">Preview</div>
                            <div className="CatalogPreview">
                                <Catalog profile={obj.state.profile} change={obj.state.change} />
                            </div>
                        </div>                    					
                    
						<div className="DashboardComponentSection">
                            <div className="DashboardComponentSectionTitle">Edit</div>
                            <div className="CatalogEdit">
								<div className="PreviewRightBlock">
									<div className="PreviewRightTestTitle">Catalog Backdrop
									{ this.state.toggles.showBackdrop ? 
										(<div className="ShowDetails" onClick={obj.handleToggle("showBackdrop")}><i className="fas fa-chevron-circle-up"></i></div>) : 
										(<div className="ShowDetails" onClick={obj.handleToggle("showBackdrop")}><i className="fas fa-chevron-circle-down"></i></div>)
									}
									</div>
									{ this.state.toggles.showBackdrop ? (
										<div>
											<div className="PreviewOption">
											<div className="PreviewOptionLabel">Use Image as Background</div>
												<div onClick={ this.selectBackgroundCover } className={ profile.catalog.backgroundCover ? "PreviewOptionInput PreviewOptionCover PreviewOptionCoverSelected" : "PreviewOptionInput PreviewOptionCover"}>
													{img && (img !== undefined) ? (<img src={ Ajax.getFile(img) } alt="backgroundImage" />) : (<FontAwesome name="image" />)}
												</div>
												<div className="CatalogUploadImage">
													<input ref={(ref) => this.logoUploader = ref} type="file" name="cover" id="cover" accept="image/jpeg" onChange={this.uploadImage}/>
													<div className="CatalogImage" onClick={() => { obj.logoUploader.click() } }>
														<div className="CatalogImageChange"><FontAwesome name="upload"/> Upload Background</div>
													</div>											
												</div>

												<div className="CoverArtOption">Blur Image <input type="checkbox" onChange={obj.toggleBlur()} checked={profile.catalog.backgroundBlur}/></div>
											</div>
											<div className="PreviewOption">
												<div className="PreviewOptionLabel">Use Color as Background</div>
												<div className="PreviewOptionInput PreviewOptionColor">
													<SketchPicker
														color={ profile.catalog.backgroundColor }
														onChangeComplete={ this.selectBackgroundColor }
													/>
												</div>
											</div>
										</div>
									) : null }                                                                                         
								</div>
							</div>
                        </div>
						{ this.state.loading ? (<Loading />) : null }
					</div>
				</DashboardComponent>			
			);		
		} else
			return null;
	}
}

export { EditCatalog };