import React, { Component } from 'react';

import { TimeChart } from './TimeChart2.js';
import { BarChart } from './BarChart.js';
import { StackedBarChart } from './StackedBarChart.js';
import { WorldMap } from "react-svg-worldmap";

import './StatSection.css';

class StatSection extends Component {
	
	constructor(props) {
		super(props);
		this.state = { stats: this.props.stats, releaseId: this.props.stats.releaseId};
    }
    
    componentDidUpdate(prevProps) {
        console.log(prevProps);
        console.log(this.props);
        if (this.props.stats.releaseId !== prevProps.stats.releaseId) {
            this.setState({stats: this.props.stats, releaseId: this.props.stats.releaseId});
        }
    }
	
	render() {
        var stats = this.state.stats;
        console.log(stats.referrers);
		return (
            <div className="StatSection">
            { stats ? (
                <div className="StatsVisits">
                    <div className="ChartTitle">Minipage Visits</div>
                    <div className="ChartSubTitle">Unique Visits - Daily</div>
                    {stats && stats.visits.length > 10 ? (<TimeChart data={stats.visits} height={200} width={530} releaseId={stats.releaseId} />) : null }
                    {stats && stats.visits.length <= 10 ? (<BarChart horizontal={false} max={stats.visits2.max} labels={stats.visits2.labels} counts={stats.visits2.counts} width="100%" height="200px" releaseId={stats.releaseId} />) : null }
                </div>) : null }
                {stats ? (
                <div className="StatsCounts">
                    <div className="ChartTitle">Total Redirects from Minipage</div>
                    <div className="ChartSubTitle">Newsletter + Minipage</div>
                    {stats ? (<StackedBarChart horizontal={true} max={stats.platform.max} labels={stats.platform.labels} counts={stats.platform.counts} width="100%" height="300px" releaseId={stats.releaseId} />) : null }
                </div>) : null }
                {stats ? (
                <div className="StatsMails">
                    <div className="ChartTitle">Newsletter</div>
                    <div className="ChartSubTitle">Overal data for your Newsletter</div>
                    {stats ? (<BarChart horizontal={false} max={stats.email.max} labels={stats.email.labels} counts={stats.email.counts} width="100%" height="300px" releaseId={stats.releaseId} />) : null }
                </div>) : null }
                {stats ? (
                <div className="StatsMap">
                    <div className="ChartTitle">Minipage Visitors</div>
                    <div className="ChartSubTitle">See where your visitors are located</div>
                    {stats ? (
                        <div className="StatsMapInner">
                        <WorldMap backgroundColor="rgba(0,0,0,0)" size="xxl" color="orange" value-suffix="people" data={stats.geo} />
                        </div>
                    ) : null }
                </div>) : null }
                {stats ? (
                <div className="StatsVisits">
                    <div className="ChartTitle">Referrers</div>
                    <div className="ChartSubTitle">Where your visitors are browsing from</div>
                    {stats ? (<BarChart horizontal={true} max={stats.referrers.max} labels={stats.referrers.labels} counts={stats.referrers.counts} width="100%" height="500px" releaseId={stats.releaseId} />) : null }
                </div>) : null }
            </div>
        );
	}
}

export { StatSection };