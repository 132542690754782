import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax.js';
import FontAwesome from 'react-fontawesome';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from '../common/Loading.js';

import './Support.css';

class Support extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			message: "",
			output: "",
			error: "",
			loading: true,
			sending: false
		};
		this.setMessage = this.setMessage.bind(this);
		this.sendMessage = this.sendMessage.bind(this);
	}

	componentWillMount() {
		var obj = this;
		Ajax.getProfile().then((profile) => {
			if (profile.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});
			} else {
				obj.setState({profile: profile, loading: false});
			}
		});
	}
	
	setMessage(event) {
		var message = event.target.value;
		this.setState({ message: message });
	}

	sendMessage() {
		var message = this.state.message;
		var obj = this;
		this.setState({sending: true}, () => {
			Ajax.sendTicket(message).then((result) => {
				if (typeof(result.error) !== "undefined") {
					obj.setState({error: result.error});
				} else {
					obj.setState({message: "", output: "Message sent!", sending: false});
				}
			});
		});		
	}

	render() {
		return (
			<DashboardComponent>
				<div className="Contact">
					<div className="DashboardComponentHeader">
						<div className="DashboardComponentTitle"><FontAwesome name="envelope" /> Contact</div>
						<div className="DashboardComponentMenu">
						</div>
					</div>

					<div className="DashboardComponentSection">
						<div className="DashboardComponentSectionTitle">Describe your issue</div>
						<div className="ContactForm">
							<div className="ContactField ContactMessage">								
								<div className="ContactInput"><textarea placeholder="Enter message here" id="message" onChange={this.setMessage} value={this.state.message} resize="false" /></div>
							</div>
							<div className="Button" onClick={this.sendMessage}>Send</div>
						</div>
						{ this.state.error !== "" ? (<div className="ContactError">{this.state.error}</div>) : null }		
						{ this.state.output !== "" ? (<div className="ContactMessage">{this.state.output}</div>) : null }
					</div>
					{ this.state.sending ? (<div className="Sending"><FontAwesome name="envelope" /> Sending... </div>) : null }
				</div>
				{ this.state.loading ? (<Loading />) : null }
			</DashboardComponent>
		);
	}
}

export { Support };