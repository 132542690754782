import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { Ajax } from '../../ajax/Ajax.js';
import { Minipage } from '../minipage/Minipage.js';
import FontAwesome from 'react-fontawesome';
import '../common/Overlay.css';
import './Preview.css';
import { config } from '../../config/config.js';
var removeDiacritics = require('diacritics').remove;

class MinipagePreview extends Component {

	constructor(props) {
        super(props);
        this.close = this.props.close;
        this.refreshRelease = this.props.refreshRelease;
        this.selectAll = this.selectAll.bind(this);
        this.save = this.save.bind(this);
        this.selectBackgroundCover = this.selectBackgroundCover.bind(this);
        this.selectBackgroundColor = this.selectBackgroundColor.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.toggleListen = this.toggleListen.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        var release = this.props.release;
        if ((release.minipage === undefined) || ! release.minipage) {
            release.minipage = {};
            release.minipage.backgroundCover = false;
            release.minipage.backgroundColor = "#ffffff";
            release.minipage.backgroundSrc = release.cover.large;
        }

        var slug = ((this.props.release.slug !== undefined) && this.props.release.slug) ? this.props.release.slug : this.props.release._id;
		this.state = {
            newsletter: false, 
            release: release, 
            minipageUrl: config.localMode ? config.local.frontend + "mini/" + slug : config.deployed.frontend + "mini/" + slug,
            slug: slug,
            slugs: this.props.slugs,
            change: false,
            toggles: {
                showBackdrop: true,
                showTrackSelection: true
            },
            uploadedImage: false,
            slugDirty: false
        };
	}

    selectAll(event) {
        event.target.setSelectionRange(0, event.target.value.length);
    }

    save() {
		var release = this.state.release;
		this.refreshRelease(release);
    }

    updateSlug() {
        var obj = this;
        return (event) => {
            var newSlug = event.target.value;
            obj.setState({slug: newSlug, slugDirty: true});
        }
    }

    setSlug() {
        var obj = this;
        return (event) => {
            var newSlug = obj.state.slug;
            if ((newSlug === undefined) || (newSlug.length === 0)) {
                return;
            } else {
                var release = obj.state.release;
                var slugs = obj.state.slugs;            
                var slugCounter = 0;
                var slugOk = false;
                var slug = false;
                while (! slugOk) {
                    slug = removeDiacritics(newSlug).replace(/\s+/g, "-").replace(/&/g, "and").replace(/\?/g, "").replace(/\//g, "-").replace(/\\/g, "-").replace(/:/g, "-").replace(/\(/g, "").replace(/\)/g, "").toLowerCase();
                    if (slugCounter > 0) {
                        slug = slug + "-" + slugCounter;
                    }
                    if (slugs[slug] !== undefined) {
                        var id = slugs[slug];
                        if (release._id === id || ! id) {
                            slugOk = true;
                        } else {
                            slugCounter++;
                            continue;
                        }
                    } else {
                        slugs[slug] = release._id;
                        slugOk = true;
                    }
                }
                if (slug) {
                    release.slug = slug;
                    obj.setState({slug: slug, slugDirty: false, release: release}, obj.save);
                }                
            } 
        }                       
    }
    
    selectBackgroundCover() {
        var release = this.state.release;
        if (release.minipage === undefined) {
            release.minipage = {};
        }
        if (this.state.uploadedImage) {
			release.minipage.backgroundSrc = this.state.uploadedImage;
		} else {
			release.minipage.backgroundSrc = release.cover.large;
		}
        release.minipage.backgroundCover = true;
        this.setState({release: release, change: ! this.state.change}, this.save);
    }

    selectBackgroundColor(color) {
        var release = this.state.release;
        if ((release.minipage === undefined)) {
            release.minipage = {};
        }
        release.minipage.backgroundCover = false;
        release.minipage.backgroundColor = color.rgb;
        this.setState({release: release, change: ! this.state.change}, this.save);
    }

    handleToggle(field) {
		var obj = this;
		return function(event) {
            var toggles = obj.state.toggles;
            var toggle = toggles[field];
            var newToggle = ! toggle;
            toggles[field] = newToggle;
			obj.setState({toggles: toggles});			
		}
    }
    
    toggleListen(trackIdx) {
        var obj = this;
        return (event) => {
            var release = obj.state.release;
            var tracks = release.tracks;
            tracks[trackIdx].listen = ! tracks[trackIdx].listen;
            release.tracks = tracks;
            obj.setState({release: release, change: ! this.state.change}, this.save);
        }               
    }

    toggleBlur() {
        var obj = this;
        return (event) => {
            var release = obj.state.release;
            if (release.minipage === undefined) {
                release.minipage = {};
            }
            if (release.minipage.backgroundBlur === undefined) {
                release.minipage.backgroundBlur = false;
            }
            release.minipage.backgroundBlur = ! release.minipage.backgroundBlur;
            obj.setState({release: release, change: ! this.state.change}, this.save);
        }        
    }

    uploadImage(event) {
		var obj = this;		
		var file = event.target.files[0];
		Ajax.upload(file, () => {}).then((data) => {
			var release = obj.state.release;
			var imageId = data.file;
			release.minipage.backgroundSrc = imageId;
			release.minipage.backgroundCover = true;
			obj.setState({release: release, uploadedImage: imageId, change: ! this.state.change}, obj.save);
		});
	}

    visitMiniPage() {
        var obj = this;
        return (event) => {
            var slug = obj.state.slug;
            window.open(config.localMode ? config.local.frontend + "mini/" + slug : config.deployed.frontend + "mini/" + slug, "_blank");
        }        
    }

	render() {
        var obj = this;
        var release = obj.state.release;
        var tracks = release.tracks;        
        var cover = Ajax.getFile(release.cover.large);
        if (release.cover.small) {
            cover = Ajax.getFile(release.cover.small);
        }
		return (
			<div className="Overlay">
                <div className="Window Preview">
                    <div className="WindowTopBar"><div className="WindowClose" onClick={this.close}><FontAwesome name='window-close' /></div></div>
                    <div className="PreviewLeft">
                        <Minipage release={ this.state.release } change={ this.state.change }/>
                    </div>
                    <div className="PreviewRight">
                    <div className="PreviewRightTitle">Minipage Preview</div>
                        <div className="PreviewRightBlock">
                            <div className="PreviewRightTestTitle">Minipage Public URL</div>
                            <div className="PreviewRightTestInput">{config.localMode ? config.local.frontend + "mini/" : config.deployed.frontend + "mini/"}<input onChange={this.updateSlug()} onBlur={this.setSlug()} type="text" name="slug" id="slug" value={this.state.slug} /></div>
                            <div className="PreviewRightTestButton">{obj.state.slugDirty ? (<div className="Button Inactive">Visit</div>) : (<div className="Button" onClick={obj.visitMiniPage()}>Visit</div>)}</div>
                        </div>
                        <div className="PreviewRightBlock">
                            <div className="PreviewRightTestTitle">Minipage Backdrop
                            { this.state.toggles.showBackdrop ? 
                                (<div className="ShowDetails" onClick={obj.handleToggle("showBackdrop")}><i className="fas fa-chevron-circle-up"></i></div>) : 
                                (<div className="ShowDetails" onClick={obj.handleToggle("showBackdrop")}><i className="fas fa-chevron-circle-down"></i></div>)
                            }
                            </div>
                            { this.state.toggles.showBackdrop ? (
                                <div>
                                    <div className="PreviewOption">
                                        <div className="PreviewOptionLabel">Use Cover Art</div>
                                        <div onClick={ this.selectBackgroundCover } className={ this.state.release.minipage.backgroundCover ? "PreviewOptionInput PreviewOptionCover PreviewOptionCoverSelected" : "PreviewOptionInput PreviewOptionCover"}>
                                            <img src={ Ajax.getFile(release.minipage.backgroundSrc) } alt="cover" />
                                        </div>
                                        <div className="MinipageUploadImage">
                                            <input ref={(ref) => this.logoUploader = ref} type="file" name="cover" id="cover" accept="image/jpeg" onChange={this.uploadImage}/>
                                            <div className="MinipageImage" onClick={() => { obj.logoUploader.click() } }>
                                                <div className="MinipageImageChange"><FontAwesome name="upload"/> Upload Background</div>
                                            </div>											
                                        </div>
                                        <div className="CoverArtOption">Blur Image <input type="checkbox" onChange={obj.toggleBlur()} checked={release.minipage.backgroundBlur}/></div>
                                    </div>
                                    <div className="PreviewOption">
                                        <div className="PreviewOptionLabel">Use Color</div>
                                        <div className="PreviewOptionInput PreviewOptionColor">
                                            <SketchPicker
                                                color={ this.state.release.minipage.backgroundColor }
                                                onChangeComplete={ this.selectBackgroundColor }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null }                                                                                         
                        </div>
                        <div className="PreviewRightBlock">
                            <div className="PreviewRightTestTitle">Include Tracks on Minipage
                            { this.state.toggles.showTrackSelection ? 
                                (<div className="ShowDetails" onClick={obj.handleToggle("showTrackSelection")}><i className="fas fa-chevron-circle-up"></i></div>) : 
                                (<div className="ShowDetails" onClick={obj.handleToggle("showTrackSelection")}><i className="fas fa-chevron-circle-down"></i></div>)
                            }
                            </div>                           
                            { this.state.toggles.showTrackSelection ? (
                                <div>
                                     <div className="PreviewRightSendText">
                                        Select which tracks are available for listening on your minipage.
                                    </div>
                                    <div className="PreviewTrackList">
                                        { tracks.map((track, idx) => {
                                            return (
                                                <div key={track.title + "_" + idx} onClick={obj.toggleListen(idx)} className={track.listen ? "PreviewTrack Listen" : "PreviewTrack"}>{track.trackNumber + ". " + track.title}</div>
                                            );
                                        })
                                        }
                                    </div>
                                </div>
                            ) : null }                                                                                         
                        </div>                        
                    </div>
                </div>
            </div>	
		);		
	}

}

export { MinipagePreview };