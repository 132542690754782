import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import FontAwesome from 'react-fontawesome';

import './Catalog.css';

import { isUndefined } from 'util';
import { ArtistFormatter } from '../common/ArtistFormatter';
import { MinipageWidget } from '../widgets/MinipageWidget';

const ACTIVE = 2;

class Catalog extends Component {
	
	constructor(props) {
        super(props);
        if ( this.props.profile !== undefined) {
            this.state = {slug: this.props.profile.slug, releases: [], loading: true, profile: this.props.profile};
        } else {
            this.state = {slug: this.props.match.params.slug, releases: [], loading: true, profile: false};
        }
        this.viewRelease = this.viewRelease.bind(this);
        this.open = this.open.bind(this);
    }
    
    componentWillMount() {        
        var obj = this;
        var slug = this.state.slug;
        Ajax.getProfileSlugs().then((slugs) => {
            var userId = slugs[slug];
            console.log(userId);
            console.log(slug);
            console.log(slugs);
            Ajax.getPublicReleases(userId).then((releases) => {
                var active = [];            
                for (var i = 0; i < releases.length; i++) {
                    var release = releases[i];
                    switch (release.status) {                    
                        case ACTIVE:
                            active.push(release);
                            break;
                        default:
                            break;
                    }
                }
                obj.setState({releases: active, loading: false});
            });
            Ajax.getPublicProfile(userId).then((profile) => {
                console.log(profile);
                obj.setState({profile: profile});
            });
        })
        
    }

    componentDidUpdate() {
        const newProps = this.props;
        if (! isUndefined(newProps.change) && newProps.change !== this.state.change) {
            var profile = newProps.profile;        
            this.setState({profile: profile, change: newProps.change});
        }
    }    

    viewRelease(release) {
        return () => { window.open("/mini/" + release.slug); };
    }

    open(link) {
        return () => { window.open(link); };
    }
	
	render() {
        var obj = this;

        var releases = this.state.releases;
        var profile = this.state.profile;
        var socials = profile.socials;
        var facebook, twitter, soundcloud, instagram, youtube, spotify, bandcamp;
      
        if (profile) {

            // check socials and set to false if they have weird values
            if ((socials !== undefined) && (socials !== null) && socials) {
                socials.facebook === undefined ? facebook = false : facebook = socials.facebook;
                socials.twitter === undefined ? twitter = false : twitter = socials.twitter;
                socials.soundcloud === undefined ? soundcloud = false : soundcloud = socials.soundcloud;
                socials.instagram === undefined ? instagram = false : instagram = socials.instagram;
                socials.youtube === undefined ? youtube = false : youtube = socials.youtube;
                socials.spotify === undefined ? spotify = false : spotify = socials.spotify;
                socials.bandcamp === undefined ? bandcamp = false : bandcamp = socials.bandcamp;
            } else {
                socials = false;
            }
            
            // check logo
            var logo = false;
            if (profile.logo) {
                logo = Ajax.getFile(profile.logo);
            }

            // set up background style        
            var catalog = profile.catalog;
            var backgroundStyle = {backgroundColor: "rgba(255,255,255,1)"};
            var background = false;
            if (catalog !== undefined && catalog !== null && catalog) {
                if  (catalog.backgroundCover && catalog.backgroundCover !== undefined) {
                    background = Ajax.getFile(catalog.backgroundSrc);
                    if (catalog.backgroundBlur !== undefined && catalog.backgroundBlur) {
                        backgroundStyle = {backgroundImage: "url(" + background + ")", filter: "blur(15px)"};
                    } else {
                        backgroundStyle = {backgroundImage: "url(" + background + ")"};
                    }
                } else {
                    if ((catalog.backgroundColor !== undefined) && catalog.backgroundColor) {
                        backgroundStyle = {backgroundColor: "rgba(" + catalog.backgroundColor.r + ", " + catalog.backgroundColor.g + ", " + catalog.backgroundColor.b + ", " + catalog.backgroundColor.a + ")"};       
                    }
                }
            }
        }  

		return (
            <div className="CatalogWrapper">
                 <div className="CatalogBackground" style={backgroundStyle}/>
                <div className="Catalog">
                    <div className="CatalogHeader"></div>
                    { profile ? (
                    <div className="CatalogUserInfo">
                        <div className="CatalogUserLogo">{logo ? (<img src={logo} alt="user logo" />) : null }</div>
                        <div className="CatalogUserName">{profile.name}</div>
                        { socials ? (
                        <div className="CatalogUserSocials">
                            { facebook ? (<div className="CatalogUserSocial" onClick={obj.open(facebook)}><span className="fab fa-facebook" /></div>) : null } 
                            { twitter ? (<div className="CatalogUserSocial" onClick={obj.open(twitter)}><span className="fab fa-twitter" /></div>) : null } 
                            { soundcloud ? (<div className="CatalogUserSocial" onClick={obj.open(soundcloud)}><span className="fab fa-soundcloud" /></div>) : null } 
                            { instagram ? (<div className="CatalogUserSocial" onClick={obj.open(instagram)}><span className="fab fa-instagram" /></div>) : null } 
                            { youtube ? (<div className="CatalogUserSocial" onClick={obj.open(youtube)}><span className="fab fa-youtube" /></div>) : null } 
                            { spotify ? (<div className="CatalogUserSocial" onClick={obj.open(spotify)}><span className="fab fa-spotify" /></div>) : null } 
                            { bandcamp ? (<div className="CatalogUserSocial" onClick={obj.open(bandcamp)}><span className="fab fa-bandcamp" /></div>) : null } 
                        </div>
                        ) : null }                        
                        <div className="CatalogUserDescription">{profile.about}</div>
                        <div className="CatalogUserSocials"><a href={profile.website} target="_blank">{profile.website}</a></div>
                    </div>) : null }
                    <div className="CatalogReleases">
                        <div className="CatalogReleasesTitle">Releases</div>
                        <div className="CatalogReleaseList">
                            {
                            releases.map(function (release, idx) {
                                var cover = false;
                                if (release.cover.large) {
                                    cover = Ajax.getFile(release.cover.large);
                                    if (release.cover.small) {
                                        cover = Ajax.getFile(release.cover.small);
                                    }
                                }				
                                return (
                                <div className="CatalogRelease" key={idx} onClick={obj.viewRelease(release)}>
                                    <a target="_blank" href={"/mini/" + release.slug}>
                                        <div className="CatalogReleaseCover">
                                            {cover ? (<img src={cover} alt="release cover" />) : (<FontAwesome name="image" />) }
                                        </div>
                                    </a>
                                    <div className="CatalogReleaseInfo">
                                        <div className="CatalogReleaseTitle">{release.title}</div>
                                        <div className="CatalogReleaseArtist">by { new ArtistFormatter(release.artists).format() }
                                        </div>
                                    </div>                                    
                                </div>
                                );
                            })
                            }
                        </div>
                    </div>
                    { profile ? (<MinipageWidget profileId={profile._id} />) : null }
                    <div className="MinipageEG">Powered by <a href="https://earlygrooves.com">Early Grooves</a></div>
                </div>
            </div>
				
		
		);
	}
}

export { Catalog };