import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { cookies } from '../../cookies/Cookies.js';

import { DashboardMenu } from './DashboardMenu.js';
import { DashboardMenuTop } from './DashboardMenuTop.js';

import '../common/Overlay.css';
import { Ajax } from '../../ajax/Ajax.js';
import './Dashboard.css';

class DashboardComponent extends Component {
	
	constructor(props) {
		super(props);		
		this.state = {loggedIn: false, token: false, validating: true};
		this.href = this.href.bind(this);
	}
	
	componentWillMount() {
		var obj = this;
		var authenticated = cookies.isAuthenticated();
		if (authenticated) {
			obj.setState({loggedIn: true, validating: false});
		} else {
			Ajax.logout().then(() => {
				obj.setState({validating: false});
			});			
		}
	}
	
	href(path) {
		return function() {
			window.location.href = path;
		};
	}
	
	render() {
		if (this.state.validating) {
			return (
			<div className="Validating">
				<div className="ValidatingInfo">
					<img src="/loading.gif" alt="Loading..." /><br/>
					Loading
				</div>
			</div>
			);
		}
		
		if (this.state.loggedIn) {
			return (
			<div className="Dashboard">
				<DashboardMenu />	
				<DashboardMenuTop />
				<div className="DashboardMenuTopLogo" onClick={this.href("/")}><FontAwesome name="sun" /></div>
				<div className="DashboardComponent">
					{ this.props.children }
				</div>				
			</div>
			);
		} else {
			window.location.href = "/";
			return null;
		}
		
	}
}

export { DashboardComponent };