import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import { config } from '../../config/config';

import './Verify.css';

class Verify extends Component {
	
	constructor(props) {
		super(props);
		this.state = {verified: false, error: false};
		this.href = this.href.bind(this);
	}
	
	componentWillMount() {
		var obj = this;
		var token = this.props.match.params.token;
		console.log(token);
		Ajax.verify(token).then(function(result) {
			if (result === undefined)  {
				obj.setState({verified: false, error: "Invalid Token"});
			} else {
				if (result.error !== undefined) {
					obj.setState({verified: true, error: result.error});
				}
				console.log(result);
				obj.setState({verified: true});
			}			
		});
	}

	href(path) {
		return function() {
			window.location.href = path;
		};
	}
	
	render() {
		return (
		<div className="Verify">
			<div className="VerifyLogo"/>
			{this.state.verified && ! this.state.error ? (
				<div className="VerifyText">E-mail verified!</div>) : 
				(<div className="VerifyText">Verifying...</div>)
				}
			{this.state.error ? (<div className="Error">Issue verifying your e-mail. Please try again.</div>) : null}
			<div className="VerifySignIn" onClick={this.href("/")}>
				<a href={config.localMode ? config.local.frontend : config.deployed.frontend}>Sign in here!</a>
			</div>
		</div>
		);
	}
}

export { Verify };