import React, { Component } from 'react';
import { Release } from '../releases/Release.js';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from '../common/Loading.js';
import { Ajax } from '../../ajax/Ajax.js';

class NewRelease extends Component {

	constructor(props) {
		super(props);
		this.state = {loading: true};
	}

	componentWillMount() {
		var obj = this;
		Ajax.getReleases().then((releases) => {
			if (releases.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});
			} else {
				for (var i = 0; i < releases.length; i++) {
					var release = releases[i];
					if (release.status === 0) {
						obj.setState({release: release, autosaved: true, loading: false});
						return;
					}
				}
				obj.setState({autosaved: false, loading: false});
			}
		});		
	}

	render() {
		if (this.state.loading) {
			return (
				<DashboardComponent>				
					<Loading />
				</DashboardComponent>		
			);		
		} else {
			return (
				<DashboardComponent>				
					{this.state.autosaved ? (<Release release={this.state.release} releaseId={this.state.release._id}/>) : (<Release />)}
				</DashboardComponent>	
			);
		}		
	}

}

export { NewRelease };