import React, { Component } from 'react';
import './Overlay.css';

class Popup extends Component {
	
	render() {
		return (
			<div className="Overlay">
				<div className="Popup">                    
                    {this.props.children}
				</div>
			</div>
		);
	}
}

export { Popup };