import React, { Component } from 'react';
import ReactTable from 'react-table';
import FontAwesome from 'react-fontawesome';

import { Ajax } from '../../ajax/Ajax.js';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from  '../common/Loading.js';
import { Popup } from '../common/Popup.js';

import 'react-table/react-table.css';
import './Subscribers.css';
import '../common/Overlay.css';

class Subscribers extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			presskit: [],
			newsletter: [],
			uploading: false,
			presskitName: false,
			presskitEmail: false,
			presskitAffiliation: false,
			newsletterName: false,
			newsletterEmail: false,
			loading: true,
			showCsvHelp: false,
			displayConfirmRemoveNewsletter: false,
			displayConfirmRemovePresskit: false
		}
		this.addCsv = this.addCsv.bind(this);
		this.addNewsletterSub = this.addNewsletterSub.bind(this);
		this.addPresskitSub = this.addPresskitSub.bind(this);
		this.href = this.href.bind(this);
		this.renderDeleteCell = this.renderDeleteCell.bind(this);
		this.openCsvHelp = this.openCsvHelp.bind(this);
		this.closeCsvHelp = this.closeCsvHelp.bind(this);
		this.removePresskit = this.removePresskit.bind(this);
		this.confirmDeletePresskit = this.confirmDeletePresskit.bind(this);
		this.cancelDeletePresskit = this.cancelDeletePresskit.bind(this);
		this.removeNewsletter = this.removeNewsletter.bind(this);
		this.confirmDeleteNewsletter = this.confirmDeleteNewsletter.bind(this);
		this.cancelDeleteNewsletter = this.cancelDeleteNewsletter.bind(this);
	}
	
	componentWillMount() {
		var obj = this;
		Ajax.getSubscribers().then(function(subscribers) {
			if (subscribers.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});
			} else {
				obj.setState({presskit: subscribers.presskit, newsletter: subscribers.newsletter, loading: false});
			}			
		});
	}

	href(path) {
		return function() {
			window.location.href = path;
		};
	}

	addCsv(list) {
		var obj = this;
		return (event) => {
			var file = event.target.files[0];
			this.setState({uploading: true}, () => {
				Ajax.importSubscribers(file, list).then(function(result) {
					Ajax.getSubscribers().then(function(subscribers) {
						obj.setState({presskit: subscribers.presskit, newsletter: subscribers.newsletter, uploading: false});
					});
				});
			});
		}		
	}

	addPresskitSub() {
		var obj = this;
		var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (obj.state.presskitEmail && re.test(obj.state.presskitEmail)) {
			var sub = {
				name: obj.state.presskitName,
				email: obj.state.presskitEmail,
				affiliation: obj.state.presskitAffiliation
			}
			obj.setState({uploading: true}, () => {
				Ajax.addSubscriber("presskit", sub).then(function(result) {
					Ajax.getSubscribers().then(function(subscribers) {
						obj.setState({presskit: subscribers.presskit, newsletter: subscribers.newsletter, uploading: false, presskitName: false, presskitEmail: false, presskitAffiliation: false});
					});
				});
			});
		}
		
	}

	addNewsletterSub() {
		var obj = this;
		var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (obj.state.newsletterEmail && re.test(obj.state.newsletterEmail)) {
			var sub = {
				name: obj.state.newsletterName,
				email: obj.state.newsletterEmail
			}
			obj.setState({uploading: true}, () => {
				Ajax.addSubscriber("newsletter", sub).then(function(result) {
					Ajax.getSubscribers().then(function(subscribers) {
						obj.setState({presskit: subscribers.presskit, newsletter: subscribers.newsletter, uploading: false, newsletterName: false, newsletterEmail: false});
					});
				});
			});
		}		
	}

	renderDeleteCell(isPresskit) {
		var obj = this;
		return (cellInfo) => {
			var idx = cellInfo.index;
			var data;
			if (isPresskit) {
				data = obj.state.presskit;
			} else {
				data = obj.state.newsletter;
			}
			
			return (
			<div className="SubscriberListDelete" onClick={
				() => {
					var sub = data[idx];					
					data.splice(idx, 1);
					if (isPresskit) {
						obj.setState({presskit: data});
						Ajax.removeSubscriber("presskit", sub).then((msg) => {

						});
					} else {
						obj.setState({newsletter: data});
						Ajax.removeSubscriber("newsletter", sub);
					}					
				}
			}>
				<FontAwesome name="minus-circle" />
			</div>
			);		
		};
	}

	openCsvHelp() {
		this.setState({showCsvHelp: true});
	}

	closeCsvHelp() {
		this.setState({showCsvHelp: false});
	}

	removePresskit() {
		this.setState({displayConfirmRemovePresskit: true});
	}

	confirmDeletePresskit() {
		var obj = this;
		Ajax.getProfile().then((profile) => {
			console.log(profile);
			Ajax.removeAllPresskitSubscribers(profile).then(() => {
				obj.setState({presskit: [], displayConfirmRemovePresskit: false});
			});		
		});
	}

	cancelDeletePresskit() {
		this.setState({displayConfirmRemovePresskit: false});
	}

	removeNewsletter() {
		this.setState({displayConfirmRemovePresskit: true});
	}

	confirmDeleteNewsletter() {
		var obj = this;
		Ajax.getProfile().then((profile) => {
			console.log(profile);
			Ajax.removeAllNewsletterSubscribers(profile).then(() => {
				obj.setState({newsletter: [], displayConfirmRemoveNewsletter: false});
			});		
		});
	}

	cancelDeleteNewsletter() {
		this.setState({displayConfirmRemoveNewsletter: false});
	}
	
	render() {
		var obj = this;
		return (
			<DashboardComponent>
				<div className="Subscribers">
					<div className="DashboardComponentHeader">
						<div className="DashboardComponentTitle"><FontAwesome name="list" /> Subscribers</div>
						<div className="DashboardComponentMenu">
							<div className="DashboardComponentMenuItem" onClick={this.href("#Presskit")} >Presskit</div>
							<div className="DashboardComponentMenuItem" onClick={this.href("#Newsletter")} >Newsletter</div>
						</div>
					</div>
					
					<div className="DashboardComponentSection" id="Presskit">
						<div className="DashboardComponentSectionTitle">Presskit</div>
						<div className="SubscriberTable">
							<ReactTable
								data={this.state.presskit}
								columns={[
									{
										Header: "E-Mail",
										accessor: "email"
									},
									{
										Header: "Name",
										accessor: "name"
									},
									{
										Header: "Affiliation",
										accessor: "affiliation"
									},
									{
										Header: "Opened",
										accessor: "opened"
									},
									{
										Header: "Clicked",
										accessor: "clicked"
									},
									{
										Header: "",
										Cell: this.renderDeleteCell(true),
										sortable: false,
										maxWidth: 40
									}
								]}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
						</div>
						<div className="SubscribersExtra">
							<div className="SubscriberImportHelp" onClick={this.openCsvHelp}>
								<FontAwesome name="question-circle" />
							</div>
							<div className="SubscriberAdd">
								<div className="SubscriberAddTitle">Add Manually</div>
								<div className="SubscriberField">
									<div className="SubscriberFieldTitle">E-mail</div>
									<div className="SubscriberFieldInput"><input type="email" id="presskitEmail" placeholder="Enter E-mail" value={this.state.presskitEmail ? this.state.presskitEmail : ""} onChange={(event) => { this.setState({presskitEmail: event.target.value}); }}  /></div>
								</div>
								<div className="SubscriberField">
									<div className="SubscriberFieldTitle">Name</div>
									<div className="SubscriberFieldInput"><input type="text" placeholder="Enter Name" value={this.state.presskitName ? this.state.presskitName : ""} onChange={(event) => { this.setState({presskitName: event.target.value}); }}  /></div>
								</div>
								<div className="SubscriberField">
									<div className="SubscriberFieldTitle">Affiliation</div>
									<div className="SubscriberFieldInput"><input type="text" placeholder="Enter Affiliation" value={this.state.presskitAffiliation ? this.state.presskitAffiliation : ""} onChange={(event) => { this.setState({presskitAffiliation: event.target.value}); }} /></div>
								</div>
								<div className="Button" onClick={this.addPresskitSub}>Add</div>
							</div>
							<div className="SubscriberRemoveAll">
								<div className="SubscriberRemoveAllButton" onClick={this.removePresskit}><FontAwesome name="trash-alt"/> Remove ALL Presskit Subscribers</div>
							</div>
							<input ref={(ref) => this.presskitUploader = ref} type="file" name="presskit" id="presskit" accept=".csv" onChange={this.addCsv("presskit")}/>
							<div className="SubscriberImportExport">
								<div className="SubscriberImport" onClick={() => { this.presskitUploader.click() } }>							
									<div className="SubscriberImportIcon"><FontAwesome name="file-csv" /></div>
									<div className="SubscriberImportText">Import CSV</div>
								</div>
								<a href={Ajax.getCsv("presskit")} target="_blank">
									<div className="SubscriberExport">
										<div className="SubscriberImportIcon"><FontAwesome name="download" /></div>
										<div className="SubscriberImportText">Export CSV</div>
									</div>
								</a>
							</div>
						</div>
					</div>

					<div className="DashboardComponentSection" id="Newsletter">
						<div className="DashboardComponentSectionTitle">Newsletter</div>
						<div className="SubscriberTable">
							<ReactTable
								data={this.state.newsletter}
								columns={[
									{
										Header: "E-Mail",
										accessor: "email"
									},
									{
										Header: "Name",
										accessor: "name"
									},
									{
										Header: "Opened",
										accessor: "opened"
									},
									{
										Header: "Clicked",
										accessor: "clicked"
									},
									{
										Header: "",
										Cell: this.renderDeleteCell(false),
										sortable: false,
										maxWidth: 40
									}
								]}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
						</div>
						<div className="SubscribersExtra">
							<div className="SubscriberImportHelp" onClick={this.openCsvHelp}>
								<FontAwesome name="question-circle" />
							</div>
							<div className="SubscriberAdd">
								
								<div className="SubscriberAddTitle">Add Manually</div>
								<div className="SubscriberField">
									<div className="SubscriberFieldTitle">E-mail</div>
									<div className="SubscriberFieldInput"><input type="email" placeholder="Enter E-mail"  value={this.state.newsletterEmail ? this.state.newsletterEmail : ""} onChange={(event) => { this.setState({newsletterEmail: event.target.value}); }} /></div>
								</div>
								<div className="SubscriberField">
									<div className="SubscriberFieldTitle">Name</div>
									<div className="SubscriberFieldInput"><input type="text" placeholder="Enter Name"  value={this.state.newsletterName ? this.state.newsletterName : ""} onChange={(event) => { this.setState({newsletterName: event.target.value}); }} /></div>
								</div>
								<div className="Button" onClick={this.addNewsletterSub} >Add</div>
							</div>
							<div className="SubscriberRemoveAll">
								<div className="SubscriberRemoveAllButton" onClick={this.removeNewsletter}><FontAwesome name="trash-alt"/> Remove ALL Newsletter Subscribers</div>
							</div>
							<input ref={(ref) => this.newsletterUploader = ref} type="file" name="newsletter" id="newsletter" accept=".csv" onChange={this.addCsv("newsletter")}/>
							<div className="SubscriberImportExport">
								<div className="SubscriberImport" onClick={() => { this.newsletterUploader.click() } }>							
									<div className="SubscriberImportIcon"><FontAwesome name="file-csv" /></div>
									<div className="SubscriberImportText">Import CSV</div>
								</div>
								<a href={Ajax.getCsv("newsletter")} target="_blank">
									<div className="SubscriberExport">
										<div className="SubscriberImportIcon"><FontAwesome name="download" /></div>
										<div className="SubscriberImportText">Export CSV</div>
									</div>
								</a>
							</div>
						</div>
					</div>			
					{ this.state.uploading ? (
						<div className="Overlay">
							<div className="Loading">
								<div className="LoadingInfo">
									<img src="/loading.gif" alt="Loading..." /><br/>
									Uploading
								</div>
							</div>
						</div>) : null }
				</div>
				{ this.state.showCsvHelp ? (
					<div className="Overlay">
						<div className="Window CsvHelp">
							<div className="WindowTopBar"><div className="WindowClose" onClick={obj.closeCsvHelp}><FontAwesome name='window-close' /></div></div>
							<div className="WindowTitle">Importing csv files</div>
							<p>You can import subscribers from other sources right here. We allow the format of the file to be the following:<br/>
							&bull; Headers are required and we parse: email, name, surname, affiliation<br/>
							&bull; Comma delimiter: ,<br/>
							&bull; if a field contains the delimiter, it should be surrounded by quotes<br/></p>
							<p>These are the fields we take into account:<br/>
								&bull; email <span className="Descriptive">required</span><br/>
								&bull; name<br/>
								&bull; surname<br/>
								&bull; affiliation <span className="Descriptive">only for the presskit mailing list</span><br/>
							</p>
							<p>Below is a sample of valid css (careful, no spaces between headers)</p>
							<p className="CsvSample">
								email,name,surname,affiliation<br/>
								brycen.waters@lehner.com,Brycen,Waters,Some Newspaper<br/>
								gloriaknapp@mail.com,Gloria,Knapp,Vice<br/>
								umiller@yahoo.com,Ulrike,Miller,The Times<br/>
								...								
							</p>								
						</div>
					</div>
				): null}
				{ this.state.loading ? (<Loading />) : null }
				{obj.state.displayConfirmRemovePresskit ? (
                <Popup>
                    <p>You are about to remove ALL of your PRESSKIT subscribers. Are you sure?<br/><br/></p>
                    <div className="ButtonContainer"><div className="Button" onClick={this.confirmDeletePresskit}>Yes, do it!</div></div>
                    <div className="ButtonContainer"><div className="Button" onClick={this.cancelDeletePresskit}>Cancel</div></div>
                </Popup>
                ) : null }
				{obj.state.displayConfirmRemoveNewsletter ? (
                <Popup>
                    <p>You are about to remove ALL of your NEWSLETTER subscribers. Are you sure?<br/><br/></p>
                    <div className="ButtonContainer"><div className="Button" onClick={this.confirmDeleteNewsletter}>Yes, do it!</div></div>
                    <div className="ButtonContainer"><div className="Button" onClick={this.cancelDeleteNewsletter}>Cancel</div></div>
                </Popup>
                ) : null }
			</DashboardComponent>
		
		
		);
	}
	
}


export { Subscribers };