import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dragula from "react-dragula";
import FontAwesome from 'react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { WithContext as ReactTags } from 'react-tag-input';

import genresList from './genres.js';
import { Ajax } from '../../ajax/Ajax.js';

import { NewsletterPreview } from './NewsletterPreview.js';
import { PresskitPreview } from './PresskitPreview.js';
import { MinipagePreview } from './MinipagePreview.js';

import './Release.css';
import './dragula.css';
import { isUndefined } from 'util';
import { ArtistFormatter } from '../common/ArtistFormatter.js';
import { makeDefaultNewsletter, makeDefaultPresskit, makeEmptyRelease } from '../common/ReleaseDefaults.js';
import { timingSafeEqual } from 'crypto';



var removeDiacritics = require('diacritics').remove;

const AUTOSAVE = 0;
const DRAFT = 1;
const ACTIVE = 2;

var genres = [...genresList];

class Release extends Component {
	
	constructor(props) {
		super(props);
		this.state = { 
			toggles: {download: false, listen: false}, 
			showNewsletterPreview: false, 
			showPresskitPreview: false, 
			showDemoPreview: false,
			showMinipagePreview: false, 
			saving: false, 
			showSaving: false,
			coverError: "",
			slugs: false,
			profile: false,
			usedOdesli: false,
			autodetecting: false
		 };
		if (this.props.release !== undefined) {
			var propsRelease = this.props.release;
			var tracks = propsRelease.tracks;
			tracks.sort((a, b) => {return a.trackNumber - b.trackNumber});
			propsRelease.tracks = tracks;
			if (typeof(propsRelease.artists) === "string")
				propsRelease.artists = [];
			if (propsRelease.genres === undefined)
				propsRelease.genres = [];
			if (propsRelease.newsletter === undefined)
				propsRelease.newsletter = makeDefaultNewsletter();
			this.state.release = propsRelease;
			if (propsRelease.presskit === undefined)
				propsRelease.presskit = makeDefaultPresskit();
			var links = propsRelease.links;
			for (var i = 0; i < links.length; i++) {
				var link = links[i];
					link.id = i+1;
			}
			propsRelease.links = links;
			this.state.release = propsRelease;
		} else {
			this.state.release = makeEmptyRelease();
		}
		
		this.state.progress = 0;
		this.state.coverProgress = 0;
		this.state.uploadingCover = false;
		this.state.queue = [];
		this.state.linkInput = "";
		this.state.saved = true;
			
		this.setReleaseValue = this.setReleaseValue.bind(this);
		this.handleDeleteArtist = this.handleDeleteArtist.bind(this);
		this.handleAddArtist = this.handleAddArtist.bind(this);
		this.handleDeleteGenre = this.handleDeleteGenre.bind(this);
		this.handleAddGenre = this.handleAddGenre.bind(this);
		this.setReleaseDate = this.setReleaseDate.bind(this);
		this.setPrereleaseDate = this.setPrereleaseDate.bind(this);
		
		this.setLinkValue = this.setLinkValue.bind(this);		
		this.autodetect = this.autodetect.bind(this);
		this.canUseOdesli = this.canUseOdesli.bind(this);
		this.addLink = this.addLink.bind(this);
		this.updateLinkUrl = this.updateLinkUrl.bind(this);
		this.updateLinkPlatform = this.updateLinkPlatform.bind(this);
		this.updateLinkAction = this.updateLinkAction.bind(this);
		this.removeLink = this.removeLink.bind(this);
		
		this.uploadFiles = this.uploadFiles.bind(this);
		this.removeTrack = this.removeTrack.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handleToggleAll = this.handleToggleAll.bind(this);
		this.handleProgress = this.handleProgress.bind(this);
		this.handleCoverProgress = this.handleCoverProgress.bind(this);
		this.dragulaDecorator = this.dragulaDecorator.bind(this);	
		this.dragulaDecoratorLinks = this.dragulaDecoratorLinks.bind(this);
		
		this.uploadCover = this.uploadCover.bind(this);
		
		this.upload = this.upload.bind(this);
					
		this.updateNewsletter = this.updateNewsletter.bind(this);
		this.updatePresskit = this.updatePresskit.bind(this);
		this.save = this.save.bind(this);
		this.saveDraft = this.saveDraft.bind(this);
		this.saveActive = this.saveActive.bind(this);

		this.previewNewsletter = this.previewNewsletter.bind(this);
		this.previewPresskit = this.previewPresskit.bind(this);
		this.previewMinipage = this.previewMinipage.bind(this);
		this.refreshRelease = this.refreshRelease.bind(this);
					
		this.drake = null;
		this.drakeLinks = null;

		this.handleKeyPress = this.handleKeyPress.bind(this);
		
		this.platforms = {
			"&app=itunes": "itunes",
			"itunes": "apple",
			"apple": "apple",
			"spotify": "spotify",
			"youtube": "youtube",
			"amazon": "amazon",
			"deezer": "deezer-icon.png",
			"napster": "napster",
			"google": "google-play",
			"bandcamp": "bandcamp",
			"soundcloud": "soundcloud",
			"tidal": "tidal-icon.png",
			"yandex": "yandex-icon.png",
			"pandora": "pandora-icon.png"
		}
		
		this.platformNames = {
			"itunes": "iTunes",
			"apple": "Apple Music",
			"spotify": "Spotify",
			"youtube": "Youtube",
			"amazon": "Amazon",
			"deezer-icon.png": "Deezer",
			"napster": "Napster",
			"google-play": "Google Play",
			"bandcamp": "Bandcamp",
			"soundcloud": "Soundcloud",
			"tidal-icon.png": "Tidal",
			"pandora-icon.png": "Pandora",
			"yandex-icon.png": "Yandex"
		}

		this.platformActions = {
			"itunes": "Buy",
			"apple": "Listen",
			"spotify": "Listen",
			"youtube": "Listen",
			"amazon": "Buy",
			"deezer-icon.png": "Listen",
			"napster": "Listen",
			"google-play": "Buy",
			"bandcamp": "Buy",
			"soundcloud": "Listen",
			"tidal-icon.png": "Listen",
			"pandora-icon.png": "Listen",
			"yandex-icon.png": "Listen"
		}
		
	}

	handleKeyPress() {
		var obj = this;
		return (event) => {
			if ((event.keyCode === 83) && event.ctrlKey) {
				event.preventDefault();
				obj.save();
			}
		}		
	}

	componentWillMount() {
		var obj = this;
		Ajax.getProfile().then((profile) => {
			var profileGenres = profile.genres;
			if (profileGenres !== undefined) {
				genres = genres.concat(profileGenres);
			}				
			obj.setState({profile: profile});
		});
		Ajax.getReleases().then((releases) => {
			var slugs = {};
			for (var i = 0; i < releases.length; i++) {
				var release = releases[i];
				var slug = release.slug;
				if ((! isUndefined(slug)) && slug) {
					slugs[slug] = release._id;
				}
			}
			obj.setState({slugs: slugs});
		});
		
	}

	componentDidMount() {
		var release = this.state.release;
		var profile = this.state.profile;
		var presskit = release.presskit;
		for (var i = 0; i < presskit.length; i++) {
			var part = presskit[i];
			if (part.type === "contact") {
				part.variables.contactEmail = profile.email;
				break;
			}
		}
		this.setState({release: release});
		document.addEventListener('keydown', this.handleKeyPress());
	}

	componentWillUnmount() {
		document.removeEventListener('keydown',this.handleKeyPress());
	  }

	handleToggle(trackIdx, field) {
		var obj = this;
		var release = this.state.release;
		return function(event) {
			var tracks = release.tracks;
			var track = tracks[trackIdx];
			var value = track[field];
			track[field] = ! value;
			tracks[trackIdx] = track;
			release.tracks = tracks;
			if (field === "cover" && ! value) {
				track.showDetails = true;
			}
			obj.setState({release: release, saved: false}, obj.save);			
		}
	}
	
	handleToggleAll(field) {
		var obj = this;
		var release = this.state.release;
		var toggles = this.state.toggles;
		var value = toggles[field];
		return function(event) {
			var tracks = release.tracks;
			for (var i = 0; i < tracks.length; i++) {
				var track = tracks[i];
				track[field] = ! value;
				tracks[i] = track;
			}
			toggles[field] = ! value;
			release.tracks = tracks;			
			obj.setState({release: release, toggles: toggles, saved: false}, obj.save);			
		}
	}
	
	handleUpdate(trackIdx, field) {
		var obj = this;
		var release = this.state.release;
		return function(event) {
			var tracks = release.tracks;
			var track = tracks[trackIdx];
			track[field] = event.target.value;
			tracks[trackIdx] = track;
			release.tracks = tracks;
			obj.setState({release: release, saved: false});
		}
	}
	
	dragulaDecorator(componentBackingInstance) {
		var obj = this;
		if (componentBackingInstance) {
			let options = {
				moves: function (el, container, handle) {
						return (handle.classList.contains('EditReleaseTrackHandle') || handle.classList.contains('fa-ellipsis-v'));
					}
			};
			obj.drake = dragula([componentBackingInstance], options);
			var from;
			obj.drake.on('drag', function(element, source) {
				var index = [].indexOf.call(element.parentNode.children, element);
				from = index;
			});
	
			obj.drake.on('drop', function(element, target, source, sibling) {
				var index = [].indexOf.call(element.parentNode.children, element);
				obj.state.release.tracks.splice(index, 0, obj.state.release.tracks.splice(from, 1)[0]);
				var release = obj.state.release;	
				var tracks = release.tracks;											
				for (var i = 0; i < tracks.length; i++) {					
					var track = tracks[i];
					track.trackNumber = i + 1;					
				}				
				obj.setState({release: release, saved: false}, obj.save);
			});

		}
	}

	dragulaDecoratorLinks(componentBackingInstance) {
		var obj = this;
		if (componentBackingInstance) {
			let options = {
				moves: function (el, container, handle) {
						return (handle.classList.contains('EditReleaseLinkHandle') || handle.classList.contains('fa-ellipsis-v'));
					}
			};
			obj.drakeLinks = dragula([componentBackingInstance], options);
			var from;
			obj.drakeLinks.on('drag', function(element, source) {
				var index = [].indexOf.call(element.parentNode.children, element);
				from = index;
			});
	
			obj.drakeLinks.on('drop', function(element, target, source, sibling) {
				var index = [].indexOf.call(element.parentNode.children, element);
				obj.state.release.links.splice(index, 0, obj.state.release.links.splice(from, 1)[0]);
				var release = obj.state.release;	
				obj.setState({release: release, saved: false}, obj.save);
			});

		}
	}
	
	setReleaseDate(date) {
		var release = this.state.release;
		release.releaseDate = date;
		this.setState({ release: release, saved: false });
	}
	
	setPrereleaseDate(date) {
		var release = this.state.release;
		release.prereleaseDate = date;
		this.setState({ release: release, saved: false });
	}
	
	setReleaseValue(field) {
		var obj = this;
		return function(event) {
			var val = event.target.value;
			var release = obj.state.release;
			release[field] = val;
			obj.setState({ release: release, saved: false });
		}
	}
	
	handleDeleteArtist(i) {
        var release = this.state.release;
		release.artists = release.artists.filter((artist, index) => index !== i);
        this.setState({release: release, saved: false}, this.save);
    }
 
    handleAddArtist(artist) {
		var release = this.state.release;
		release.artists = [...release.artists, artist];
        this.setState({release: release, saved: false}, this.save);
    }
	
	handleDeleteGenre(i) {
        var release = this.state.release;
		release.genres = release.genres.filter((genre, index) => index !== i);
        this.setState({release: release, saved: false}, this.save);
    }
 
    handleAddGenre(genre) {
		var obj = this;
		var release = this.state.release;
		release.genres = [...release.genres, genre];
        this.setState({release: release, saved: false}, () => {
			obj.save();
			Ajax.addProfileGenre(genre);
		});
    }
	
	handleProgress(size) {
		var obj = this;
		return function(progress) {			
			var percent = Math.round((progress.loaded / size) * 100);
			obj.setState({progress: percent});
		};
	}

	handleCoverProgress(size) {
		var obj = this;
		return function(progress) {
			var percent = Math.round((progress.loaded / size) * 100);
			obj.setState({coverProgress: percent});
		};
	}
	
	upload() {
		var queue = this.state.queue;
		if (queue.length === 0)
			return;
		var file = queue[0];		
		var obj = this;
		var release = obj.state.release;
		if (isUndefined(release._id)) {
			obj.save(obj.upload);
		} else {
			Ajax.uploadTrack(release._id, file, this.handleProgress(file.size)).then((track) => {
				queue.splice(0, 1);				
				var tracks = release.tracks;
				track.trackNumber = tracks.length + 1;				
				if (isUndefined(track.artist) || (track.artist === "")) {
					if ((! isUndefined(obj.state.artists) && obj.state.artists.length > 0))
						track.artist = obj.state.artists[0].text;
				}				
				tracks.push(track);
				release.tracks = tracks;
				obj.setState({queue: queue, release: release, saved: false}, () => {
					if (queue.length > 0) {
						obj.upload();
					} else {
						obj.save();
					}
				});			
			});
		}
	}
	
	uploadFiles(event) {
		var files = event.target.files;
		var queue = [];
		for (var i = 0; i < files.length; i++) {
			queue.push(files[i]);
		}
		this.setState({queue: queue}, this.upload);
	}
	
	uploadCover(event) {
		var obj = this;		
		var file = event.target.files[0];
		var release = obj.state.release;
		obj.setState({uploadingCover: true}, () => {
			if (release._id === undefined) {
				obj.save(() => {
					obj.uploadCover(event);
				});
			} else {
				Ajax.uploadCover(release._id, file, this.handleCoverProgress(file.size)).then((cover) => {
					if (cover.error) {
						obj.setState({coverError: "error uploading cover: " + cover.error, uploadingCover: false});
					} else {					
						var release = obj.state.release;
						release.cover = cover;
						obj.setState({coverError: false, release: release, uploadingCover: false}, obj.save);
					}				
				});
			}
		});
	}
	
	removeTrack(id) {
		var obj = this;		
		return function(event) {
			var release = obj.state.release;
			var tracks = release.tracks;
			tracks.splice(id, 1);
			for (var i = 0; i < tracks.length; i++) {					
				var track = tracks[i];
				track.trackNumber = i + 1;					
			}
			obj.setState({release: release, saved: false}, obj.save);
		}
	}
	
	setLinkValue(event) {
		var val = event.target.value;
		this.setState({ linkInput: val });
	}

	pushLink(url, platforms, links) {
		var platformFound = false;
		for (var i = 0; i < platforms.length; i++) {
			var platform = platforms[i];
			if (url.includes(platform)) {
				var icon = this.platforms[platform];
				var linkFound = false;
				for (var j = 0; j < links.length; j++) {
					var link = links[j];
					if (link.icon === icon) {
						links[j] = {id: links.length + 1, icon: icon, url: url, img: link.icon.replace("-icon.png", "") + ".png", platform: this.platformNames[icon], action: this.platformActions[icon]};
						linkFound = true;
					}
				}
				if (! linkFound)
					links.push({id: links.length + 1, icon: icon, url: url, img: icon.replace("-icon.png", "") + ".png", platform: this.platformNames[icon], action: this.platformActions[icon]});					
				return links;
			}
		}
		if (! platformFound) {
			links.push({id: links.length + 1, icon: "shopping-cart", url: url, img: "shopping-cart.png", platform: "", action: "Buy"});
			return links;
		}
	}

	canUseOdesli() {
		var obj = this;
		var release = obj.state.release;
		var links = release.links;
		var odesliPlatforms = [
			"spotify",
			"itunes",
			"apple",
			"youtube",
			"google",
			"pandora",
			"deezer",
			"tidal",
			"amazon",
			"soundcloud",
			"napster",
			"yandex",
			"spinrilla",
			"audius"
		];
		for (var j = 0; j < links.length; j++) {
			var link = links[j];
			var url = link.url;				
			for (var i = 0; i < odesliPlatforms.length; i++) {
				var platform = odesliPlatforms[i];
				if (url.includes(platform)) {
					return true;
				}
			}
		}
		return false;
	}

	autodetect() {
		var obj = this;
		var odesliPlatforms = [
			"spotify",
			"itunes",
			"apple",
			"youtube",
			"google",
			"pandora",
			"deezer",
			"tidal",
			"amazon",
			"soundcloud",
			"napster",
			"yandex",
			"spinrilla",
			"audius"
		]
		var platforms = Object.keys(obj.platforms);

		return () => {			
			var release = obj.state.release;
			var links = release.links;

			var link = false;
			var found = false;

			for (var j = 0; j < links.length; j++) {
				if (found) break;
				link = links[j];
				var url = link.url;				
				for (var i = 0; i < odesliPlatforms.length; i++) {
					var platform = odesliPlatforms[i];
					if (url.includes(platform)) {
						found = true;	
						obj.setState({autodetecting: true});
						break;				
					}
				}
			}
			if (found) {
				Ajax.getOdesliLinks(url).then((odesli) => {
					if (odesli) {
						var linksByPlatform = odesli.linksByPlatform;
						for (var key in linksByPlatform) {
							// check if the property/key is defined in the object itself, not in parent
							if (linksByPlatform.hasOwnProperty(key)) {           
								var linkByPlatform = linksByPlatform[key];
								links = obj.pushLink(linkByPlatform.url, platforms, links);
							}
						}
						for (var i = 0; i < links.length; i++) {
							links[i].id = i+1;
						}
						release.links = links;
						obj.setState({release: release, saved: false, usedOdesli: true, autodetecting: false}, obj.save);
					} else {
						obj.setState({autodetecting: false});
					}
				});
			}
		}		
	}
	
	addLink(event) {
		var url = this.state.linkInput;
		var release = this.state.release;
		var platforms = Object.keys(this.platforms);
		var links = release.links;
		links = this.pushLink(url, platforms, links);
		release.links = links;
		this.setState({release: release, linkInput: "", saved: false}, this.save);
	}
	
	updateLinkUrl(idx) {
		var obj = this;		
		return function(event) {
			var release = obj.state.release;
			var val = event.target.value;
			var link = release.links[idx];
			link.url = val;
			release.links[idx] = link;
			obj.setState({release: release, saved: false});
		};
	}''

	updateLinkPlatform(idx) {
		var obj = this;		
		return function(event) {
			var release = obj.state.release;
			var val = event.target.value;
			var link = release.links[idx];
			link.platform = val;
			release.links[idx] = link;
			obj.setState({release: release, saved: false});
		};
	}

	updateLinkAction(idx) {
		var obj = this;		
		return function(event) {
			var release = obj.state.release;
			var val = event.target.value;
			var link = release.links[idx];
			link.action = val;
			release.links[idx] = link;
			obj.setState({release: release, saved: false});
		};
	}
	
	removeLink(iconName) {
		var obj = this;
		return function() {
			var release = obj.state.release;
			var links = release.links;
			var idx = false;
			for (var j = 0; j < links.length; j++) {
				var link = links[j];
				if (link.icon === iconName) {
					idx = j;
					break;
				}
			}
			links.splice(idx, 1);
			release.links = links;
			obj.setState({release: release, saved: false}, obj.save);
		};
	}

	updateNewsletter(release) {
		var artists = "";
		for (var idx = 0; idx < release.artists.length; idx++) {
			var artist = release.artists[idx].text;
			artists += artist;
			if (idx < release.artists.length - 1) {
				artists += ", ";
			}
		}
		var genres = "";
		for (var idx = 0; idx < release.genres.length; idx++) {
			var genre = release.genres[idx].text;
			genres += genre;
			if (idx < release.genres.length - 1) {
				genres += ", ";
			}
		}
		var newsletterLinks = [];
		for (var idx = 0; idx < release.links.length; idx++) {
			var link = release.links[idx];
			var platform = link.platform;
			var action = link.action;
			var icon = link.icon;
			var url = platform.replace(/ /g,'-') + "/" + release._id;
			var img = link.img;
			var customPlatform = "";
			if (icon === "shopping-cart") {
				customPlatform = platform;
			}
			var newsletterLink = {url: url, action: action, img: img, platform: platform, customPlatform: customPlatform};
			newsletterLinks.push(newsletterLink);
		}

		for (var i = 0; i < release.newsletter.length; i++) {
			var part = release.newsletter[i];
			if (part.variables.fontColor === undefined) {
				if (part.type === "catalogNumber" || part.type === "tags") {
					part.variables.fontColor = "#999999";
				} else {
					part.variables.fontColor = "#eeeeee";
				}				
			}
			if (part.variables.backgroundColor === undefined) {
				if (part.type === "checkItOut") {
					part.variables.backgroundColor = "#ffa500";
				} else {
					part.variables.backgroundColor = "#111111";
				}
			}
			if (part.variables.fontSize === undefined) {
				if (part.type === "titleArtist" || part.type === "checkItOut") {
					part.variables.fontSize = "13pt";
				}
				else if (part.type === "platformLinks") {
					part.variables.fontSize = "9pt";
				} else {
					part.variables.fontSize = "10pt";
				}
			}
			if (part.variables.textAlign === undefined) {
				if (part.type === "description") {					
					part.variables.textAlign = "justify";
				}
				else if (part.type === "platformLinks") {
					part.variables.textAlign = "right";
				} else {
					part.variables.textAlign = "center";
				}
			}
			switch (part.type) {
				case "cover":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, cover: release.cover.small ? release.cover.small : release.cover.large, minipageLink: release._id};
					break;
				case "titleArtist":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, title: release.title, artist: artists};
					break;
				case "checkItOut":
					part.variables =  {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, minipageLink: release._id};
					break;
				case "platformLinks":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, links: newsletterLinks};
					break;
				case "description":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, description: release.description};
					break;
				case "releaseDate":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, releaseDate: release.releaseDate};
					break;
				case "tags":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, genres: genres};
					break;
				case "catalogNr":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, catalogNumber: release.catalogNumber};
					break;
				default: break;
			}
		}
	}

	updatePresskit(release) {
		var artists = "";
		for (var idx = 0; idx < release.artists.length; idx++) {
			var artist = release.artists[idx].text;
			artists += artist;
			if (idx < release.artists.length - 1) {
				artists += ", ";
			}
		}
		var genres = "";
		for (var idx = 0; idx < release.genres.length; idx++) {
			var genre = release.genres[idx].text;
			genres += genre;
			if (idx < release.genres.length - 1) {
				genres += ", ";
			}
		}
		var presskitLinks = [];
		for (var idx = 0; idx < release.links.length; idx++) {
			var link = release.links[idx];
			var platform = link.platform;
			var action = link.action;
			var icon = link.icon;
			var url = platform.replace(/ /g,'-') + "/" + release._id;
			var img = link.img;
			var customPlatform = "";
			if (icon === "shopping-cart") {
				customPlatform = platform;
			}
			var presskitLink = {url: url, action: action, img: img, platform: platform, customPlatform: customPlatform};
			presskitLinks.push(presskitLink);
		}

		for (var i = 0; i < release.presskit.length; i++) {
			var part = release.presskit[i];
			if (part.variables.fontColor === undefined) {
				if (part.type === "catalogNumber" || part.type === "tags") {
					part.variables.fontColor = "#999999";
				} else {
					part.variables.fontColor = "#eeeeee";
				}				
			}
			if (part.variables.backgroundColor === undefined) {
				if (part.type === "checkItOut" || part.type === "download" || part.type === "catalogLink") {
					part.variables.backgroundColor = "#ffa500";
				} else {
					part.variables.backgroundColor = "#111111";
				}
			}
			if (part.variables.fontSize === undefined) {
				if (part.type === "titleArtist" || part.type === "checkItOut" || part.type === "download") {
					part.variables.fontSize = "13pt";
				}
				else if (part.type === "platformLinks") {
					part.variables.fontSize = "9pt";
				} else {
					part.variables.fontSize = "10pt";
				}
			}
			if (part.variables.textAlign === undefined) {
				if (part.type === "description") {					
					part.variables.textAlign = "justify";
				}
				else if (part.type === "platformLinks") {
					part.variables.textAlign = "right";
				} else {
					part.variables.textAlign = "center";
				}
			}
			switch (part.type) {
				case "cover":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, cover: release.cover.small ? release.cover.small : release.cover.large, minipageLink: release._id};
					break;
				case "titleArtist":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, title: release.title, artist: artists};
					break;
				case "checkItOut":
					part.variables =  {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, minipageLink: release._id};
					break;
				case "download":
					part.variables =  {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, downloadLink: release._id};
					break;
				case "platformLinks":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, links: presskitLinks};
					break;
				case "description":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, description: release.description};
					break;
				case "releaseDate":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, releaseDate: release.releaseDate};
					break;
				case "catalogLink":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, catalogLink: release.profileId};
					break;
				case "contact":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, contactEmail: this.state.profile.email};
					break;
				case "tags":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, genres: genres};
					break;
				case "catalogNr":
					part.variables = {fontSize: part.variables.fontSize, textAlign: part.variables.textAlign, fontColor: part.variables.fontColor, backgroundColor: part.variables.backgroundColor, catalogNumber: release.catalogNumber};
					break;
				default: break;
			}
		}
	}
	
	save(callback) {
		if (! this.state.slugs) {
			return;
		}
		
		var release = this.state.release;		

		if ((! release.slug) || (release.slug === null) || (release.slug === undefined)) {
			// generate a slug for the release
			var slugs = this.state.slugs;
			var title = release.title;
			var slug = release._id;
			var slugCounter = 0;
			var slugOk = false;
			while ((! slugOk) && (title.length > 0)) {
				slug = removeDiacritics(title).replace(/\s+/g, "-").replace(/&/g, "and").replace(/\?/g, "").replace(/\//g, "-").replace(/\\/g, "-").replace(/:/g, "-").replace(/\(/g, "").replace(/\)/g, "").toLowerCase();
				if (slugCounter > 0) {
					slug = slug + "-" + slugCounter;
				}
				if (slugs[slug] !== undefined) {
					var id = slugs[slug];
					if (release._id === id || ! id) {
						slugOk = true;
					} else {
						slugCounter++;
						continue;
					}
				} else {
					slugs[slug] = release._id;
					slugOk = true;
				}
			}
			release.slug = slug;
		}
		var obj = this;

		this.updateNewsletter(release);
		this.updatePresskit(release);

		// save to the database
		if ((! obj.state.saved) && (! obj.state.saving)) {
			obj.setState({showSaving: true, saving: true}, () => {
				Ajax.saveRelease(release).then(function(updatedRelease) {
					if (updatedRelease._id !== undefined) {
						release._id = updatedRelease._id;						
						obj.setState({release: release});
					}
					obj.setState({saving: false, saved: true}, () => {
						setTimeout(() => {
							obj.setState({showSaving: false});
						}, 3000);
					});
					if ((callback !== undefined) && (typeof(callback) === "function")) {
						try {
							callback(updatedRelease._id);
						} catch (error) {

						}						
					}					
				});
			});			
		}		
	}
	
	saveDraft() {
		var release = this.state.release;
		release.status = DRAFT;
		this.setState({release: release, saved: false}, this.save);
	}
	
	saveActive() {
		var release = this.state.release;
		release.status = ACTIVE;
		this.setState({release: release, saved: false}, this.save);
	}
	
	previewNewsletter() {		
		this.setState({showNewsletterPreview: true});
	}
	
	previewPresskit() {
		this.setState({showPresskitPreview: true});
	}
	
	previewMinipage() {
		this.setState({showMinipagePreview: true});
	}

	refreshRelease(release) {
		this.setState({release: release, saved: false}, this.save);
	}
	
	render() {
		var obj = this;
		var release = this.state.release;
		var artistFormatter = new ArtistFormatter(release.artist);
		var artists = artistFormatter.format();
		var cover = false;
		if (release.cover.large) {
			cover = Ajax.getFile(release.cover.large);
		}
        if (release.cover.small) {
            cover = Ajax.getFile(release.cover.small);
        }
		return (
		<div className="EditRelease">				

			<div className="DashboardComponentHeader">
				<div className="DashboardComponentTitle"><FontAwesome name="edit" /> Release Details</div>
				<div className="DashboardComponentMenu">
					{ release.status === ACTIVE ? (<div className={ obj.state.saved ? "DashboardComponentMenuItem":"DashboardComponentMenuItem Unsaved" } onClick={this.saveActive} ><FontAwesome name="save" /> Save</div>) : (<div className={ obj.state.saved && release.status !== AUTOSAVE ? "DashboardComponentMenuItem":"DashboardComponentMenuItem Unsaved" } onClick={this.saveDraft} ><FontAwesome name="save" /> Save</div>) }
					{ release.status === ACTIVE ? (<div className="DashboardComponentMenuItem" onClick={this.saveDraft} ><FontAwesome name="unlink" /> Draft</div>) : (<div className="DashboardComponentMenuItem" onClick={this.saveActive} ><FontAwesome name="share" /> Publish</div>) }
					{ release.status === ACTIVE ? (<div className="DashboardComponentMenuItem Spaced" onClick={this.previewPresskit} >Presskit</div>) : 
						(<div className="DashboardComponentMenuItemInactive Spaced" data-for="menu-01" data-tip="Publish release to edit and send presskit."><span>Presskit</span><ReactTooltip id="menu-01" place="bottom" className="Tooltip" delayShow={500} /></div>) }
					{ release.status === ACTIVE ? (<div className="DashboardComponentMenuItem Spaced" onClick={this.previewNewsletter} >Newsletter</div>) : 
						(<div className="DashboardComponentMenuItemInactive Spaced" data-for="menu-02" data-tip="Publish release to edit and send newsletter."><span>Newsletter</span><ReactTooltip id="menu-02" place="bottom" className="Tooltip" delayShow={500} /></div>) }
					{ release.status === ACTIVE ? (<div className="DashboardComponentMenuItem" onClick={this.previewMinipage} >Mini-Page</div>) : 
						(<div className="DashboardComponentMenuItemInactive" data-for="menu-03" data-tip="Publish release to edit mini page."><span>Mini-Page</span><ReactTooltip id="menu-03" place="bottom" className="Tooltip" delayShow={500} /></div>) }

					<CSSTransitionGroup transitionName="saving" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
						{ this.state.showSaving ? (<div className={ "DashboardComponentMenuInfo" + (this.state.saving ? " Saving" : " Saved") } >{ this.state.saving ? " Saving..." : (<span>Saved <FontAwesome name="check" /></span>) }</div>) : null }						
		 			</CSSTransitionGroup>										
				</div>
			</div>
			
			<div className="DashboardComponentSection">
				<div className="DashboardComponentSectionTitle">Album Cover</div>
				<div className="EditReleaseCover">
					<input ref={(ref) => this.coverUploader = ref} type="file" name="cover" id="cover" accept="image/jpeg" onChange={this.uploadCover}/>
					{ release.cover.large ? (
						<div className="EditReleaseCoverImage" onClick={() => { obj.coverUploader.click() } }>
							<img src={cover} alt="cover art" />
							<div className="EditReleaseCoverImageChange">Click to change<br/>{obj.state.coverError}</div>
						</div>
					) : (
						<div className="EditReleaseCoverImage" onClick={() => { obj.coverUploader.click() } }>
							<FontAwesome name="image" />
							<div className="EditReleaseCoverImageText">Image must be at least 1500x1500 pixels and JPEG format</div>
							<div className="EditReleaseCoverImageChange">Click to upload<br/>{obj.state.coverError}</div>
						</div>
					)					
					}
					{
					obj.state.uploadingCover ? 
					(<div className="CoverProgressBar"><div className="CoverProgress" style={{width: obj.state.coverProgress + "%"}}></div></div>) : 
					null
					}
				</div>
			</div>
			
			<div className="DashboardComponentSection">
				<div className="DashboardComponentSectionTitle">Album Details</div>
				<div className="EditReleaseMeta">				
					<div className="EditReleaseMetaTitle EditReleaseMetaField">
						<div className="EditReleaseMetaLabel">
							<div className="EditReleaseMetaLabelText">Album Title</div> 
							<div className="EditReleaseMetaLabelInfo"></div>
						</div>
						
						<div className="EditReleaseMetaFormInput"><input type="text" placeholder="Enter title here" id="title" onBlur={this.save} onChange={this.setReleaseValue("title")} value={release.title} /></div>
					</div>
					<div className="EditReleaseMetaArtists EditReleaseMetaField">
						<div className="EditReleaseMetaLabel">
							<div className="EditReleaseMetaLabelText">Artist/Band name</div> 
							<div className="EditReleaseMetaLabelInfo"></div>
						</div>
						<div className="EditReleaseMetaFormInput">
							<ReactTags tags={release.artists}									
								handleDelete={this.handleDeleteArtist}
								handleAddition={this.handleAddArtist}
								handleInputBlur={(value) => { if ((value !== undefined) && (value !== "")) obj.handleAddArtist({id: value, text: value}); obj.save(); }}
								allowDragDrop={false}
								placeholder="Add Artist"
								inline
							/>
						</div>
					</div>
					<div className="EditReleaseMetaDate EditReleaseMetaField">
						<div className="EditReleaseMetaLabel">
							<div className="EditReleaseMetaLabelText">Presskit Date</div> 
							<div className="EditReleaseMetaLabelInfo"><i className="far fa-question-circle" data-for="ml-04" data-tip="The date at which to send out e-mails to your pre-release mailing list"></i></div>
							<ReactTooltip id="ml-04" className="Tooltip" delayShow={500} />
						</div>
						<div className="EditReleaseMetaFormInput"><DatePicker selected={typeof(release.prereleaseDate) === "Date" ? release.prereleaseDate : Date.parse(release.prereleaseDate)} onBlur={this.save} onChange={this.setPrereleaseDate} /></div>
					</div>
					<div className="EditReleaseMetaDate EditReleaseMetaField">
						<div className="EditReleaseMetaLabel">
							<div className="EditReleaseMetaLabelText">Release Date</div> 
							<div className="EditReleaseMetaLabelInfo"><i className="far fa-question-circle" data-for="ml-05" data-tip="The date at which to send out e-mails to your pre-release mailing list"></i></div>
							<ReactTooltip id="ml-05" className="Tooltip" delayShow={500} />
						</div>
						<div className="EditReleaseMetaFormInput"><DatePicker selected={typeof(release.releaseDate) === "Date" ? release.releaseDate : Date.parse(release.releaseDate)} onBlur={this.save} onChange={this.setReleaseDate} /></div>
					</div>
					<div className="EditReleaseMetaDescription EditReleaseMetaField">
						<div className="EditReleaseMetaLabel">Description</div>
						<div className="EditReleaseMetaFormInput"><textarea placeholder="Enter description here" id="description" onBlur={this.save} onChange={this.setReleaseValue("description")} value={release.description} resize="false" /></div>
					</div>
					<div className="EditReleaseMetaArtists EditReleaseMetaField">
						<div className="EditReleaseMetaLabel">
							<div className="EditReleaseMetaLabelText">Genre</div> 
							<div className="EditReleaseMetaLabelInfo"></div>
						</div>
						<div className="EditReleaseMetaFormInput">
							<ReactTags tags={release.genres}									
								handleDelete={this.handleDeleteGenre}
								handleAddition={this.handleAddGenre}
								handleInputBlur={(value) => { if ((value !== undefined) && (value !== "")) obj.handleAddGenre({id: value, text: value}); obj.save(); }}
								allowDragDrop={false}
								placeholder="Add Genre"
								suggestions={genres}
								inline
							/>
						</div>
					</div>
					<div className="EditReleaseMetaCatalogNumber EditReleaseMetaField">
						<div className="EditReleaseMetaLabel">Catalog Number (Optional)</div>
						<div className="EditReleaseMetaFormInput"><input type="text" placeholder="Enter catalog number here" id="catalogNumber" onBlur={this.save} onChange={this.setReleaseValue("catalogNumber")} value={release.catalogNumber} /></div>
					</div>
					<div className="EditReleaseMetaCatalogNumber EditReleaseMetaField">
						<div className="EditReleaseMetaLabel">UPC/EAN (Optional)</div>
						<div className="EditReleaseMetaFormInput"><input type="text" placeholder="Enter UPC/EAN here" id="upc" onBlur={this.save} onChange={this.setReleaseValue("upc")} value={release.upc} /></div>
					</div>					
				</div>
			</div>
			
			<div className="DashboardComponentSection">
				<div className="DashboardComponentSectionTitle">Links (Spotify, iTunes, ...)</div>
				<div className="EditReleaseLinks">									
					<div className="EditReleaseLinkHeaders">
						<div className="EditReleaseLinkHeader EditReleaseLinkHeaderPlatform">Platform</div>
						<div className="EditReleaseLinkHeader EditReleaseLinkHeaderURL">URL</div>
						<div className="EditReleaseLinkHeader EditReleaseLinkHeaderAction">Link Text</div>
						<div className="EditReleaseLinkHeader EditReleaseLinkHeaderTest">Check</div>
					</div>
					<div className="EditReleaseLinksList" ref={this.dragulaDecoratorLinks}>
					{ release.links.map(function(platform, idx) {							
							return (
							<div key={platform.id} id={"link-" + idx} className="EditReleaseLink">
								<div className="EditReleaseLinkHandle"><FontAwesome name="ellipsis-v" /><FontAwesome name="ellipsis-v" /><FontAwesome name="ellipsis-v" /></div>
								{platform.icon === "shopping-cart" ? (
								<div className="EditReleaseLinkPlatform">
									<div className="EditReleaseLinkPlatformIcon">{ platform.icon.includes(".png") ? (<img src={"/" + platform.icon} alt="icon"/>) : (<FontAwesome name={platform.icon} />)}</div>
									<div className="EditReleaseLinkPlatformLabel">
										<input type="text" value={platform.platform} onChange={obj.updateLinkPlatform(idx)} onBlur={obj.save}/>
									</div>
								</div>
								) : (
								<div className="EditReleaseLinkPlatform">
									<div className="EditReleaseLinkPlatformIcon">{ platform.icon.includes(".png") ? (<img src={"/" + platform.icon} alt="icon"/>) : (<i className={"fab fa-" + platform.icon}></i>)}</div>
									<div className="EditReleaseLinkPlatformLabel">{platform.platform}</div>
								</div>
								) }
								<div className="EditReleaseLinkUrl"><input type="text" value={platform.url} onChange={obj.updateLinkUrl(idx)} onBlur={obj.save}/></div>
								<div className="EditReleaseLinkAction"><input type="text" value={platform.action} onChange={obj.updateLinkAction(idx)} onBlur={obj.save}/></div>
								<div className="EditReleaseLinkTest" onClick={() => { obj["checkLink_" + platform.icon].click() } }><a ref={(ref) => obj["checkLink_" + platform.icon] = ref} href={platform.url} target="_blank">Check</a></div>
								<div className="LinkRemove" onClick={obj.removeLink(platform.icon)}><i className="fas fa-minus-circle"></i></div>
							</div>
							);
						})
					}					
					</div>
					{obj.state.autodetecting ? (<div className="LoaderWrapper"><div className="ReleaseAutoDetectLoader"/></div>) : null }
					<div className="EditReleaseAutodetect">			
					{!obj.canUseOdesli() || obj.state.autodetecting || obj.state.usedOdesli ? 
					(<div data-for="odesli" data-tip="Requires at least one manually entered link from one of the major online platforms." className= "Button Inactive">Autodetect</div>) : 
					(<div data-for="odesli" data-tip="Requires at least one manually entered link from one of the major online platforms." className= "Button" onClick={this.autodetect()}>Autodetect</div>) }
						<ReactTooltip id="odesli" className="Tooltip" delayShow={500} />
						<div className="EditReleaseAutodetectPoweredBy">Powered by <a href="https://odesli.co/" target="_blank">Odesli</a></div>
					</div>
					<div className="EditReleaseAddLink">
						<input type="text" placeholder="Enter link here" id="link" value={this.state.linkInput} onChange={this.setLinkValue} />
						<div className="Button" onClick={this.addLink}>Add</div>						
					</div>
				</div>
			</div>		
			
			<div className="DashboardComponentSection">
				<div className="DashboardComponentSectionTitle">Tracks</div>
				<div className="EditReleaseTracks">
					<div className="EditReleaseTrackListHeaders">
						<div className="EditReleaseTrackHandle Header"></div>
						<div className="EditReleaseTrackNumber Header">Nr</div>
						<div className="EditReleaseTrackFilename Header">Filename</div>						
						<div className="EditReleaseTrackName Header">Title</div>
						<div className="EditReleaseTrackArtists Header">Artist</div>											
					</div>
					<div className="EditReleaseTrackList" ref={this.dragulaDecorator}>
						{ release.tracks.map(function(track, idx) {
								return (
									<div key={track.id} id={idx} className="EditReleaseTrack">
										<div className="EditReleaseTrackHandle"><FontAwesome name="ellipsis-v" /><FontAwesome name="ellipsis-v" /><FontAwesome name="ellipsis-v" /></div>
										<div className="EditReleaseTrackNumber">{track.trackNumber}</div>
										<div className="EditReleaseTrackFilename">{track.originalFileName}</div>
										<div className="EditReleaseTrackName"><input type="text" onBlur={obj.save} onChange={obj.handleUpdate(idx, "title")} value={track.title} name="title" placeholder="Enter track title here"/></div>
										{!isUndefined(track.artist) && track.artist && track.artist.length > 0 ? (
											<div className="EditReleaseTrackArtists"><input type="text" onBlur={obj.save} onChange={obj.handleUpdate(idx, "artist")} value={track.artist} name="artist" placeholder="Enter track artist here"/></div>
										) : (
											<div className="EditReleaseTrackArtists"><input type="text" onBlur={obj.save} onChange={obj.handleUpdate(idx, "artist")} value={artists} name="artist" placeholder="Enter track artist here"/></div>
										)}
											{track.showDetails ? (
										<div className="EditReleaseTrackDetails">
											<div className="EditReleaseTrackDetail">
												<div className="EditReleaseTrackDetailTitle">Include in Presskit</div>
												<div className="EditReleaseTrackDetailValue"><input type="checkbox" onChange={obj.handleToggle(idx, "demolisten")} name="demolisten" checked={track.demolisten}/></div>
											</div>
											<div className="EditReleaseTrackDetail">
												<div className="EditReleaseTrackDetailTitle">Include in Minipage</div>
												<div className="EditReleaseTrackDetailValue"><input type="checkbox" onChange={obj.handleToggle(idx, "listen")} name="listen" checked={track.listen}/></div>
											</div>
											<div className="EditReleaseTrackDetail">
												<div className="EditReleaseTrackDetailTitle">ISRC (Optional)</div>
												<div className="EditReleaseTrackDetailValue"><input type="text" onBlur={obj.save} onChange={obj.handleUpdate(idx, "isrc")} value={track.isrc} name="isrc" placeholder="Enter ISRC"/></div>
											</div>
											<div className="EditReleaseTrackDetail">
												<div className="EditReleaseTrackDetailTitle">Cover</div>
												<div className="EditReleaseTrackDetailValue"><input type="checkbox" onChange={obj.handleToggle(idx, "cover")} name="cover" checked={track.cover}/></div>
											</div>
											{ track.cover ? (
											<div className="EditReleaseTrackDetail">
												<div className="EditReleaseTrackDetailTitle">Original Artist</div>
												<div className="EditReleaseTrackDetailValue"><input type="text" onBlur={obj.save} onChange={obj.handleUpdate(idx, "originalArtist")} value={track.originalArtist} name="original artist" placeholder="Enter Original Artist"/></div>
											</div>) : null }
										</div>) : null }
										
										{ track.showDetails ? 
										(<div className="TrackShowDetails" onClick={obj.handleToggle(idx, "showDetails")}><i className="fas fa-chevron-circle-up"></i></div>) : 
										(<div className="TrackShowDetails" onClick={obj.handleToggle(idx, "showDetails")}><i className="fas fa-chevron-circle-down"></i></div>)
										}
										<div className="TrackRemove" onClick={obj.removeTrack(idx)}><i className="fas fa-minus-circle"></i></div>
									</div>
								);
							})
						}
					</div>
				</div>
				
				<div className="EditReleaseTracksUpload">					
					<input ref={(ref) => this.fileUploader = ref} type="file" name="tracks" id="tracks" accept="audio/*" multiple onChange={this.uploadFiles}/>						
					{ this.state.queue.length > 0 ? (
						<div className="EditReleaseTracksUploadPreview">
							<div className="ProgressBar"><div className="Progress" style={{width: this.state.progress + "%"}}></div></div>
							{ this.state.queue.map(function (track, idx) {
								return (
								<div key={"upload" + idx} className="EditReleaseTrack">
									<div className="EditReleaseTrackHandle"></div>
									<div className="EditReleaseTrackNumber"></div>
									<div className="EditReleaseTrackFilename">{track.name}</div>
									<div className="EditReleaseTrackName">{idx < 1 ? ((obj.state.progress / 100) * (track.size / 1000000)).toFixed(2) + " / " + (track.size / 1000000).toFixed(2) + "MB" : "0 / " + (track.size / 1000000).toFixed(2) + "MB"}</div>
									<div className="EditReleaseTrackArtists"></div>
								</div>);
							})
						}					
						
						</div>) : null 
					}						
				</div>
				<div className="Button" id="ButtonUploadTracks" onClick={() => { this.fileUploader.click() } }>Upload <FontAwesome name="upload" /></div>
			</div>
			{this.state.showNewsletterPreview ? (<NewsletterPreview release={release} refreshRelease={obj.refreshRelease} updateNewsletter={obj.updateNewsletter} close={() => {obj.setState({showNewsletterPreview: false}); }}/>) : null }
			{this.state.showPresskitPreview ? (<PresskitPreview release={release} refreshRelease={obj.refreshRelease} updatePresskit={obj.updatePresskit} close={() => {obj.setState({showPresskitPreview: false}); }}/>) : null }
			{this.state.showMinipagePreview ? (<MinipagePreview release={release} slugs={obj.state.slugs} refreshRelease={obj.refreshRelease} close={() => {obj.setState({showMinipagePreview: false}); }}/>) : null }
		</div>
		);
	}
}

export { Release };