import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax.js';
import './DefaultWidget.css';

class DefaultWidget extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
            loading: false,
            values: {},
            profileId: this.props.profileId
        };
        this.subscribe = this.subscribe.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
	}

	componentWillMount() {
		var obj = this;
		Ajax.getPublicProfile(obj.state.profileId).then((profile) => {
            obj.setState({profile: profile, loading: false});
		});
    }

    handleUpdate(event) {		
		var values = this.state.values;
		values[event.target.id] = event.target.value;
		this.setState({values: values});
	}
	
    
    subscribe() {
        var obj = this;
        
        return () => {
            var profileId = obj.state.profile._id;
            var name = obj.state.values.name;
            var email = obj.state.values.email;
            console.log(name);
            console.log(email);
            Ajax.subscribe(profileId, {name: name, email: email}).then((result) => {
                console.log("what?");
                console.log(result);
            });
        };
    }
	
	render() {
		return (
			<div className="DefaultWidget">
                <div className="SubscribeInput">
                    <div className="InputLabel">Name</div>
                    <input type="text" placeholder="Name" id="name" onChange={this.handleUpdate} />
                </div>
                <div className="SubscribeInput">
                    <div className="InputLabel">E-mail</div>
                    <input type="email" placeholder="E-mail" id="email" onChange={this.handleUpdate} />
                </div>
                <div className="SubscribeButton" onClick={this.subscribe()}>Subscribe</div>
            </div>
		);
	}
}

export { DefaultWidget };