import cookie from 'react-cookies';
import { config } from '../config/config.js';
import { isUndefined } from 'util';

class Cookies {
	
	addDays(date, amount) {
		var t = date.getTime();
		t += (1000 * 60 * 60 * 24) * amount;
		date.setTime(t);
	}

	isAuthenticated() {
		var session = cookie.load(config.cookies.sessionCookieName);
		return (! isUndefined(session));
	}

	removeSessionCookie() {
		cookie.remove(config.cookies.sessionCookieName);
		cookie.remove(config.cookies.nameCookieName);
	}
	
	minipageOpened(releaseId) {
		cookie.save(config.cookies.minipageVisitedCookieName(releaseId), true);
	}

	wasMinipageOpened(releaseId) {
		var opened = cookie.load(config.cookies.minipageVisitedCookieName(releaseId));
		return (! isUndefined(opened));
	}

	getName() {
		var name = cookie.load(config.cookies.nameCookieName);
		if (! isUndefined(name)) {
			return name;
		} else {
			return false;
		}
	}

	setName(name) {
		cookie.save(config.cookies.nameCookieName, name);
	}

}

var cookies = new Cookies();

export { cookies };