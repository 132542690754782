import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax.js';
import FontAwesome from 'react-fontawesome';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from '../common/Loading.js';

import './Profile.css';
import { isUndefined } from 'util';

const removeDiacritics = require('diacritics').remove;

class Profile extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			profile: false,
			loading: true,
			slugs: {}
		}
		this.href = this.href.bind(this);
		this.uploadLogo = this.uploadLogo.bind(this);
		this.save = this.save.bind(this);
		this.setProfileValue = this.setProfileValue.bind(this);
		this.setProfileSocial = this.setProfileSocial.bind(this);
		this.fillProfile = this.fillProfile.bind(this);
	}

	fillProfile(profile) {
		if (isUndefined(profile.name)) profile.name = "";
		if (isUndefined(profile.logo) || (profile.logo === null)) profile.logo = "";
		if (isUndefined(profile.about) || (profile.about === null)) profile.about = "";
		if (isUndefined(profile.website) || (profile.website === null)) profile.website = "";
		if (isUndefined(profile.socials) || (profile.socials === null)) profile.socials = {};
		if (isUndefined(profile.socials.facebook) || (profile.socials.facebook === null)) profile.socials.facebook = "";
		if (isUndefined(profile.socials.twitter) || (profile.socials.twitter === null)) profile.socials.twitter = "";
		if (isUndefined(profile.socials.instagram) || (profile.socials.instagram === null)) profile.socials.instagram = "";
		if (isUndefined(profile.socials.soundcloud) || (profile.socials.soundcloud === null)) profile.socials.soundcloud = "";
		if (isUndefined(profile.socials.bandcamp) || (profile.socials.bandcamp === null)) profile.socials.bandcamp = "";
		if (isUndefined(profile.socials.spotify) || (profile.socials.spotify === null)) profile.socials.spotify = "";
		if (isUndefined(profile.socials.youtube) || (profile.socials.youtube === null)) profile.socials.youtube = "";
		if ((profile.catelog === undefined) || (profile.catalog === null ) || (! profile.catalog)) profile.catalog = {};
		if ((profile.linkpage === undefined) || (profile.linkpage === null ) || (! profile.linkpage)) profile.linkpage = {};
		return profile;
	}

	href(url) {
		return () => {
			window.location.href = url;
		}
	}

	componentWillMount() {
		var obj = this;
		Ajax.getProfile().then((profile) => {
			if (profile.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});
			} else {
				profile = obj.fillProfile(profile);
				Ajax.getProfileSlugs().then((slugs) => {
					obj.setState({profile: profile, loading: false, slugs: slugs});
				});				
			}
		});
	}

	uploadLogo(event) {
		var obj = this;		
		var file = event.target.files[0];
		Ajax.upload(file, () => {}).then((data) => {
			var profile = obj.state.profile;
			var logoId = data.file;
			profile.logo = logoId;
			obj.setState({profile: profile}, obj.save);
		});
	}

	setProfileValue(field) {
		var obj = this;
		return function(event) {
			var val = event.target.value;
			var profile = obj.state.profile;
			profile[field] = val;
			obj.setState({ profile: profile });
		}
	}

	setProfileSocial(field) {
		var obj = this;
		return function(event) {
			var val = event.target.value;
			var profile = obj.state.profile;
			profile.socials[field] = val;
			obj.setState({ profile: profile });
		}
	}

	save() {
		var profile = this.state.profile;
		var slugs = this.state.slugs;
		var name = profile.name;
		var slug = removeDiacritics(name).replace(/\s+/g, "-").replace(/&/g, "and").replace(/\?/g, "").replace(/\//g, "-").replace(/:/g, "-").toLowerCase();
		var slugOk = false;
		var slugCounter = 0;
		while(! slugOk) {
			if (slugCounter > 0) {
				slug = slug + "-" + slugCounter;
			}
			if (! isUndefined(slugs[slug])) {
				var id = slugs[slug];
				if (profile._id === id || ! id) {
					slugOk = true;
				} else {
					slugCounter++;
					continue;
				}
			} else {
				slugs[slug] = profile._id;
				slugOk = true;
			}
		}		
		profile.slug = slug;
		Ajax.updateProfile(profile).then(() => {
			
		});
	}
	
	render() {
		var obj = this;
		var profile = this.state.profile;
		if (profile) {
			return (
				<DashboardComponent>
					<div className="Profile">
						<div className="DashboardComponentHeader">
							<div className="DashboardComponentTitle"><FontAwesome name="user-circle" /> Edit Profile</div>
							<div className="DashboardComponentMenu">
							</div>
						</div>
	
						<div className="DashboardComponentSection">
							<div className="DashboardComponentSectionTitle">Logo</div>
							<div className="ProfileLogo">
								<input ref={(ref) => this.logoUploader = ref} type="file" name="cover" id="cover" accept="image/jpeg" onChange={this.uploadLogo}/>
								{ profile.logo ? (
									<div className="ProfileLogoImage" onClick={() => { obj.logoUploader.click() } }>
										<img src={Ajax.getFile(profile.logo)} alt="logo" />
										<div className="ProfileLogoImageChange">Click to change</div>
									</div>
								) : (
									<div className="ProfileLogoImage" onClick={() => { obj.logoUploader.click() } }>
										<FontAwesome name="image" />
										<div className="ProfileLogoImageChange">Click to upload</div>
									</div>
								)					
								}
							</div>
						</div>
						
						<div className="DashboardComponentSection">
							<div className="DashboardComponentSectionTitle">Details</div>
							<div className="ProfileMeta">				
								<div className="ProfileField">
									<div className="ProfileLabel">
										<div className="ProfileLabelText">Artist/Label Name</div> 
										<div className="ProfileLabelInfo"></div>
									</div>								
									<div className="ProfileInput"><input type="text" placeholder="Enter name here" id="name" onBlur={this.save} onChange={this.setProfileValue("name")} value={profile.name} /></div>
								</div>														
								<div className="ProfileDescription ProfileField">
									<div className="ProfileLabel">About</div>
									<div className="ProfileInput"><textarea placeholder="Enter description here" id="about" onBlur={this.save} onChange={this.setProfileValue("about")} value={profile.about} resize="false" /></div>
								</div>
								<div className="ProfileField">
									<div className="ProfileLabel">
										<div className="ProfileLabelText">Website</div> 
										<div className="ProfileLabelInfo"></div>
									</div>								
									<div className="ProfileInput"><input type="text" placeholder="Enter url here" id="website" onBlur={this.save} onChange={this.setProfileValue("website")} value={profile.website} /></div>
								</div>	
	
								<div className="ProfileField">
									<div className="ProfileLabel"><i className="fab fa-facebook-f" />Facebook</div>								
									<div className="ProfileInput"><input type="text" placeholder="Enter facebook url here" id="website" onBlur={this.save} onChange={this.setProfileSocial("facebook")} value={profile.socials.facebook} /></div>
								</div>
	
								<div className="ProfileField">
									<div className="ProfileLabel"><i className="fab fa-twitter" />Twitter</div>								
									<div className="ProfileInput"><input type="text" placeholder="Enter twitter url here" id="twitter" onBlur={this.save} onChange={this.setProfileSocial("twitter")} value={profile.socials.twitter} /></div>
								</div>
	
								<div className="ProfileField">
									<div className="ProfileLabel"><i className="fab fa-instagram" />Instagram</div>								
									<div className="ProfileInput"><input type="text" placeholder="Enter instagram url here" id="instagram" onBlur={this.save} onChange={this.setProfileSocial("instagram")} value={profile.socials.instagram} /></div>
								</div>
	
								<div className="ProfileField">
									<div className="ProfileLabel"><i className="fab fa-soundcloud" />Soundcloud</div>								
									<div className="ProfileInput"><input type="text" placeholder="Enter soundcloud url here" id="soundcloud" onBlur={this.save} onChange={this.setProfileSocial("soundcloud")} value={profile.socials.soundcloud} /></div>
								</div>
	
								<div className="ProfileField">
									<div className="ProfileLabel"><i className="fab fa-bandcamp" />Bandcamp</div>								
									<div className="ProfileInput"><input type="text" placeholder="Enter bandcamp url here" id="bandcamp" onBlur={this.save} onChange={this.setProfileSocial("bandcamp")} value={profile.socials.bandcamp} /></div>
								</div>
	
								<div className="ProfileField">
									<div className="ProfileLabel"><i className="fab fa-spotify" />Spotify</div>								
									<div className="ProfileInput"><input type="text" placeholder="Enter spotify url here" id="spotify" onBlur={this.save} onChange={this.setProfileSocial("spotify")} value={profile.socials.spotify} /></div>
								</div>			
								<div className="ProfileField">
									<div className="ProfileLabel"><i className="fab fa-youtube" />Youtube</div>								
									<div className="ProfileInput"><input type="text" placeholder="Enter youtube url here" id="youtube" onBlur={this.save} onChange={this.setProfileSocial("youtube")} value={profile.socials.youtube} /></div>
								</div>			
							</div>
						</div>
					</div>
					{ this.state.loading ? (<Loading />) : null }
				</DashboardComponent>
			);
		} else {
			return (
			<DashboardComponent>
			{ this.state.loading ? (<Loading />) : null }
			</DashboardComponent>			
			);
		}
		
	}
}

export { Profile };