import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';

import { Ajax } from '../../ajax/Ajax.js';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { ReleaseList } from './ReleaseList.js';
import { Loading } from '../common/Loading.js';

import './Releases.css';

const AUTOSAVE = 0;
const DRAFT = 1;
const ACTIVE = 2;

class Releases extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			active: [],
			drafts: [],
			autosaved: [],
			loading: true,
			count: false
		}
		this.href = this.href.bind(this);
		this.activate = this.activate.bind(this);
		this.deactivate = this.deactivate.bind(this);
	}

	componentWillMount() {
		var obj = this;
		Ajax.getReleases().then(function(releases) {
			if (releases.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});
			} else {
				var active = [];
				var drafts = [];
				var autosaved = [];
				for (var i = 0; i < releases.length; i++) {
					var release = releases[i];
					switch (release.status) {
						case AUTOSAVE:
							autosaved.push(release);
							break;
						case DRAFT:
							drafts.push(release);
							break;
						case ACTIVE:
							active.push(release);
							break;
						default:
							break;
					}
				}
				obj.setState({active: active, drafts: drafts, autosaved: autosaved, loading: false, count: releases.length});
			}
		});
	}

	href(path) {
		return function() {
			window.location.href = path;
		};
	}

	activate(release, idx) {
		var obj = this;
		return () => {
			var inactives = obj.state.drafts;
			inactives.splice(idx, 1);
			release.status = ACTIVE;
			var actives = obj.state.active;
			actives.push(release);
			obj.setState({active: actives, drafts: inactives});
			Ajax.saveRelease(release);
		};
	}

	deactivate(release, idx) {
		var obj = this;
		return () => {
			var actives = obj.state.active;
			actives.splice(idx, 1);
			var inactives = obj.state.drafts;
			release.status = DRAFT;
			inactives.push(release);
			obj.setState({active: actives, drafts: inactives});
			Ajax.saveRelease(release);
		};
	}
	
	render() {
		return (
			<DashboardComponent>
				<div className="Releases">
					<div className="DashboardComponentHeader">
						<div className="DashboardComponentTitle"><FontAwesome name="music" /> Releases</div>
						<div className="DashboardComponentMenu">
							{ this.state.active.length > 0 ? (<div className="DashboardComponentMenuItem" onClick={this.href("#Active")} >Published</div>) : null }
							{ this.state.drafts.length > 0 ? (<div className="DashboardComponentMenuItem" onClick={this.href("#Inactive")} >Drafts</div>) : null }					
						</div>
					</div>
				
				
					{this.state.count > 0 ? (
						<div className="DashboardComponentSection">
						<div className="DashboardComponentSectionTitle" id="Active">Published</div>				
						{ this.state.active.length > 0 ? (<ReleaseList releases={this.state.active} showStats={false} activate={this.activate} deactivate={this.deactivate} />) : (
						<div className="ReleasesNoActive">
							No Published Releases
						</div>) 
						}
					</div>
					) : (
						<div className="ReleasesEmpty">
						You have no releases yet. Get started by creating a <a href="/artists/newrelease">new release!</a>
						<div className="ReleasesNewRelease" onClick={() => {window.location="/artists/newrelease"}}>
						<div className="ReleasesNewReleaseLogo"><FontAwesome name="plus-square" /></div> <div className="ReleasesNewReleaseText">Create New Release</div>
						</div>
					</div>
					)}
					
					
					{ this.state.drafts.length && this.state.count > 0 ? (
					<div className="DashboardComponentSection">
						<div className="DashboardComponentSectionTitle" id="Inactive">Drafts</div>				
						<ReleaseList releases={this.state.drafts} showStats={false} activate={this.activate} deactivate={this.deactivate} />
					</div>) : null }						
					
				</div>
				{ this.state.loading ? (<Loading />) : null }
			</DashboardComponent>
		
		
		);
	}
	
}


export { Releases };