import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax.js';
import FontAwesome from 'react-fontawesome';
import dragula from "react-dragula";
import '../common/Overlay.css';
import './Preview.css';
import { Popup } from '../common/Popup.js';
import './dragula.css';
import { isUndefined } from 'util';
import ColorPicker from '../common/ColorPicker.js';
import { CSSTransitionGroup } from 'react-transition-group';
import { makeDefaultPresskit, makeDefaultNewsletter } from '../common/ReleaseDefaults.js';

const ACTIVE = 2;

class NewsletterPreview extends Component {

	constructor(props) {
        super(props);
        this.close = this.props.close;
        this.updateNewsletter = this.props.updateNewsletter;
        this.refreshRelease = this.props.refreshRelease;
        this.save = this.save.bind(this);
        this.handleSetTestEmail = this.handleSetTestEmail.bind(this);
        this.sendTest = this.sendTest.bind(this);
        this.sendNewsletter = this.sendNewsletter.bind(this);
        this.dragulaDecorator = this.dragulaDecorator.bind(this);
        this.removeNewsletterPart = this.removeNewsletterPart.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
        this.pushCustomImage = this.pushCustomImage.bind(this);
        this.toggleDetails = this.toggleDetails.bind(this);
        this.updateBackgroundColor = this.updateBackgroundColor.bind(this);
        this.updateFontColor = this.updateFontColor.bind(this);
        this.updateFontSize = this.updateFontSize.bind(this);
        this.updateTextAlign = this.updateTextAlign.bind(this);
        this.pushCustomText = this.pushCustomText.bind(this);
        this.setCustomText = this.setCustomText.bind(this);
        this.update = this.update.bind(this);
        this.setNewsletterToPreviousRelease = this.setNewsletterToPreviousRelease.bind(this);
        this.resetNewsletterDefaults = this.resetNewsletterDefaults.bind(this);
        this.updateGlobalBackgroundColor = this.updateGlobalBackgroundColor.bind(this);
        this.updateGlobalFontColor = this.updateGlobalFontColor.bind(this);
        this.setDefaultColors = this.setDefaultColors.bind(this);
        this.confirmSend = this.confirmSend.bind(this);
        this.cancelSend = this.cancelSend.bind(this);
        this.state = {newsletter: false, release: this.props.release, testemail: "", globalBackgroundColor: "#111111", globalFontColor: "#eeeeee", previousReleases: false, selectedPreviousRelease: "None", displayConfirmSend: false, 
            showSending: false, sending: false, sent: false, showSendingTest: false, sendingTest: false, sentTest: false};

        this.drake = null;

        this.partMapping = {
            titleArtist: "Title & Artist",
            cover: "Cover",
            checkItOut: "Check it out",
            platformLinks: "Platform Links",
            description: "Description",
            releaseDate: "Release Date",
            tags: "Genres",
            catalogNr: "Catalog Number",
            customImage: "Custom Image",
            customText: "Custom Text"
        };
    }

    uploadImage(partIdx) {
        var obj = this;
        return (event) => {
            var file = event.target.files[0];
            Ajax.upload(file, () => {}).then((data) => {
                var release = obj.state.release;            
                var newsletter = release.newsletter;
                var part = newsletter[partIdx];
                var logoId = data.file;
                part.variables.image = logoId;
                release.newsletter = newsletter;
                obj.setState({release: release}, obj.update);
            });
        };
    }

    update() {
        var obj = this;
        obj.save();
        Ajax.getNewsletterPreview(obj.state.release).then((result) => {
            obj.setState({newsletter: result.newsletter});                        
        });
    }

    setCustomText(partIdx) {
        var obj = this;
        return (event) => {
            var release = obj.state.release;            
            var newsletter = release.newsletter;
            var part = newsletter[partIdx];
            var val = event.target.value;
            part.variables.text = val;
            release.newsletter = newsletter;
            obj.setState({release: release});
        };
    }

    toggleDetails(partIdx) {
        var obj = this;
        return () => {
            var newsletter = obj.state.release.newsletter;
            var part = newsletter[partIdx];
            if (isUndefined(part.showDetails)) {
                part.showDetails = true;
            } else {
                part.showDetails = ! part.showDetails;
            }
            var release = obj.state.release;
            release.newsletter = newsletter;
            obj.setState({release: release}, () => {
                obj.save();
            });
        };
    }

    updateGlobalBackgroundColor() {
        var obj = this;
        return (color) => {
            var newsletter = obj.state.release.newsletter;
            for (var idx = 0; idx < newsletter.length; idx++) {
                var part = newsletter[idx];
                part.variables.backgroundColor = color;                
            }
            var release = obj.state.release;
            release.newsletter = newsletter;
            obj.setState({release: release, globalBackgroundColor: color}, () => {
                obj.update();
            });
        }        
    }

    updateBackgroundColor(partIdx) {
        var obj = this;
        return (color) => {
            var newsletter = obj.state.release.newsletter;
            var part = newsletter[partIdx];
            part.variables.backgroundColor = color;
            var release = obj.state.release;
            release.newsletter = newsletter;
            obj.setState({release: release}, () => {
                obj.update();
            });
        }
    }

    updateGlobalFontColor() {
        var obj = this;
        return (color) => {
            var newsletter = obj.state.release.newsletter;
            for (var idx = 0; idx < newsletter.length; idx++) {
                var part = newsletter[idx];
                part.variables.fontColor = color;                
            }
            var release = obj.state.release;
            release.newsletter = newsletter;
            obj.setState({release: release, globalFontColor: color}, () => {
                obj.update();
            });
        }    
    }

    updateFontColor(partIdx) {
        var obj = this;
        return (color) => {
            var newsletter = obj.state.release.newsletter;
            var part = newsletter[partIdx];
            part.variables.fontColor = color;
            var release = obj.state.release;
            release.newsletter = newsletter;
            obj.setState({release: release}, obj.update);
        }
    }

    updateFontSize(partIdx) {
        var obj = this;
        return (event) => {
            var fontSize = event.target.value;
            var newsletter = obj.state.release.newsletter;
            var part = newsletter[partIdx];
            part.variables.fontSize = fontSize;
            var release = obj.state.release;
            release.newsletter = newsletter;
            obj.setState({release: release}, obj.update);
        }
    }

    updateTextAlign(partIdx) {
        var obj = this;
        return (event) => {
            var textAlign = event.target.value;
            var newsletter = obj.state.release.newsletter;
            var part = newsletter[partIdx];
            part.variables.textAlign = textAlign;
            var release = obj.state.release;
            release.newsletter = newsletter;
            obj.setState({release: release}, obj.update);
        }
    }

    setDefaultColors() {
        var obj = this;
        var newsletter = obj.state.release.newsletter;
        for (var i = 0; i < newsletter.length; i++) {
			var part = newsletter[i];		
            if (part.type === "catalogNumber" || part.type === "tags") {
                part.variables.fontColor = "#999999";
            } else {
                part.variables.fontColor = "#eeeeee";
            }				
                
            if (part.type === "checkItOut") {
                part.variables.backgroundColor = "#ffa500";
            } else {
                part.variables.backgroundColor = "#111111";
            }
        }
        var release = obj.state.release;        
        release.newsletter = newsletter;        
        obj.setState({release: release}, obj.update);
    }

    pushCustomImage() {
        var obj = this;
        var release = obj.state.release;
        var newsletter = release.newsletter;
        var token = new Date().getTime();
        var customImage = {type: "customImage", key: token, active: true, variables: {image: false, link: release._id}};
        newsletter.push(customImage);
        release.newsletter = newsletter;
        obj.setState({release: release}, obj.update);
    }

    pushCustomText() {
        var obj = this;
        var release = obj.state.release;
        var newsletter = release.newsletter;
        var token = new Date().getTime();
        var customText = {type: "customText", key: token, active: true, variables: {text: ""}};
        newsletter.push(customText);
        release.newsletter = newsletter;
        obj.setState({release: release}, obj.update);
    }
    
    toggleActive(partIdx) {
        var obj = this;
        return () => {
            var newsletter = obj.state.release.newsletter;
            var part = newsletter[partIdx];
            if (isUndefined(part.active)) {
                part.active = false;
            } else {
                part.active = ! part.active;
            }
            var release = obj.state.release;
            release.newsletter = newsletter;
            obj.setState({release: release}, obj.update);
        };
    }

    removeNewsletterPart(partIdx) {
        var obj = this;
		return function() {
			var release = obj.state.release;
			var newsletter = release.newsletter;
			newsletter.splice(partIdx, 1);
			obj.setState({release: release}, obj.update);
		}
    }
    
    dragulaDecorator(componentBackingInstance) {
		var obj = this;
		if (componentBackingInstance) {
			let options = {
				moves: function (el, container, handle) {
						return (handle.classList.contains('NewsletterPartHandle') || handle.classList.contains('fa-ellipsis-v'));
					}
			};
			obj.drake = dragula([componentBackingInstance], options);
			var from;
			obj.drake.on('drag', function(element, source) {
				var index = [].indexOf.call(element.parentNode.children, element);
				from = index;
			});
	
			obj.drake.on('drop', function(element, target, source, sibling) {
				var index = [].indexOf.call(element.parentNode.children, element);
				obj.state.release.newsletter.splice(index, 0, obj.state.release.newsletter.splice(from, 1)[0]);
                var release = obj.state.release;                		
				obj.setState({release: release}, obj.update);
			});

		}
	}

	componentWillMount() {
		var obj = this;
		Ajax.getNewsletterPreview(this.state.release).then((result) => {
			if (result.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});
			} else {
				obj.setState({newsletter: result.newsletter}, () => {
                    Ajax.getReleases().then((releases) => {
                        var previousReleases = [];
                        for (var i = 0; i < releases.length; i++) {
                            var currentRelease = releases[i];
                            if (currentRelease.status === ACTIVE) {					
                                    previousReleases.push(currentRelease);
                            }
                        }
                        obj.setState({previousReleases: previousReleases});
                    });
                });
			}
		});
    }

    handleSetTestEmail(event) {
        var testemail = event.target.value;
        this.setState({testemail: testemail});
    }
    
    sendTest() {
        var obj = this;
        obj.setState({showSendingTest: true, sendingTest: true}, () => {
            Ajax.sendTestNewsLetter(obj.state.release, obj.state.testemail).then((result) => {                    
                obj.setState({sendingTest: false, sentTest: true}, () => {
                    setTimeout(() => {
                        obj.setState({showSendingTest: false});
                    }, 3000);
                });                	
            });
        });
    }

    sendNewsletter() {
        this.setState({displayConfirmSend: true});        
    }

    confirmSend() {
        var obj = this;
        var release = obj.state.release;

        obj.setState({showSending: true, sending: true, displayConfirmSend: false}, () => {
            Ajax.sendNewsletter(release).then(() => {
                release.newsletterSent = true;
                obj.setState({release: release});
                obj.setState({sending: false, sent: true}, () => {
                    setTimeout(() => {
                        obj.setState({showSending: false});
                    }, 3000);
                });     
            });
        });             
    }

    cancelSend() {
        var obj = this;
        obj.setState({displayConfirmSend: false});
    }

	setNewsletterToPreviousRelease(e) { 
        var obj = this;
        var selectedValue = e.target.value;
        if (selectedValue === "None") {
            obj.setState({selectedPreviousRelease: selectedValue}, obj.update);
            return;
        }
        var idx = parseInt(selectedValue);
        var release = obj.state.release;
        var previousReleases = obj.state.previousReleases;

        if (previousReleases.length <= 1) {
            return;
        }
        var previous = previousReleases[idx];
        if (previous) {
            release.newsletter = previous.newsletter;
            for (var i = 0; i < release.newsletter; i++) {
                var part = release.newsletter[i];
                if (part.type === "customImage") {
                    part.variables.link = release._id;
                }
            }
            obj.updateNewsletter(release);
            obj.setState({release: release, selectedPreviousRelease: selectedValue}, obj.update);
        }
	}

	resetNewsletterDefaults() {
		var defaultNewsletter = makeDefaultNewsletter();        
		var release = this.state.release;
        release.newsletter = defaultNewsletter;
        this.updateNewsletter(release);
		this.setState({release: release, selectedPreviousRelease: "None"}, this.update);
	}    

    save() {
        var release = this.state.release;        
        this.refreshRelease(release); // sends message to release to get saved
    }

	render() {
        var release = this.state.release;
        var obj = this;
		return (
			<div className="Overlay">
                <div className="Window Preview">
                    <div className="WindowTopBar"><div className="WindowClose" onClick={this.close}><FontAwesome name='window-close' /></div></div>
                    <div className="PreviewLeft">
                        {this.state.newsletter ? (<iframe title="Newsletter Preview" srcDoc={this.state.newsletter}></iframe>) : null}
                    </div>
                    <div className="PreviewRight">
                        <div className="PreviewRightTitle">Newsletter Preview</div>
                        <div className="PreviewRightBlock">
                            <div className="PreviewRightTestTitle">Send Newsletter</div>
                            <div className="PreviewRightSendText">
                                Clicking below will send the newsletter to all of your <a href="/admin/subscribers#Newsletter">Newsletter subscribers</a>. Be careful, the newsletter can only be sent once in this way!
                            </div>
                            {release.newsletterSent ? (<div className="PreviewRightTestButton"><div className="Button Inactive">Already Sent</div></div>) : (<div className="PreviewRightTestButton"><div className="Button" onClick={this.sendNewsletter}>Send</div></div>)}
                            <CSSTransitionGroup transitionName="confirmation" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
                                { this.state.showSending ? (<div className="ButtonConfirmation" >{ this.state.sending ? " Sending..." : (<span>Sent <FontAwesome name="check" /></span>) }</div>) : null }						
                            </CSSTransitionGroup>
                        </div>
                        <div className="PreviewRightBlock">
                            <div className="PreviewRightTestTitle">Send a test e-mail</div>
                            <div className="PreviewRightTestInput"><input type="text" name="testemail" value={this.state.testemail} onChange={this.handleSetTestEmail}/></div>
                            <div className="PreviewRightTestButton">
                                <div className="Button" onClick={this.sendTest}>Send Test</div>
                                <CSSTransitionGroup transitionName="confirmation" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
                                    { this.state.showSendingTest ? (<div className="ButtonConfirmation" >{ this.state.sendingTest ? " Sending..." : (<span>Sent <FontAwesome name="check" /></span>) }</div>) : null }						
                                </CSSTransitionGroup>	
                            </div>
                        </div>
                        <div className="PreviewRightBlock">
                            <div className="PreviewRightTestTitle">Options</div>
                            <div className="PreviewRightSendText">Use your previous newsletter layout.</div>
                            <div className="PreviewRightTestButton">
                                {obj.state.previousReleases ? (
                                <select value={obj.state.selectedPreviousRelease} onChange={obj.setNewsletterToPreviousRelease}>
                                    <option value="None">----</option>
                                    {obj.state.previousReleases.map((previousRelease, idx) => {
                                        if (previousRelease._id !== release._id)
                                            return (<option value={idx} key={idx}>{previousRelease.title}</option>);
                                    })}
                                </select>): null }
                            </div>
                            <div className="PreviewRightSendText">Reset default layout.</div>
                            <div className="PreviewRightTestButton"><div className="Button" onClick={this.resetNewsletterDefaults}>Default Layout</div></div>
                            <div className="PreviewRightSendText">Adjust colors for the entire newsletter.</div>
                            <div className="NewsletterPartTextDetails">
                                <div className="NewsletterPartDetail">
                                    <div className="NewsletterPartDetailName">Background Color</div>
                                    <div className="NewsletterPartValue">
                                        <ColorPicker color={obj.state.globalBackgroundColor} update={obj.updateGlobalBackgroundColor()} />
                                    </div>
                                </div>
                                <div className="NewsletterPartDetail">
                                    <div className="NewsletterPartDetailName">Font Color</div>
                                    <div className="NewsletterPartValue">
                                        <ColorPicker color={obj.state.globalFontColor} update={obj.updateGlobalFontColor()} />
                                    </div>
                                </div>
                            </div>
                            <div className="PreviewRightSendText">Reset the newsletter to default colors.</div>
                            <div className="PreviewRightTestButton"><div className="Button" onClick={this.setDefaultColors}>Default Colors</div></div>                                                   
                        </div>
                        <div className="PreviewRightBlock">
                            <div className="PreviewRightTestTitle">Customize</div>
                            <div className="PreviewRightSendText">Drag and drop components to rearrange them in the newsletter. Edit them to modify background and font colors. Add custom image or text components to enrich your newsletter!</div>                            
                            <div className="NewsletterParts" ref={this.dragulaDecorator}>                                
                                { release.newsletter.map(function(part, idx) {                
                                        return (
                                            <div key={part.type + part.key} id={idx} className="NewsletterPart">
                                                <div className="NewsletterPartHandle"><FontAwesome name="ellipsis-v" /><FontAwesome name="ellipsis-v" /></div>
                                                <div className="NewsletterPartToggle"><input type="checkbox" onChange={obj.toggleActive(idx)} name="active" checked={isUndefined(part.active) || part.active}/></div>
                                                {((part.active === undefined) || part.active) ? 
                                                (<div className="NewsletterPartName">{obj.partMapping[part.type]}</div>) :
                                                (<div className="NewsletterPartName NewsletterPartNameInactive">{obj.partMapping[part.type]}</div>)
                                                }
                                                {((part.active === undefined) || part.active) && ((part.showDetails !== undefined) && part.showDetails) ? (
                                                    <div className="NewsletterPartDetails">
                                                        {part.type === "customImage" ? 
                                                        (<div className="NewsletterPartImageUpload">
                                                            <input ref={(ref) => obj["imageUploader " + idx] = ref} type="file" name="cover" id="cover" accept="image/jpeg" onChange={obj.uploadImage(idx)}/>
                                                                { part.variables.image ? (
                                                                    <div className="ProfileLogoImage" onClick={() => { obj["imageUploader " + idx].click() } }>
                                                                        <img src={Ajax.getFile(part.variables.image)} alt="custom image" />
                                                                        <div className="ProfileLogoImageChange">Click to change</div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="ProfileLogoImage" onClick={() => { obj["imageUploader " + idx].click() } }>
                                                                        <FontAwesome name="image" />
                                                                        <div className="ProfileLogoImageChange">Click to upload</div>
                                                                    </div>
                                                                )					
                                                                }
                                                        </div>) : null }
                                                        {part.type === "customText" ? 
                                                        (<div className="NewsletterPartCustomText">
                                                            <div className="NewsletterPartDetailName">Custom Text</div>
                                                            <textarea placeholder="Enter text here" id="custom text" onBlur={obj.update} onChange={obj.setCustomText(idx)} value={part.variables.text} resize="false" />
                                                        </div>) : null }
                                                        <div className="NewsletterPartTextDetails">
                                                            <div className="NewsletterPartDetail">
                                                                <div className="NewsletterPartDetailName">Background Color</div>
                                                                <div className="NewsletterPartValue">
                                                                    <ColorPicker color={part.variables.backgroundColor} update={obj.updateBackgroundColor(idx)} />
                                                                </div>
                                                            </div>
                                                            {! (part.type === "customImage") ? 
                                                            (<div className="NewsletterPartDetail">
                                                                <div className="NewsletterPartDetailName">Font Color</div>
                                                                <div className="NewsletterPartValue">
                                                                    <ColorPicker color={part.variables.fontColor} update={obj.updateFontColor(idx)} />
                                                                </div>
                                                            </div>) : null }
                                                            {! (part.type === "customImage") ? 
                                                            (<div className="NewsletterPartDetail">
                                                                <div className="NewsletterPartDetailName">Font Size</div>
                                                                <div className="NewsletterPartValue">
                                                                    <select name="fontSize" defaultValue={part.variables.fontSize} id="fontSize" onChange={obj.updateFontSize(idx)}>
                                                                        <option value="8pt">8pt</option>
                                                                        <option value="9pt">9pt</option>
                                                                        <option value="10pt">10pt</option>
                                                                        <option value="11pt">11pt</option>
                                                                        <option value="12pt">12pt</option>
                                                                        <option value="13pt">13pt</option>
                                                                        <option value="14pt">14pt</option>
                                                                    </select>
                                                                </div>
                                                            </div>) : null }
                                                            {! (part.type === "customImage") ? 
                                                            (<div className="NewsletterPartDetail">
                                                                <div className="NewsletterPartDetailName">Font Alignment</div>
                                                                <div className="NewsletterPartValue">
                                                                    <select name="textAlign" id="textAlign" defaultValue={part.variables.textAlign} onChange={obj.updateTextAlign(idx)}>
                                                                        <option value="left">left</option>
                                                                        <option value="right">right</option>
                                                                        <option value="center">center</option>
                                                                        <option value="justify">justify</option>
                                                                    </select>
                                                                </div>
                                                            </div>) : null }
                                                        </div>
                                                    </div>) : null
                                                }
                                                { ! (part.type === "cover") && (isUndefined(part.active) || part.active) ?                                                 
                                                (<div className="NewsletterPartShowDetails" onClick={obj.toggleDetails(idx)}><i className={ !isUndefined(part.showDetails) && part.showDetails ? "fas fa-chevron-circle-up" : "fas fa-chevron-circle-down"}></i></div>) : null }
                                                {part.type === "customText" || part.type === "customImage" ? 
                                                (<div className="NewsletterPartDelete" onClick={obj.removeNewsletterPart(idx)}><i className="fas fa-minus-circle"></i></div>) : null}
                                            </div>
                                        );
                                    })
                                }
                            </div>                
                        </div>
                        <div className="NewsletterAddParts">
                            <div className="NewsletterAddPart" onClick={this.pushCustomImage}>
                                <div className="NewsletterAddPartIcon"><FontAwesome name="image" /></div>
                                <div className="NewsletterAddPartName">Add custom image</div>
                            </div>
                            <div className="NewsletterAddPart" onClick={this.pushCustomText}>
                                <div className="NewsletterAddPartIcon"><FontAwesome name="file-alt" /></div>
                                <div className="NewsletterAddPartName">Add custom text</div>
                            </div>
                        </div>                                             
                    </div>
                </div>
                {obj.state.displayConfirmSend ? (
                <Popup>
                    <p>You are about to send your newsletter to all of your subscribers. Cancel this if you still need to make changes.<br/><br/></p>
                    <div className="ButtonContainer"><div className="Button" onClick={this.confirmSend}>Send Newsletter</div></div>
                    <div className="ButtonContainer"><div className="Button" onClick={this.cancelSend}>Cancel</div></div>
                </Popup>
                ) : null }
            </div>	
		);		
	}

}

export { NewsletterPreview };