import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Ajax } from '../../ajax/Ajax.js';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from '../common/Loading.js';
import './Stats.css';
import { StatFormatter } from '../common/StatFormatter.js';
import { ArtistFormatter } from '../common/ArtistFormatter.js';
import { StatSection } from '../common/StatSection.js';

class Stats extends Component {
	
	constructor(props) {
		super(props);
        this.state = { allowed: true, releases: false, loading: true, selectedReleaseId: 0, stats: false};
        this.selectRelease = this.selectRelease.bind(this);
	}
	
	componentWillMount() {		
		var obj = this;
        Ajax.getProfile().then((profile) => {
            if (profile.plan === undefined || profile.plan === "free") {
                obj.setState({allowed: false, loading: false});
            } else {
                Ajax.getReleases().then((releases) => {
                    obj.setState({releases: releases}, () => {
                        if (releases.length > 0) {
                            var firstRelease = releases[0];
                            Ajax.getStats(firstRelease._id).then((stats) => {                        
                                var formattedStats = new StatFormatter(firstRelease, stats.minipage, stats.newsletter, stats.presskit).format();
                                obj.setState({allowed: true, loading: false, stats: formattedStats});
                            });
                        } else {
                            obj.setState({allowed: true, loading: false});
                        }                
                    });
                });
            }
        });		
    }
    
    selectRelease(event) {
        var obj = this;
        var idx = event.target.value;
        var release = obj.state.releases[idx];
        console.log(idx);
        console.log(release);
        var releaseId = release._id;
        obj.setState({loading: true}, () => {
            Ajax.getStats(releaseId).then((stats) => {                
                var formattedStats = new StatFormatter(release, stats.minipage, stats.newsletter, stats.presskit).format();
                //console.log(formattedStats);
                obj.setState({loading: false, stats: formattedStats, selectedReleaseId: idx});
            });
        });
    }
	
	render() {
        var obj = this;
        var releases = obj.state.releases;
        var stats = obj.state.stats;
        return (
            <DashboardComponent>
                <div className="Stats">
                    <div className="DashboardComponentHeader">
                        <div className="DashboardComponentTitle"><FontAwesome name="chart-bar" /> Stats</div>
                        <div className="DashboardComponentMenu">
                        </div>
                    </div>
                    {this.state.allowed ? (
                    <div className="DashboardComponentSection">
                        <div className="DashboardComponentSectionTitle">Select release to view stats</div>
                        {releases ? (
                        <select value={obj.state.selectedReleaseId} onChange={obj.selectRelease}>
                            {releases.map((release, idx) => {
                                var artistFormatter = new ArtistFormatter(release.artists);
                                var artists = artistFormatter.format();
                                return (<option value={idx} key={release._id}>{release.title + " by " + artists}</option>);
                            })}
                        </select>) : null}
                        {stats ? (<StatSection stats={stats} />) : null}
                    </div>
                    ) : (
                    <div className="DashboardComponentSection">
                        <div className="StatsUpgrade">
                            <p>This is a premium feature. Upgrade your subscription in the <a href="/artists/billing">Billing Plan</a> section.</p>
                            <div className="StatsUpgradeAction" onClick={() => {window.location = "/artists/billing"}}>
                                <div className="StatsUpgradeActionLogo"><FontAwesome name="sun"/></div>
                                <div className="StatsUpgradeActionText">Upgrade Now!</div>
                            </div>
                        </div>
                    </div>
                    )}
                    
                </div>
                { this.state.loading ? (<Loading />) : null }
            </DashboardComponent>			
        );		
	}
}

export { Stats };