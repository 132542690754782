const { isUndefined } = require("underscore");

class ArtistFormatter {
    constructor(artists) {
        this.artists = artists;
    }

    format() {
        var artists = this.artists;

        if (isUndefined(artists)) {
            return "";            
        }

        var artistsString = "";
        for (var idx = 0; idx < artists.length; idx++) {
            var artist = artists[idx].text;
            artistsString += artist;
            if (idx < artists.length - 1) {
                artistsString += ", ";
            }
        }
        return artistsString;
    }
}

export { ArtistFormatter }