import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import { PayPalButton } from "react-paypal-button-v2";

import './Register.css';
import '../common/Overlay.css';

var FontAwesome = require('react-fontawesome');

class Upgrade extends Component {
	
	constructor(props) {
		super(props);
		this.close = this.props.close;
		this.upgrade = this.upgrade.bind(this);
		this.state = {
            showSuccess: false
		};
	}
	

	upgrade(data) {
		var obj = this;
		return (details) => {
			var subscriptionId = data.subscriptionID;
			var orderId = data.orderID;
			Ajax.upgrade(subscriptionId, orderId).then(function (profile) {
				if (profile.error) {
					obj.setState({error: profile.error});
				} else {
					obj.setState({showSuccess: true});
				}
			});
		}		
	}
	
	render() {
		var obj = this;		
		return (
			<div className="Window Register">
				<div className="WindowTopBar"><div className="WindowClose" onClick={this.close}><FontAwesome name='window-close' /></div></div>		
					<div className="RegisterVerify">
						<div className="RegisterVerifyPrice">
							Premium Account: &euro;69.99/Month<br/>
						</div>
                        {obj.state.showSuccess ? (
                            <div>
                                Payment Successful! You can continue using your Premium Account!
                            </div>
                        ) : (
                        <PayPalButton
							options={{vault: true, intent: "subscription", currency: "EUR", clientId: "AauUdj68A0fw_ilVbnABFx2tqbob_soSJGur0txb8evqyhSrGEzhMOSgCLfwHxRHJtVhy5ps2cIus9pd"}}
							createSubscription={(data, actions) => {
							return actions.subscription.create({
								plan_id: 'P-6J1654201P789340NMBAFZRQ'
							});
							}}
							onApprove={(data, actions) => {
							// Capture the funds from the transaction
							return actions.subscription.get().then(obj.upgrade(data));
							}}
						/>
                        )}  
						
					</div>		
			</div>
		
		);
	}
}

export { Upgrade };
