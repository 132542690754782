import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax.js';
import FontAwesome from 'react-fontawesome';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from '../common/Loading.js';
import './Widgets.css';

class Widgets extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			profile: false
		}
	}

	componentWillMount() {
		var obj = this;
		Ajax.getProfile().then((profile) => {
			if (profile.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});
			} else {
				obj.setState({profile: profile, loading: false});
				console.log(profile);
			}
		});
	}
	
	render() {
		var obj = this;
		return (
			<DashboardComponent>
				<div className="Widgets">
					<div className="DashboardComponentHeader">
							<div className="DashboardComponentTitle"><FontAwesome name="columns" /> Widgets</div>
						</div>
					
											
						{ obj.state.profile ? (
						<div className="DashboardComponentSection">
							<div className="DashboardComponentSectionTitle" id="Active">Newsletter Subscription using IFrame</div>
							<p>Include this code on your page where you want your subscription widget to appear: </p>
							<div className="WidgetCode">
								<div className="CopyButton" onClick={() => {navigator.clipboard.writeText('<iframe src="https://earlygrooves.com/subwidget/' + obj.state.profile._id + '" width="500" height="50" frameBorder="0" scrolling="no" />')}}><FontAwesome name="copy" /> Copy to clipboard</div>
								<div className="Code">
								&lt;iframe<br/>
								&nbsp;&nbsp;src="https://earlygrooves.com/subwidget/{obj.state.profile._id}"<br/>
								&nbsp;&nbsp;width="500"<br/>
								&nbsp;&nbsp;height="50"<br/>
								&nbsp;&nbsp;frameBorder="0"<br/> 
								&nbsp;&nbsp;scrolling="no"<br/>
								/>	
								</div>							
							</div>
							<p>This will add a widget to your page as shown below.</p>
							<iframe src={ "/subwidget/" + obj.state.profile._id } width="500" height="50" frameBorder="0" scrolling="no" />
						</div>) : null }
					{ this.state.loading ? (<Loading />) : null }
				</div>
			</DashboardComponent>
		);
	}
}

export { Widgets };