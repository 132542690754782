import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax';
import { cookies } from '../../cookies/Cookies.js';

import { AudioPlaylist } from '../common/AudioPlaylist.js';
import { MinipageWidget } from '../widgets/MinipageWidget.js';
import './Minipage.css';
import { ArtistFormatter } from '../common/ArtistFormatter';
import { GenreFormatter } from '../common/GenreFormatter';

function formatDate(date) {
	var monthNames = [
	  "January", "February", "March",
	  "April", "May", "June", "July",
	  "August", "September", "October",
	  "November", "December"
	];
  
	var day = date.getDate();
	var monthIndex = date.getMonth();
	var year = date.getFullYear();
  
	return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

class Minipage extends Component {
	
	constructor(props) {
        super(props);
        if (this.props.release !== undefined) {
            this.state = {slug: this.props.release.slug, releaseId: this.props.release._id, preview: true, release: this.props.release, tracks: false, artist: false, genres: false, releaseDate: false, profileId: false};
        } else {
            this.state = {slug: this.props.match.params.releaseId, releaseId: false, subscriberId: this.props.match.params.subscriberId, preview: false, release: false, tracks: false, artist: false, genres: false, releaseDate: false, profileId: false};
        }        
        this.formatRelease = this.formatRelease.bind(this);
        this.toPlatform = this.toPlatform.bind(this);        
    }
    
    formatRelease(release) {
        var obj = this;
        var artists = new ArtistFormatter(release.artists).format();
        var genres = new GenreFormatter(release.genres).format();
        var releaseDate = formatDate(new Date(release.releaseDate));
        var tracks = release.tracks;
        var mp3s = [];
        for (var i = 0; i < tracks.length; i++) {
            var track = tracks[i];            
            var src = Ajax.getFile(track.mp3File);                    
            var mp3 = {src: src, title: track.title, nr: track.trackNumber, artist: track.artist, playable: track.listen};
            mp3s.push(mp3);         
        }
        var background = {img: false, color: false};
        if (release.minipage !== undefined) {
            if ((release.minipage.backgroundCover !== undefined) && release.minipage.backgroundCover) {
                background.img = release.cover;
            } else {
                if (release.minipage.backgroundColor !== undefined) {
                    background.color = release.minipage.backgroundColor;
                }
            }
        }
        var profileId = release.profileId;
        obj.setState({release: release, releaseId: release._id, tracks: mp3s, artists: artists, genres: genres, releaseDate: releaseDate, profileId: profileId});
    }
	
	componentWillMount() {        
        var obj = this;
        if (! this.state.release) {            
            Ajax.getPublicReleaseBySlug(this.state.slug).then((release) => {
                var from = window.document.referrer;
                if (! this.state.preview && ! cookies.wasMinipageOpened(release._id)) {
                    Ajax.statsMinipageOpen(release._id, from, this.state.subscriberId);
                    cookies.minipageOpened(release._id);
                }
                obj.formatRelease(release);
            });
        } else {
            obj.formatRelease(this.state.release);
        }        
    }

    componentDidUpdate() {
        const newProps = this.props;
        if ((newProps.change !== undefined) && newProps.change !== this.state.change) {
            var release = newProps.release;
            var tracks = release.tracks;
            var mp3s = [];
            for (var i = 0; i < tracks.length; i++) {
                var track = tracks[i];            
                var src = Ajax.getFile(track.mp3File);                    
                var mp3 = {src: src, title: track.title, nr: track.trackNumber, artist: track.artist, playable: track.listen};
                mp3s.push(mp3);         
            }
            this.setState({release: newProps.release, tracks: mp3s, change: newProps.change});
        }
    }
    
    toPlatform(url, platform) {
        var obj = this;
        return (event) => {
            Ajax.statsMinipagePlatformClick(obj.state.releaseId, platform).then((output) => {
                window.location.href = url;
            });      
        };
    }
	
	render() {
        var obj = this;
        var release = obj.state.release;
        var tracks = obj.state.tracks;
        
        if (release) {
            var backgroundStyle;
            var mini = release.minipage;
            var background = false;
            if (mini.backgroundCover) {
                if (mini.backgroundSrc === undefined) {
                    background = Ajax.getFile(release.cover.large);
                } else {
                    background = Ajax.getFile(mini.backgroundSrc);
                }                
            }
            var cover = false;
            if (release.cover.large) {
                cover = Ajax.getFile(release.cover.large);
            }
            if (release.cover.small) {
                cover = Ajax.getFile(release.cover.small);
            }
            if ((mini !== undefined) && mini) {
                if ((mini.backgroundBlur !== undefined) && mini.backgroundBlur) {
                    backgroundStyle = mini.backgroundCover ? 
                    {backgroundImage: "url(" + background + ")", filter: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='15' height='15'%3E%3CfeGaussianBlur stdDeviation='15' result='b'/%3E%3CfeMorphology operator='dilate' radius='15'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E%3C/svg%3E#a\")"} : 
                    {backgroundColor: "rgba(" + mini.backgroundColor.r + ", " + mini.backgroundColor.g + ", " + mini.backgroundColor.b + ", " + mini.backgroundColor.a + ")"};                
                } else {
                    backgroundStyle = mini.backgroundCover ? 
                    {backgroundImage: "url(" + background + ")"} : 
                    {backgroundColor: "rgba(" + mini.backgroundColor.r + ", " + mini.backgroundColor.g + ", " + mini.backgroundColor.b + ", " + mini.backgroundColor.a + ")"};                
                }
                
            } else {
                release.minipage = {};
                release.minipage.backgroundColor = {r: 255, g: 255, b: 255, a: 1};
                mini = release.minipage;
                backgroundStyle =  {backgroundColor: "rgba(" + mini.backgroundColor.r + ", " + mini.backgroundColor.g + ", " + mini.backgroundColor.b + ", " + mini.backgroundColor.a + ")"};
            }            
            return (
                <div className="MinipageOuter">
                    <div className="MinipageBackground" style={backgroundStyle}/>
                    <div className="MinipageInner">                        
                        <div className="MinipageCover">
                            {cover ? (<img src={cover} alt="cover" />) : null }
                        </div>
                        <div className="MinipageTitle">
                            <div className="MinipageTitleTitle">{release.title}</div>                        
                            <div className="MinipageTitleArtists">{obj.state.artists}</div>
                        </div>
                        <div className="MinipageLinks">
                            {release.links.map((link, idx) => {
                                if (link.icon === "shopping-cart") {
                                    return (
                                        <div className="MinipageLink" onClick={obj.toPlatform(link.url, link.platform)} key={link.icon}>
                                            <div className="MinipageLinkLeft"><i className={"fa fa-" + link.icon}></i> {link.platform}</div>
                                            <div className="MinipageLinkRight">{link.action}</div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="MinipageLink" onClick={obj.toPlatform(link.url, link.platform)} key={link.icon}>
                                            <div className="MinipageLinkLeft"><img src={"/" + link.img} alt={link.platform + " logo"} /></div>
                                            <div className="MinipageLinkRight">{link.action}</div>
                                        </div>
                                    );
                                }
                               
                            })}
                        </div>
                        { tracks.length > 0 ? (
                        <div className="MinipagePlayer">
                            <AudioPlaylist mp3={tracks} change={this.state.change} />
                        </div>): null }                    
                        <div className="MinipageDescription" dangerouslySetInnerHTML={{__html: release.description.replace(/(?:\r\n|\r|\n)/g, '<br/>')}} />
                        <div className="MinipageReleaseDate">Released on {this.state.releaseDate}</div>
                        <div className="MinipageTags">{this.state.genres}</div>                        
                        <div className="MinipageCatalogNumber">
                            {release.catalogNumber}
                        </div>
                        { this.state.profileId ? (<MinipageWidget profileId={this.state.profileId} />) : null }
                        <div className="MinipageEG">Powered by <a href="https://earlygrooves.com">Early Grooves</a></div>
                    </div>
                </div>
                );
        } else {
            return null;
        }		
	}
}

export { Minipage };