import React, { Component } from 'react';
import { Ajax } from '../../ajax/Ajax.js';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from '../common/Loading.js';
import FontAwesome from 'react-fontawesome';
import './Billing.css';
import { Upgrade } from '../auth/Upgrade.js';
import { Cancel } from '../auth/Cancel.js';

function formatDate(date) {
	var monthNames = [
	  "January", "February", "March",
	  "April", "May", "June", "July",
	  "August", "September", "October",
	  "November", "December"
	];
  
	var day = date.getDate();
	var monthIndex = date.getMonth();
	var year = date.getFullYear();
  
	return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

class Billing extends Component {
	
	constructor(props) {
		super(props);
		this.showUpgrade = this.showUpgrade.bind(this);
		this.state = {
			profile: false,
			loading: true,
			showUpgrade: false,
			showCancel: false,
			subscription: false
		}
	}

	componentWillMount() {
		var obj = this;
		Ajax.getProfile().then((profile) => {
			if (profile.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});
			} else {
				//console.log(profile);
				//obj.setState({profile: profile, loading: false});
				Ajax.getSubscription(profile.subscriptionId).then((subscription) => {
					obj.setState({profile: profile, subscription: subscription, loading: false});
				});
			}
		});
	}

	showUpgrade(value) {
		var obj = this;
		return function() {
			obj.setState({showUpgrade: value});
		}
	}

	showCancel(value) {
		var obj = this;
		return function() {
			obj.setState({showCancel: value});
		}
	}
	
	render() {
		var plan = false;
		if (this.state.profile) {
			plan = this.state.profile.plan;
		}
		var status = false;
		var nextBilling = false;
		if (this.state.subscription && this.state.subscription !== undefined) {	
			console.log(this.state.subscription.subscription);
			status = this.state.subscription.subscription.status;
			if (status === "ACTIVE") {
				var nextBillingDate = new Date(this.state.subscription.subscription.billing_info.next_billing_time);
				nextBilling = formatDate(nextBillingDate);
			} else {
				nextBilling = formatDate(new Date(this.state.profile.validUntil));
			}			
		}
		return (
			<DashboardComponent>
                <div className="Billing">
                    <div className="DashboardComponentHeader">
                        <div className="DashboardComponentTitle"><FontAwesome name="dollar-sign" /> Billing</div>
                    </div>
					{plan ? (
					<div className="DashboardComponentSection">
						<div className="BillingCurrentPlan">
							You current plan: <div className="BillingPlan">{plan === "free" ? "Standard (free)" : "Premium" }</div>
						</div>
						{plan === "premium" ? (
						<div className="BillingPlanDetails">
							<div><div className="BillingPlanDetailsLabel">Status</div><div className="BillingPlanDetailsStatus">{status}</div></div>
							<div><div className="BillingPlanDetailsLabel">{status === "ACTIVE" ? "Next Billing Date" : "Valid Until" }</div><div className="BillingPlanDetailsNextBilling">{nextBilling}</div></div>
							{status === "ACTIVE" ? (<div><div className="BillingPlanDetailsLabel">Billing Amount</div><div className="BillingPlanDetailsNextBilling">&euro;69.99</div></div>) : null }
						</div>
						) : null }
						{plan === "free" ? (
						<div>
								Upgrading to a Premium plan allows you to consult all the stats for your releases.
							<div className="BillingUpgrade" onClick={this.showUpgrade(true)}>
								
								<div className="BillingUpgradeLogo"><FontAwesome name="sun"/></div><div className="BillingUpgradeText">Upgrade to Premium for &euro;69.99/Month</div>
							</div>
						</div>
						) : status === "ACTIVE" ? (
						<div className="BillingCancel" onClick={this.showCancel(true)}>
							<div className="BillingCancelLogo"><FontAwesome name="ban"/></div><div className="BillingCancelText">Cancel Plan</div>
						</div>) : null						
						}
					</div>
					) : null }
				</div>
				{ this.state.showCancel ? (<div className="Overlay"><Cancel close={this.showCancel(false)} subscriptionId={this.state.profile.subscriptionId} /></div>) : null }
				{ this.state.showUpgrade ? (<div className="Overlay"><Upgrade close={this.showUpgrade(false)} /></div>) : null }
				{ this.state.loading ? (<Loading />) : null }
			</DashboardComponent>
		);
	}
}

export { Billing };