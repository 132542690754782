import React, { Component } from 'react';

import { Ajax } from '../../ajax/Ajax.js';
import FontAwesome from 'react-fontawesome';
import { DashboardComponent } from '../dashboard/DashboardComponent.js';
import { Loading } from '../common/Loading.js';


import './DashboardLanding.css';
import { StatFormatter } from '../common/StatFormatter.js';
import { ArtistFormatter } from '../common/ArtistFormatter.js';
import { StatSection } from '../common/StatSection.js';
import { isUndefined } from 'util';

const ACTIVE = 2;
const removeDiacritics = require('diacritics').remove;
function formatDate(date) {
	var monthNames = [
	  "January", "February", "March",
	  "April", "May", "June", "July",
	  "August", "September", "October",
	  "November", "December"
	];
  
	var day = date.getDate();
	var monthIndex = date.getMonth();
	var year = date.getFullYear();
  
	return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

class DashboardLanding extends Component {
	
	constructor(props) {
		super(props);
		this.state = { loading: true, stats: false, latest: false, schedule: false};
		this.formatStats = this.formatStats.bind(this);
	}

	formatStats(release, minipage, newsletter, presskit) {		
		if (! isUndefined(release) &&  release) {
			var statFormatter = new StatFormatter(release, minipage, newsletter, presskit);
			var stats = statFormatter.format();
			var artistFormatter = new ArtistFormatter(release.artists);
			var artists = artistFormatter.format();
			this.setState({stats: stats, latest: release, artists: artists});		
		}		
	}

	componentWillMount() {		
		var obj = this;

		Ajax.getProfile().then((profile) => {
			if (profile.error === "Not Authenticated") {
				Ajax.logout().then(() => {
					window.location.href = "/";
				});
			} else {
				if ((! profile.slug) || (profile.slug === undefined) || (profile.slug === null) ) {
					Ajax.getProfileSlugs().then((slugs) => {
						var name = profile.name;
						var slug = removeDiacritics(name).replace(/\s+/g, "-").replace(/&/g, "and").replace(/\?/g, "").replace(/\//g, "-").replace(/:/g, "-").toLowerCase();
						var slugOk = false;
						var slugCounter = 0;
						while(! slugOk) {
							if (slugCounter > 0) {
								slug = slug + "-" + slugCounter;
							}
							if (! isUndefined(slugs[slug])) {
								var id = slugs[slug];
								if (profile._id === id || ! id) {
									slugOk = true;
								} else {
									slugCounter++;
									continue;
								}
							} else {
								slugs[slug] = profile._id;
								slugOk = true;
							}
						}		
						profile.slug = slug;
						Ajax.updateProfile(profile).then(() => {
							
						});
					});	
				}					
				Ajax.getReleases().then((releases) => {			
					var today = new Date();
					var latest = false;
					var latestDate = today;
					var schedule = [];
					for (var i = 0; i < releases.length; i++) {
						var release = releases[i];
						var releaseDate = new Date(release.releaseDate);
		
						// add info about upcoming releases to schedule
						if (release.status === ACTIVE) {
							if (latest) {
								if (releaseDate >= latestDate) {
									latestDate = releaseDate;
									latest = release;
								}  
							} else {
								latestDate = releaseDate;
								latest = release;
							}
							if (new Date(release.prereleaseDate) >= today) {
								schedule.push({release: release, date: new Date(release.prereleaseDate), message: "Send out presskit"});
							}
							if (new Date(release.releaseDate) >= today) {
								schedule.push({release: release, date: new Date(release.releaseDate), message: "Send out newsletter"});
							}
						}
					}
			
					schedule.sort((r1, r2) => {
						if (r1.date < r2.date) return -1;
						if (r1.date === r2.date) return 0;
						if (r1.date > r2.date) return 1;
					});
					obj.setState({schedule: schedule}, () => {
						Ajax.getStats(latest._id).then((stats) => {
							if (stats.stats) {
								obj.formatStats(latest, stats.minipage, stats.newsletter, stats.presskit);
							}					
							obj.setState({latest: latest, loading: false});
						});
					});
					
					
				});		
			}
		});						
	}
	
	render() {
		var latest = this.state.latest;
		var cover = false;
		if (latest) {
			var cover = Ajax.getFile(latest.cover.large);
			if (latest.cover.small) {
				cover = Ajax.getFile(latest.cover.small);
			}
		}		
		var stats = this.state.stats;
		console.log(stats);
		return (
			<DashboardComponent>
				<div className="DashboardLanding">
					<div className="DashboardComponentHeader">
						<div className="DashboardComponentTitle"><FontAwesome name="chart-pie" /> Dashboard</div>
						<div className="DashboardComponentMenu">
						</div>
					</div>

					<div className="DashboardComponentSection">
						<div className="DashboardLandingLatest">
							<div className="DashboardComponentSectionTitle">Recent Releases</div>
							{latest ? (
							<div>
								<div className="DashboardLandingLatestTitle" onClick={() => {window.location.href = "/artists/release/" + latest._id;}}>
									<div className="DashboardLandingLatestTitleCover">
										{cover ? (<img src={cover} alt="cover" />) : null }
									</div>
									<div className="DashboardLandingLatestRelease">
										<div className="DashboardLandingLatestReleaseAnnounce">Latest Release</div>
										<div className="DashboardLandingLatestReleaseTitle">{latest ? latest.title : null}</div>
										<div className="DashboardLandingLatestReleaseArtist">{latest ? this.state.artists : null}</div>
									</div>
								</div>							
								{ stats ? (<StatSection stats={stats} />) : null }
							</div>
							): (
								<div className="DashboardLandingLatestEmpty">
									You have no active releases yet. Get started by creating a <a href="/artists/newrelease">new release!</a>
									<div className="DashboardLandingNewRelease" onClick={() => {window.location="/artists/newrelease"}}>
									<div className="DashboardLandingNewReleaseLogo"><FontAwesome name="plus-square" /></div> <div className="DashboardLandingNewReleaseText">Create New Release</div>
									</div>
								</div>
							) }
						</div>
						<div className="DashboardLandingSchedule">
							<div className="DashboardComponentSectionTitle">Schedule</div>								
							{ !isUndefined(this.state.schedule) && this.state.schedule && this.state.schedule.length > 0 ? (
							<div>
								<div className="DashboardLandingScheduleTitle">Upcoming</div>
								{this.state.schedule ?  
									this.state.schedule.map((item, elIdx) => {
										var d = new Date(item.date);
										var formatted = formatDate(d);
										var cover = false;
										if (item.release.cover.large) {
											cover = Ajax.getFile(item.release.cover.large);
										}
										if (item.release.cover.small) {
											cover = Ajax.getFile(item.release.cover.small);
										}
										var artists = "";
										for (var idx = 0; idx < item.release.artists.length; idx++) {
											var artist = item.release.artists[idx].text;
											artists += artist;
											if (idx < item.release.artists.length - 1) {
												artists += ", ";
											}
										}
										return (
										<div className="DashboardLandingScheduleItem" key={elIdx} onClick={() => {window.location.href = "/artists/release/" + item.release._id;}}>
											<div className="DashboardLandingScheduleItemDate">{formatted}</div>
											<div className="DashboardLandingScheduleItemRelease">
												<div className="DashboardLandingScheduleItemReleaseCover">
													{cover ? (<img src={cover} alt="cover art"/>) : null}
												</div>
												<div className="DashboardLandingScheduleItemReleaseTitle">{item.release.title}</div>
												<div className="DashboardLandingScheduleItemReleaseArtist">{artists}</div>
											</div>
											<div className="DashboardLandingScheduleItemTask">{item.message}</div>
										</div>
										);
									}) : null
								}
						</div>
						) :  (<div className="DashboardLandingScheduleEmpty">No upcoming events</div>) }	
						</div>					
					</div>					
				</div>
				{ this.state.loading ? (<Loading />) : null }
			</DashboardComponent>
		);
	}

}

export { DashboardLanding };